import {
  Button,
  Checkbox,
  Divider,
  Form,
  Input,
  Modal,
  Select,
  Tabs,
  Tag
} from 'antd';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useStorePromotion } from '../../../hooks/useStorePromotion';
import actions from '../../../redux/actions';
import { notificationError } from '../../../utils/Notification';
import dispatch from '../../../utils/dispatch';
import { Line } from './Line';
import './styles.less';

const ModelAddPromotion = () => {
  useEffect(() => {
    dispatch(actions.getWebsitePromotion());
  }, []);

  const { website } = useSelector(
    state => state?.transaction?.addPromotion,
    shallowEqual
  );

  const handleOk = () => {};

  const handleCancel = () => {
    dispatch(actions.onModelAddPromotion(''));
  };

  return (
    <div className="add-promotion">
      <Modal
        className="add-promotion"
        open={!!website}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Tabs
          defaultActiveKey="2"
          items={[
            {
              key: 1,
              label: 'Thêm khuyến mãi',
              children: <AddNew />
            },
            {
              key: 2,
              label: 'Khuyến mãi đã thêm',
              children: <ListAdd />
            }
          ]}
        />
      </Modal>
    </div>
  );
};

const AddNew = () => {
  const [form] = Form.useForm();
  const [quotationIds, setQuotationIds] = useState([]);

  const user_id = useSelector(
    state => state?.customer?.userSelected?._id || '',
    shallowEqual
  );
  const onFinish = values => {
    const data = {
      code: values.note,
      discount_amount_original: values.sale_price,
      discount_amount_type: values.sale_price_type,
      user_id,
      quotation_ids: Object.keys(values)
        .filter(key => key.startsWith('checkbox_') && values[key] === true)
        .map(key => key.replace('checkbox_', ''))
    };
    if (isEmpty(data?.quotation_ids)) {
      notificationError('Chọn ít nhất một sản phẩm');
      return;
    }
    setQuotationIds([]);
    form.resetFields();
    dispatch(actions.addWebsitePromotion(data));
  };
  const onFinishFailed = errorInfo => {
    notificationError(errorInfo);
  };

  const cartGroup = useSelector(
    state => state?.transaction?.cartGroup || [],
    shallowEqual
  );

  const { website } = useSelector(
    state => state?.transaction?.addPromotion,
    shallowEqual
  );
  const array = (cartGroup.find(i => i?.website === website)?.list || []).filter(
    i => !i.store_promotion_unit_price
  );
  return (
    <>
      <Form
        name="basic"
        form={form}
        labelCol={{
          span: 8
        }}
        wrapperCol={{
          span: 16
        }}
        style={{
          maxWidth: 600
        }}
        initialValues={{
          sale_price_type: '%',
          note: ''
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item name="sale_price_type">
          <Select>
            <Select.Option value="$">Khuyến mãi theo giá trị</Select.Option>
            <Select.Option value="%">Khuyến mãi theo phần trăm</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="sale_price"
          rules={[
            {
              required: true,
              message: 'Please input this field'
            }
          ]}
        >
          <Input placeholder="Điền số" />
        </Form.Item>

        <Form.Item name="note">
          <Input placeholder="Điền mã giảm giá (nếu có)" />
        </Form.Item>
        <Divider />

        {array.length > 0 && (
          <Checkbox
            checked={quotationIds.length === array.length}
            onChange={event => {
              setQuotationIds(
                event.target.checked ? array.map(item => item._id) : []
              );
              array
                .map(item => `checkbox_${item._id}`)
                .forEach(item => form.setFieldValue(item, event.target.checked));
            }}
          >
            Chọn tất cả
          </Checkbox>
        )}

        <div style={{ maxHeight: 336, overflow: 'scroll' }}>
          {array.map((j, k) => (
            <div
              key={k}
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <Form.Item
                style={{ margin: 0 }}
                key={k}
                name={`checkbox_${j._id}`}
                valuePropName="checked"
              >
                <Checkbox
                  onChange={event =>
                    setQuotationIds(prevState => {
                      if (event.target.checked) return [...prevState, j._id];
                      return prevState.filter(item => item !== j._id);
                    })
                  }
                />
              </Form.Item>
              <Line {...j} showDelete={false} />
            </div>
          ))}
        </div>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Xác nhận
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

const ListAdd = () => {
  const { onDelete } = useStorePromotion();

  const promotions = useSelector(
    state => state?.transaction?.storePromotion || [],
    shallowEqual
  );
  return (
    <>
      {promotions.map((i, key) => (
        <div key={key} style={{ border: '1px solid #E9ECF4', marginBottom: 20 }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              borderBottom: '1px solid #E9ECF4',
              padding: '10px 5px 15px 5px'
            }}
          >
            <Tag color="#2db7f5">
              Khuyến mãi giảm {i.discount_amount_original}
              {i.discount_amount_type}
            </Tag>
            <Button
              size="small"
              danger
              onClick={() => onDelete(i.cart_items.map(x => `${x._id}`))}
            >
              Xóa
            </Button>
          </div>
          <div>
            {i.cart_items.map((j, k) => (
              <Line key={k} {...j} />
            ))}
          </div>
        </div>
      ))}
    </>
  );
};

export default ModelAddPromotion;
