import React from 'react';
import ShippingAddressList from './List';
import ShippingAddressCreate from './Create';

export default function AddressManagement() {
  return (
    <>
      <ShippingAddressList />
      <ShippingAddressCreate />
    </>
  );
}
