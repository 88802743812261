import { Button, List, Spin } from 'antd';
import get from 'lodash/get';
import React, { useState } from 'react';
import ReactDragListView from 'react-drag-listview';
import { useSelector } from 'react-redux';
import useDraggable from '../../hooks/useDraggable';
import actions from '../../redux/actions';
import dispatch from '../../utils/dispatch';
import TopBrandItem from './TopBrandItem';

export default function TopBrand() {
  const [loading, setLoading] = useState(false);
  const [showAddNew, setShowAddNew] = useState(false);
  const list = useSelector(state => {
    return get(state, 'mobile.topBrand', []);
  });

  const { onDragEnd } = useDraggable(list);

  const handleDragEnd = (fromIndex, toIndex) => {
    const result = onDragEnd(fromIndex, toIndex);
    dispatch(actions.draggableTopBrand(result));
  };

  const handleSavePriority = () => {
    setLoading(true);
    dispatch(
      actions.updateTopBrandPriority({
        data: list.map(item => item._id),
        callback: () => {
          setLoading(false);
          setShowAddNew(false);
        }
      })
    );
  };

  return (
    <Spin spinning={loading}>
      <div className="fragment-theme">
        <div className="btn-action">
          <Button type="primary" onClick={handleSavePriority}>
            <b>Lưu thứ tự</b>
          </Button>
          <Button onClick={() => setShowAddNew(true)} className="save">
            <b>+ Thêm Brand</b>
          </Button>
        </div>

        <div className="dealngon-banner">
          <ReactDragListView
            onDragEnd={handleDragEnd}
            handleSelector=".ant-collapse-header"
            nodeSelector=".banner-item"
          >
            {showAddNew && (
              <TopBrandItem
                isNew
                setShowAddNew={setShowAddNew}
                setLoading={setLoading}
              />
            )}
            {(list.length > 0 || !showAddNew) && (
              <List
                dataSource={list}
                split={false}
                renderItem={(item, index) => {
                  return (
                    <List.Item className="banner-item">
                      <TopBrandItem
                        {...item}
                        key={index}
                        setLoading={setLoading}
                        setShowAddNew={setShowAddNew}
                        index={index}
                      />
                    </List.Item>
                  );
                }}
              />
            )}
          </ReactDragListView>
        </div>
      </div>
    </Spin>
  );
}
