/* eslint-disable consistent-return */

import { get } from 'lodash';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import keys from 'lodash/keys';
import { ACCESS_KEY, REFRESH_TOKEN } from '../contents/Constants';
import HTTP_CODE from '../contents/HTTP_CODE';
import actions from '../redux/actions';
import dispatch from '../utils/dispatch';
import { notificationError } from '../utils/Notification';
import API from './API';

const handle = async (method, url, data) => {
  try {
    const authorization = !includes(url, API.LOGIN)
      ? window.localStorage.getItem(ACCESS_KEY)
      : '';
    let queries = '';
    if (method === 'GET' && !isEmpty(data)) {
      const params = keys(data)
        .map(key => {
          return `${key}=${data[key]}`;
        })
        .join('&');
      queries = `?${params}`;
    }
    const body =
      data && includes(['POST', 'PUT', 'DELETE'], method)
        ? JSON.stringify(data)
        : null;
    const response = await fetch(`${url}${queries}`, {
      method,
      headers: {
        'Content-Type': 'application/json',
        authorization,
        'Cache-Control': 'no-cache'
      },
      body
    });
    switch (response.status) {
      case HTTP_CODE.CREATED_201:
      case HTTP_CODE.SUCCESS_200:
        const result = await response.json();
        const success = result?.success;
        const message = result?.message;
        if (success === false && message) {
          notificationError(message);
        }
        return result;
      case HTTP_CODE.AUTHORIZED_401:
      case HTTP_CODE.FORBIDDENT_403:
        dispatch(actions.logout());
        // notificationError("Token hết hạn, vui lòng đăng nhập lại");
        return null;
      case HTTP_CODE.METHOD_NOT_ALLOWED_405:
        dispatch(actions.logout());
        notificationError('Token hết hạn, vui lòng đăng nhập lại');
        return null;
      case HTTP_CODE.HAVE_NOT_ACCESS_406:
        dispatch(actions.logout());
        return null;
      default:
        if (typeof response.text === 'function') {
          const errorMessage = await response.text();
          throw new Error(errorMessage);
        }
        throw new Error(response);
    }
  } catch (err) {
    throw new Error(err);
  }
};

const handleUpload = async (url, file) => {
  try {
    const name = get(file, 'type', '').split('/')[0];
    const formData = new FormData();
    formData.append(name, file);
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        authorization: window.localStorage.getItem(ACCESS_KEY)
      },
      body: formData
    });
    switch (response.status) {
      case HTTP_CODE.CREATED_201:
      case HTTP_CODE.SUCCESS_200:
        return response.json();
      case HTTP_CODE.AUTHORIZED_401:
      case HTTP_CODE.FORBIDDENT_403:
        dispatch(actions.refreshToken(localStorage.getItem(REFRESH_TOKEN)));
        break;
      case HTTP_CODE.METHOD_NOT_ALLOWED_405:
        dispatch(actions.logout());
        notificationError('Token hết hạn, vui lòng đăng nhập lại');
        return null;
      default:
        if (typeof response.text === 'function') {
          const errorMessage = await response.text();
          throw new Error(errorMessage);
        }
        throw new Error(response);
    }
  } catch (error) {
    throw new Error(error);
  }
};

export default class Restful {
  static get(url, data) {
    return handle('GET', url, data);
  }

  static post(url, data) {
    return handle('POST', url, data);
  }

  static put(url, data) {
    return handle('PUT', url, data);
  }

  static delete(url, data) {
    return handle('DELETE', url, data);
  }

  static upload(url, data) {
    return handleUpload(url, data);
  }
}
