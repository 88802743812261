export default {
  LOADING: 'LOADING',
  OFF_LOADING: 'OFF_LOADING',

  CLEAR_CONFIGURATION: 'CLEAR_CONFIGURATION',

  LOGIN: 'LOGIN',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILED: 'LOGIN_FAILED',

  LOGOUT: 'LOGOUT',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',

  LIST_ACCOUNTS: 'LIST_ACCOUNTS',
  LIST_ACCOUNTS_SUCCESS: 'LIST_ACCOUNTS_SUCCESS',
  LIST_ACCOUNTS_FAILED: 'LIST_ACCOUNTS_FAILED',

  ENABLE_LOADING_IMPROVE: 'ENABLE_LOADING_IMPROVE',
  DISABLE_LOADING_IMPROVE: 'DISABLE_LOADING_IMPROVE',

  CHANGE_STATUS_ACTIVITY_ACCOUNT: 'CHANGE_STATUS_ACTIVITY_ACCOUNT',
  CHANGE_STATUS_ACTIVITY_ACCOUNT_SUCCESS: 'CHANGE_STATUS_ACTIVITY_ACCOUNT_SUCCESS',
  CHANGE_STATUS_ACTIVITY_ACCOUNT_FAILED: 'CHANGE_STATUS_ACTIVITY_ACCOUNT_FAILED',

  SEARCH_ACCOUNT: 'SEARCH_ACCOUNTS',
  SEARCH_ACCOUNT_SUCCESS: 'SEARCH_ACCOUNTS_SUCCESS',
  SEARCH_ACCOUNT_FAILED: 'SEARCH_ACCOUNTS_FAILED',

  ACCOUNT_DETAIL: 'ACCOUNT_DETAIL',
  ACCOUNT_DETAIL_SUCCESS: 'ACCOUNT_DETAIL_SUCCESS',
  ACCOUNT_DETAIL_FAILED: 'ACCOUNT_DETAIL_FAILED',

  UPDATE_ACCOUNT: 'UPDATE_ACCOUNT',
  UPDATE_ACCOUNT_SUCCESS: 'UPDATE_ACCOUNT_SUCCESS',
  UPDATE_ACCOUNT_FAILED: 'UPDATE_ACCOUNT_FAILED',

  CREATE_ACCOUNT: 'CREATE_ACCOUNT',
  CREATE_ACCOUNT_SUCCESS: 'CREATE_ACCOUNT_SUCCESS',
  CREATE_ACCOUNT_FAILED: 'CREATE_ACCOUNT_FAILED',

  REMOVE_ACCOUNT: 'REMOVE_ACCOUNT',
  REMOVE_ACCOUNT_SUCCESS: 'REMOVE_ACCOUNT_SUCCESS',
  REMOVE_ACCOUNT_FAILED: 'REMOVE_ACCOUNT_FAILED',

  PROFILE_OWNER: 'PROFILE_OWNER',
  PROFILE_OWNER_SUCCESS: 'PROFILE_OWNER_SUCCESS',
  PROFILE_OWNER_FAILED: 'PROFILE_OWNER_FAILED',

  LIST_TRANSACTIONS: 'LIST_TRANSACTIONS',
  LIST_TRANSACTIONS_SUCCESS: 'LIST_TRANSACTIONS_SUCCESS',
  LIST_TRANSACTIONS_FAILED: 'LIST_TRANSACTIONS_FAILED',

  DETAIL_TRANSACTION: 'DETAIL_TRANSACTION',

  GET_TRANSACTION_INFO: 'GET_TRANSACTION_INFO',
  CLEAR_TRANSACTION_INFO: 'CLEAR_TRANSACTION_INFO',

  GET_WITHDRAWAL_DETAIL: 'GET_WITHDRAWAL_DETAIL',
  GET_WITHDRAWAL_DETAIL_SUCCESS: 'GET_WITHDRAWAL_DETAIL_SUCCESS',
  GET_WITHDRAWAL_DETAIL_FAILED: 'GET_WITHDRAWAL_DETAIL_FAILED',

  TOGGLE_REQUEST_CHANGE_POINT: 'TOGGLE_REQUEST_CHANGE_POINT',

  TOGGLE_EXPORT_POINT_STATISTICAL: 'TOGGLE_EXPORT_POINT_STATISTICAL',

  GET_LIST_DEAL_WITHDRAW: 'GET_LIST_DEAL_WITHDRAW',
  GET_LIST_DEAL_WITHDRAW_SUCCESS: 'GET_LIST_DEAL_WITHDRAW_SUCCESS',
  GET_LIST_DEAL_WITHDRAW_FAILED: 'GET_LIST_DEAL_WITHDRAW_FAILED',

  CREATE_DEAL_WITHDRAW_REQUEST: 'CREATE_DEAL_WITHDRAW_REQUEST',
  CREATE_DEAL_WITHDRAW_REQUEST_SUCCESS: 'CREATE_DEAL_WITHDRAW_REQUEST_SUCCESS',
  CREATE_DEAL_WITHDRAW_REQUEST_FAILED: 'CREATE_DEAL_WITHDRAW_REQUEST_FAILED',

  GET_DEAL_WITHDRAW_DETAIL: 'GET_DEAL_WITHDRAW_DETAIL',

  CONFIRM_DEAL_WITHDRAW: 'CONFIRM_DEAL_WITHDRAW',
  CONFIRM_DEAL_WITHDRAW_SUCCESS: 'CONFIRM_DEAL_WITHDRAW_SUCCESS',
  CONFIRM_DEAL_WITHDRAW_FAILED: 'CONFIRM_DEAL_WITHDRAW_FAILED',

  REJECT_DEAL_WITHDRAW: 'REJECT_DEAL_WITHDRAW',
  REJECT_DEAL_WITHDRAW_SUCCESS: 'REJECT_DEAL_WITHDRAW_SUCCESS',
  REJECT_DEAL_WITHDRAW_FAILED: 'REJECT_DEAL_WITHDRAW_FAILED',

  EXPORT_POINT_STATISTICAL: 'EXPORT_POINT_STATISTICAL',
  EXPORT_POINT_STATISTICAL_SUCCESS: 'EXPORT_POINT_STATISTICAL_SUCCESS',
  EXPORT_POINT_STATISTICAL_FAILED: 'EXPORT_POINT_STATISTICAL_FAILED',

  GET_EXCHANGE_POINT_REQUEST_DETAIL: 'GET_EXCHANGE_POINT_REQUEST_DETAIL',
  CLEAR_EXCHANGE_POINT_REQUEST_DETAIL: 'CLEAR_EXCHANGE_POINT_REQUEST_DETAIL',

  CLEAR_WITHDRAWAL_DETAIL: 'CLEAR_WITHDRAWAL_DETAIL',

  UPDATE_TRANSACTION_INFO: 'UPDATE_TRANSACTION_INFO',
  UPDATE_TRANSACTION_INFO_SUCCESS: 'UPDATE_TRANSACTION_INFO_SUCCESS',
  UPDATE_TRANSACTION_INFO_FAILED: 'UPDATE_TRANSACTION_INFO_FAILED',

  ACCEPT_TRANSACTION: 'ACCEPT_TRANSACTION',
  ACCEPT_TRANSACTION_SUCCESS: 'ACCEPT_TRANSACTION_SUCCESS',
  ACCEPT_TRANSACTION_FAILED: 'ACCEPT_TRANSACTION_FAILED',

  REJECT_TRANSACTION: 'REJECT_TRANSACTION',
  REJECT_TRANSACTION_SUCCESS: 'REJECT_TRANSACTION_SUCCESS',
  REJECT_TRANSACTION_FAILED: 'REJECT_TRANSACTION_FAILED',

  REMOVE_TRANSACTION: 'REMOVE_TRANSACTION',
  REMOVE_TRANSACTION_SUCCESS: 'REMOVE_TRANSACTION_SUCCESS',
  REMOVE_TRANSACTION_FAILED: 'REMOVE_TRANSACTION_FAILED',

  CUSTOMER_DETAIL: 'CUSTOMER_DETAIL',

  UPDATE_CUSTOMER: 'UPDATE_CUSTOMER',
  UPDATE_CUSTOMER_SUCCESS: 'UPDATE_CUSTOMER_SUCCESS',
  UPDATE_CUSTOMER_FAILED: 'UPDATE_CUSTOMER_FAILED',

  CREATE_CUSTOMER: 'CREATE_CUSTOMER',
  CREATE_CUSTOMER_SUCCESS: 'CREATE_CUSTOMER_SUCCESS',
  CREATE_CUSTOMER_FAILED: 'CREATE_CUSTOMER_FAILED',

  REMOVE_CUSTOMER: 'REMOVE_CUSTOMER',
  REMOVE_CUSTOMER_SUCCESS: 'REMOVE_CUSTOMER_SUCCESS',
  REMOVE_CUSTOMER_FAILED: 'REMOVE_CUSTOMER_FAILED',

  CHANGE_STATUS_ACTIVITY_CUSTOMER: 'CHANGE_STATUS_ACTIVITY_CUSTOMER',
  CHANGE_STATUS_ACTIVITY_CUSTOMER_SUCCESS: 'CHANGE_STATUS_ACTIVITY_CUSTOMER_SUCCESS',
  CHANGE_STATUS_ACTIVITY_CUSTOMER_FAILED: 'CHANGE_STATUS_ACTIVITY_CUSTOMER_FAILED',

  SEARCH_CUSTOMER: 'SEARCH_CUSTOMER',
  SEARCH_CUSTOMER_SUCCESS: 'SEARCH_CUSTOMER_SUCCESS',
  SEARCH_CUSTOMER_FAILED: 'SEARCH_CUSTOMER_FAILED',

  NOTIFICATION_TRANSACTION: 'NOTIFICATION_TRANSACTION',
  NOTIFICATION_TRANSACTION_FAILED: 'NOTIFICATION_TRANSACTION_FAILED',
  NOTIFICATION_TRANSACTION_SUCCESS: 'NOTIFICATION_TRANSACTION_SUCCESS',

  UPDATE_FCM_TOKEN: 'UPDATE_FCM_TOKEN',
  UPDATE_FCM_TOKEN_FAILED: 'UPDATE_FCM_TOKEN_FAILED',
  UPDATE_FCM_TOKEN_SUCCESS: 'UPDATE_FCM_TOKEN_SUCCESS',

  CHECK_AUTH: 'CHECK_AUTH',

  REFRESH_TOKEN: 'REFRESH_TOKEN',
  REFRESH_TOKEN_SUCCESS: 'REFRESH_TOKEN_SUCCESS',
  REFRESH_TOKEN_FAILED: 'REFRESH_TOKEN_FAILED',

  GET_CONFIGURATION: 'GET_CONFIGURATION',
  GET_CONFIGURATION_SUCCESS: 'GET_CONFIGURATION_SUCCESS',
  GET_CONFIGURATION_FAILED: 'GET_CONFIGURATION_FAILED',

  UPDATE_CONFIGURATION: 'UPDATE_CONFIGURATION',
  UPDATE_CONFIGURATION_SUCCESS: 'UPDATE_CONFIGURATION_SUCCESS',
  UPDATE_CONFIGURATION_FAILED: 'UPDATE_CONFIGURATION_FAILED',

  GET_URL_IMAGE: 'GET_URL_IMAGE',
  GET_URL_IMAGE_SUCCESS: 'GET_URL_IMAGE_SUCCESS',
  GET_URL_IMAGE_FAILED: 'GET_URL_IMAGE_FAILED',

  GET_ALL_CUSTOMERS: 'GET_ALL_CUSTOMERS',
  GET_ALL_CUSTOMERS_SUCCESS: 'GET_ALL_CUSTOMERS_SUCCESS',
  GET_ALL_CUSTOMERS_FAILED: 'GET_ALL_CUSTOMERS_FAILED',

  GET_DRAFT_SALE_ORDER: 'GET_DRAFT_SALE_ORDER',
  GET_DRAFT_SALE_ORDER_SUCCESS: 'GET_DRAFT_SALE_ORDER_SUCCESS',
  GET_DRAFT_SALE_ORDER_FAILED: 'GET_DRAFT_SALE_ORDER_FAILED',

  ADD_QUOTATION_TO_SALE_ORDER: 'ADD_QUOTATION_TO_SALE_ORDER',
  ADD_QUOTATION_TO_SALE_ORDER_SUCCESS: 'ADD_QUOTATION_TO_SALE_ORDER_SUCCESS',
  ADD_QUOTATION_TO_SALE_ORDER_FAILED: 'ADD_QUOTATION_TO_SALE_ORDER_FAILED',

  EDIT_QUOTATION: 'EDIT_QUOTATION',
  EDIT_QUOTATION_SUCCESS: 'EDIT_QUOTATION_SUCCESS',
  EDIT_QUOTATION_FAILED: 'EDIT_QUOTATION_FAILED',

  DELETE_QUOTATION: 'DELETE_QUOTATION',
  DELETE_QUOTATION_SUCCESS: 'DELETE_QUOTATION_SUCCESS',
  DELETE_QUOTATION_FAILED: 'DELETE_QUOTATION_FAILED',

  GET_DELIVERY_ADDRESS: 'GET_DELIVERY_ADDRESS',
  GET_DELIVERY_ADDRESS_SUCCESS: 'GET_DELIVERY_ADDRESS_SUCCESS',
  GET_DELIVERY_ADDRESS_FAILED: 'GET_DELIVERY_ADDRESS_FAILED',

  CREATE_DELIVERY_ADDRESS: 'CREATE_DELIVERY_ADDRESS',
  CREATE_DELIVERY_ADDRESS_SUCCESS: 'CREATE_DELIVERY_ADDRESS_SUCCESS',
  CREATE_DELIVERY_ADDRESS_FAILED: 'CREATE_DELIVERY_ADDRESS_FAILED',

  SELECTED_DELIVERY_ADDRESS: 'SELECTED_DELIVERY_ADDRESS',
  SELECTED_DELIVERY_ADDRESS_SUCCESS: 'SELECTED_DELIVERY_ADDRESS_SUCCESS',
  SELECTED_DELIVERY_ADDRESS_FAILED: 'SELECTED_DELIVERY_ADDRESS_FAILED',

  CONFIRM_SALE_ORDER: 'CONFIRM_SALE_ORDER',
  CONFIRM_SALE_ORDER_SUCCESS: 'CONFIRM_SALE_ORDER_SUCCESS',
  CONFIRM_SALE_ORDER_FAILED: 'CONFIRM_SALE_ORDER_FAILED',

  SELECT_CUSTOMER: 'SELECT_CUSTOMER',

  GET_ALL_PROVINCE: 'GET_ALL_PROVINCE',
  GET_ALL_PROVINCE_SUCCESS: 'GET_ALL_PROVINCE_SUCCESS',
  GET_ALL_PROVINCE_FAILED: 'GET_ALL_PROVINCE_FAILED',

  GET_ALL_DISTRICT: 'GET_ALL_DISTRICT',
  GET_ALL_DISTRICT_SUCCESS: 'GET_ALL_DISTRICT_SUCCESS',
  GET_ALL_DISTRICT_FAILED: 'GET_ALL_DISTRICT_FAILED',

  GET_ALL_WARD: 'GET_ALL_WARD',
  GET_ALL_WARD_SUCCESS: 'GET_ALL_WARD_SUCCESS',
  GET_ALL_WARD_FAILED: 'GET_ALL_WARD_FAILED',

  CLEAR_CUSTOMER: 'CLEAR_CUSTOMER',

  IMPORT_QUOTATION: 'IMPORT_QUOTATION',
  IMPORT_QUOTATION_SUCCESS: 'IMPORT_QUOTATION_SUCCESS',
  IMPORT_QUOTATION_FAILED: 'IMPORT_QUOTATION_FAILED',

  CLEAR_IMPORT_QUOTATION: 'CLEAR_IMPORT_QUOTATION',

  AUTO_CANCEL: 'AUTO_CANCEL',
  AUTO_CANCEL_SUCCESS: 'AUTO_CANCEL_SUCCESS',
  AUTO_CANCEL_FAILED: 'AUTO_CANCEL_FAILED',

  GET_WEBSITE_LIST_CRAWLER: 'GET_WEBSITE_LIST_CRAWLER',
  GET_WEBSITE_LIST_CRAWLER_SUCCESS: 'GET_WEBSITE_LIST_CRAWLER_SUCCESS',
  GET_WEBSITE_LIST_CRAWLER_FAILED: 'GET_WEBSITE_LIST_CRAWLER_FAILED',

  ADD_FORMAT_WEBSITE_CRAWLER: 'ADD_FORMAT_WEBSITE_CRAWLER',
  ADD_FORMAT_WEBSITE_CRAWLER_SUCCESS: 'ADD_FORMAT_WEBSITE_CRAWLER_SUCCESS',
  ADD_FORMAT_WEBSITE_CRAWLER_FAILED: 'ADD_FORMAT_WEBSITE_CRAWLER_FAILED',

  EDIT_FORMAT_WEBSITE_CRAWLER: 'EDIT_FORMAT_WEBSITE_CRAWLER',
  EDIT_FORMAT_WEBSITE_CRAWLER_SUCCESS: 'EDIT_FORMAT_WEBSITE_CRAWLER_SUCCESS',
  EDIT_FORMAT_WEBSITE_CRAWLER_FAILED: 'EDIT_FORMAT_WEBSITE_CRAWLER_FAILED',

  CREATE_FORMAT_WEBSITE_CRAWLER: 'CREATE_FORMAT_WEBSITE_CRAWLER',
  CREATE_FORMAT_WEBSITE_CRAWLER_SUCCESS: 'CREATE_FORMAT_WEBSITE_CRAWLER_SUCCESS',
  CREATE_FORMAT_WEBSITE_CRAWLER_FAILED: 'CREATE_FORMAT_WEBSITE_CRAWLER_FAILED',

  SYNC_PURCHASE_WEBSTIE: 'SYNC_PURCHASE_WEBSTIE',
  SYNC_PURCHASE_WEBSTIE_SUCCESS: 'SYNC_PURCHASE_WEBSTIE_SUCCESS',
  SYNC_PURCHASE_WEBSTIE_FAILED: 'SYNC_PURCHASE_WEBSTIE_FAILED',

  GET_ALL_CATEGORIES: 'GET_ALL_CATEGORIES',
  GET_ALL_CATEGORIES_SUCCESS: 'GET_ALL_CATEGORIES_SUCCESS',
  GET_ALL_CATEGORIES_FAILED: 'GET_ALL_CATEGORIES_FAILED',

  DRAGGABLE_CATEGORIES: 'DRAGGABLE_CATEGORIES',

  UPDATE_CATEGORIES_NAME: 'UPDATE_CATEGORIES_NAME',

  UPDATE_CATEGORIES: 'UPDATE_CATEGORIES',
  UPDATE_CATEGORIES_SUCCESS: 'UPDATE_CATEGORIES_SUCCESS',
  UPDATE_CATEGORIES_FAILED: 'UPDATE_CATEGORIES_FAILED',
  UPDATE_ICON_CATEGORIES: 'UPDATE_ICON_CATEGORIES',

  CREATE_GROUP: 'CREATE_GROUP',
  CREATE_GROUP_SUCCESS: 'CREATE_GROUP_SUCCESS',
  CREATE_GROUP_FAILED: 'CREATE_GROUP_FAILED',

  CREATE_CATEGORY: 'CREATE_CATEGORY',
  CREATE_CATEGORY_SUCCESS: 'CREATE_CATEGORY_SUCCESS',
  CREATE_CATEGORY_FAILED: 'CREATE_CATEGORY_FAILED',

  DELETE_CATEGORY: 'DELETE_CATEGORY',
  DELETE_CATEGORY_SUCCESS: 'DELETE_CATEGORY_SUCCESS',
  DELETE_CATEGORY_FAILED: 'DELETE_CATEGORY_FAILED',

  ENABLE_LOADING: 'ENABLE_LOADING',
  DISABLE_LOADING: 'DISABLE_LOADING',

  UPDATE_CRAWL_WEBSITE: 'UPDATE_CRAWL_WEBSITE',
  UPDATE_CRAWL_WEBSITE_FAILED: 'UPDATE_CRAWL_WEBSITE_FAILED',

  HANDLE_EDIT_QUOTATION: 'HANDLE_EDIT_QUOTATION',
  HANDLE_HIDE_QUOTATION: 'HANDLE_HIDE_QUOTATION',

  HANDLE_EDIT: 'HANDLE_EDIT',
  HANDLE_EDIT_SUCCESS: 'HANDLE_EDIT_SUCCESS',
  HANDLE_EDIT_FAILED: 'HANDLE_EDIT_FAILED',

  GET_LIST_WEBSITE_DISABLE_US: 'GET_LIST_WEBSITE_DISABLE_US',
  GET_LIST_WEBSITE_DISABLE_US_SUCCESS: 'GET_LIST_WEBSITE_DISABLE_US_SUCCESS',
  GET_LIST_WEBSITE_DISABLE_US_FAILED: 'GET_LIST_WEBSITE_DISABLE_US_FAILED',

  CREATE_TAG: 'CREATE_TAG',
  CREATE_TAG_SUCCESS: 'CREATE_TAG_SUCCESS',
  CREATE_TAG_FAILED: 'CREATE_TAG_FAILED',

  GET_LIST_TAGS: 'GET_LIST_TAGS',
  GET_LIST_TAGS_SUCCESS: 'GET_LIST_TAGS_SUCCESS',
  GET_LIST_TAGS_FAILED: 'GET_LIST_TAGS_FAILED',

  UPDATE_TAG: 'UPDATE_TAG',
  UPDATE_TAG_SUCCESS: 'UPDATE_TAG_SUCCESS',
  UPDATE_TAG_FAILED: 'UPDATE_TAG_FAILED',

  CREATE_WEBSITE: 'CREATE_WEBSITE',
  CREATE_WEBSITE_SUCCESS: 'CREATE_WEBSITE_SUCCESS',
  CREATE_WEBSITE_FAILED: 'CREATE_WEBSITE_FAILED',

  GET_LIST_WEBSITES: 'GET_LIST_WEBSITES',
  GET_LIST_WEBSITES_SUCCESS: 'GET_LIST_WEBSITES_SUCCESS',
  GET_LIST_WEBSITES_FAILED: 'GET_LIST_WEBSITES_FAILED',

  UPDATE_WEBSITE: 'UPDATE_WEBSITE',
  UPDATE_WEBSITE_SUCCESS: 'UPDATE_WEBSITE_SUCCESS',
  UPDATE_WEBSITE_FAILED: 'UPDATE_WEBSITE_FAILED',

  CREATE_DN_CATEGORY: 'CREATE_DN_CATEGORY',
  CREATE_DN_CATEGORY_SUCCESS: 'CREATE_DN_CATEGORY_SUCCESS',
  CREATE_DN_CATEGORY_FAILED: 'CREATE_DN_CATEGORY_FAILED',

  UPDATE_DN_CATEGORY: 'UPDATE_DN_CATEGORY',
  UPDATE_DN_CATEGORY_SUCCESS: 'UPDATE_DN_CATEGORY_SUCCESS',
  UPDATE_DN_CATEGORY_FAILED: 'UPDATE_DN_CATEGORY_FAILED',

  GET_LIST_DN_CATEGORIES: 'GET_LIST_DN_CATEGORIES',
  GET_LIST_DN_CATEGORIES_SUCCESS: 'GET_LIST_DN_CATEGORIES_SUCCESS',
  GET_LIST_DN_CATEGORIES_FAILED: 'GET_LIST_DN_CATEGORIES_FAILED',

  CREATE_ICON: 'CREATE_ICON',
  CREATE_ICON_SUCCESS: 'CREATE_ICON_SUCCESS',
  CREATE_ICON_FAILED: 'CREATE_ICON_FAILED',

  GET_LIST_ICONS: 'GET_LIST_ICONS',
  GET_LIST_ICONS_SUCCESS: 'GET_LIST_ICONS_SUCCESS',
  GET_LIST_ICONS_FAILED: 'GET_LIST_ICONS_FAILED',

  GET_LIST_ICONS_OTHER: 'GET_LIST_ICONS_OTHER',
  GET_LIST_ICONS_OTHER_SUCCESS: 'GET_LIST_ICONS_OTHER_SUCCESS',
  GET_LIST_ICONS_OTHER_FAILED: 'GET_LIST_ICONS_OTHER_FAILED',

  DELETE_ICON: 'DELETE_ICON',
  DELETE_ICON_SUCCESS: 'DELETE_ICON_SUCCESS',
  DELETE_ICON_FAILED: 'DELETE_ICON_FAILED',

  DELETE_TAG: 'DELETE_TAG',
  DELETE_TAG_SUCCESS: 'DELETE_TAG_SUCCESS',
  DELETE_TAG_FAILED: 'DELETE_TAG_FAILED',

  DRAGGABLE_BANNER: 'DRAGGABLE_BANNER',

  DRAGGABLE_LIST_CATEGORY: 'DRAGGABLE_LIST_CATEGORY',

  DELETE_WEBSITE: 'DELETE_WEBSITE',
  DELETE_WEBSITE_SUCCESS: 'DELETE_WEBSITE_SUCCESS',
  DELETE_WEBSITE_FAILED: 'DELETE_WEBSITE_FAILED',

  GET_SHIPPING_CATEGORIES: 'GET_SHIPPING_CATEGORIES',
  GET_SHIPPING_CATEGORIES_SUCCESS: 'GET_SHIPPING_CATEGORIES_SUCCESS',
  GET_SHIPPING_CATEGORIES_FAILED: 'GET_SHIPPING_CATEGORIES_FAILED',

  LIST_DEPOSITS_SUCCESS: 'LIST_DEPOSITS_SUCCESS',
  LIST_DEPOSITS_FAILED: 'LIST_DEPOSITS_FAILED',

  LIST_DEPOSITS_FOR_CUSTOMER_SUCCESS: 'LIST_DEPOSITS_FOR_CUSTOMER_SUCCESS',
  LIST_DEPOSITS_FOR_CUSTOMER_FAILED: 'LIST_DEPOSITS_FOR_CUSTOMER_FAILED',

  LIST_DEPOSITS_FOR_WITHDRAWAL_SUCCESS: 'LIST_DEPOSITS_FOR_WITHDRAWAL_SUCCESS',
  LIST_DEPOSITS_FOR_WITHDRAWAL_FAILED: 'LIST_DEPOSITS_FOR_WITHDRAWAL_FAILED',

  LIST_DEPOSITS_FOR_EXCHANGE_POINT_REQUEST_SUCCESS:
    'LIST_DEPOSITS_FOR_EXCHANGE_POINT_REQUEST_SUCCESS',
  LIST_DEPOSITS_FOR_EXCHANGE_POINT_REQUEST_FAILED:
    'LIST_DEPOSITS_FOR_EXCHANGE_POINT_REQUEST_FAILED',

  LIST_POINT_FOR_EXCHANGE_POINT_REQUEST_SUCCESS:
    'LIST_POINT_FOR_EXCHANGE_POINT_REQUEST_SUCCESS',
  LIST_POINT_FOR_EXCHANGE_POINT_REQUEST_FAILED:
    'LIST_POINT_FOR_EXCHANGE_POINT_REQUEST_FAILED',

  GET_INFO_WALLET: 'GET_INFO_WALLET',
  CLEAR_INFO_WALLET: 'CLEAR_INFO_WALLET',

  CS_VERIFY_RETAIL_SALE_ORDER: 'CS_VERIFY_RETAIL_SALE_ORDER',
  CS_VERIFY_RETAIL_SALE_ORDER_SUCCESS: 'CS_VERIFY_RETAIL_SALE_ORDER_SUCCESS',
  CS_VERIFY_RETAIL_SALE_ORDER_FAILED: 'CS_VERIFY_RETAIL_SALE_ORDER_FAILED',

  GET_LIST_PAYMENT_CONTENT: 'GET_LIST_PAYMENT_CONTENT',
  GET_LIST_PAYMENT_CONTENT_SUCCESS: 'GET_LIST_PAYMENT_CONTENT_SUCCESS',
  GET_LIST_PAYMENT_CONTENT_FAILED: 'GET_LIST_PAYMENT_CONTENT_FAILED',

  CREATE_PAYMENT_CONTENT: 'CREATE_PAYMENT_CONTENT',
  CREATE_PAYMENT_CONTENT_SUCCESS: 'CREATE_PAYMENT_CONTENT_SUCCESS',
  CREATE_PAYMENT_CONTENT_FAILED: 'CREATE_PAYMENT_CONTENT_FAILED',

  GET_LIST_WITHDRAWAL: 'GET_LIST_WITHDRAWAL',
  GET_LIST_WITHDRAWAL_SUCCESS: 'GET_LIST_WITHDRAWAL_SUCCESS',
  GET_LIST_WITHDRAWAL_FAILED: 'GET_LIST_WITHDRAWAL_FAILED',

  GET_LIST_BANK: 'GET_LIST_BANK',
  GET_LIST_BANK_SUCCESS: 'GET_LIST_BANK_SUCCESS',
  GET_LIST_BANK_FAILED: 'GET_LIST_BANK_FAILED',

  REJECT_WITHDRAWAL: 'REJECT_WITHDRAWAL',
  REJECT_WITHDRAWAL_SUCCESS: 'REJECT_WITHDRAWAL_SUCCESS',
  REJECT_WITHDRAWAL_FAILED: 'REJECT_WITHDRAWAL_FAILED',

  APPROVE_WITHDRAWAL: 'APPROVE_WITHDRAWAL',
  APPROVE_WITHDRAWAL_SUCCESS: 'APPROVE_WITHDRAWAL_SUCCESS',
  APPROVE_WITHDRAWAL_FAILED: 'APPROVE_WITHDRAWAL_FAILED',

  GET_LIST_EXCHANGE_POINT_REQUEST: 'GET_LIST_EXCHANGE_POINT_REQUEST',
  GET_LIST_EXCHANGE_POINT_REQUEST_SUCCESS: 'GET_LIST_EXCHANGE_POINT_REQUEST_SUCCESS',
  GET_LIST_EXCHANGE_POINT_REQUEST_FAILED: 'GET_LIST_EXCHANGE_POINT_REQUEST_FAILED',

  CONFIRM_EXCHANGE_POINT_REQUEST: 'CONFIRM_EXCHANGE_POINT_REQUEST',
  CONFIRM_EXCHANGE_POINT_REQUEST_SUCCESS: 'CONFIRM_EXCHANGE_POINT_REQUEST_SUCCESS',
  CONFIRM_EXCHANGE_POINT_REQUEST_FAILED: 'CONFIRM_EXCHANGE_POINT_REQUEST_FAILED',

  REJECT_EXCHANGE_POINT_REQUEST: 'REJECT_EXCHANGE_POINT_REQUEST',
  REJECT_EXCHANGE_POINT_REQUEST_SUCCESS: 'REJECT_EXCHANGE_POINT_REQUEST_SUCCESS',
  REJECT_EXCHANGE_POINT_REQUEST_FAILED: 'REJECT_EXCHANGE_POINT_REQUEST_FAILED',

  UPDATE_POINT_BY_ADMIN: 'UPDATE_POINT_BY_ADMIN',
  UPDATE_POINT_BY_ADMIN_SUCCESS: 'UPDATE_POINT_BY_ADMIN_SUCCESS',
  UPDATE_POINT_BY_ADMIN_FAILED: 'UPDATE_POINT_BY_ADMIN_FAILED',

  GET_PROMOTION_US: 'GET_PROMOTION_US',
  GET_PROMOTION_US_SUCCESS: 'GET_PROMOTION_US_SUCCESS',
  GET_PROMOTION_FAILED: 'GET_PROMOTION_FAILED',

  DELETE_PROMOTION_US: 'DELETE_PROMOTION_US',
  DELETE_PROMOTION_US_SUCCESS: 'DELETE_PROMOTION_US_SUCCESS',
  DELETE_PROMOTION_US_FAILED: 'DELETE_PROMOTION_US_FAILED',

  CREATE_PROMOTION_US: 'CREATE_PROMOTION_US',
  CREATE_PROMOTION_US_SUCCESS: 'CREATE_PROMOTION_US_SUCCESS',
  CREATE_PROMOTION_US_FAILED: 'CREATE_PROMOTION_US',

  UPDATE_PROMOTION_US: 'UPDATE_PROMOTION_US',
  UPDATE_PROMOTION_US_SUCCESS: 'UPDATE_PROMOTION_US_SUCCESS',
  UPDATE_PROMOTION_US_FAILED: 'UPDATE_PROMOTION_US_FAILED',

  GET_CURRENT_DEAL_ORDER: 'GET_CURRENT_DEAL_ORDER',
  GET_CURRENT_DEAL_ORDER_SUCCESS: 'GET_CURRENT_DEAL_ORDER_SUCCESS',
  GET_CURRENT_DEAL_ORDER_FAILED: 'GET_CURRENT_DEAL_ORDER_FAILED',

  CONFIRM_DEAL_ORDER: 'CONFIRM_DEAL_ORDER',
  CONFIRM_DEAL_ORDER_SUCCESS: 'CONFIRM_DEAL_ORDER_SUCCESS',
  CONFIRM_DEAL_ORDER_FAILED: 'CONFIRM_DEAL_ORDER_FAILED',

  GET_LIST_DEAL_BY_USER: 'GET_LIST_DEAL_BY_USER',
  GET_LIST_DEAL_BY_USER_SUCCESS: 'GET_LIST_DEAL_BY_USER_SUCCESS',
  GET_LIST_DEAL_BY_USER_FAILED: 'GET_LIST_DEAL_BY_USER_FAILED',

  CLEAR_LIST_DEAL_BY_USER: 'CLEAR_LIST_DEAL_BY_USER',

  DELETE_ITEM_DEAL_ORDER: 'DELETE_ITEM_DEAL_ORDER',
  DELETE_ITEM_DEAL_ORDER_SUCCESS: 'DELETE_ITEM_DEAL_ORDER_SUCCESS',
  DELETE_ITEM_DEAL_ORDER_FAILED: 'DELETE_ITEM_DEAL_ORDER_FAILED',

  ADD_ITEM_DEAL_ORDER: 'ADD_ITEM_DEAL_ORDER',
  ADD_ITEM_DEAL_ORDER_SUCCESS: 'ADD_ITEM_DEAL_ORDER_SUCCESS',
  ADD_ITEM_DEAL_ORDER_FAILED: 'ADD_ITEM_DEAL_ORDER_FAILED',

  LIST_DEALS: 'LIST_DEALS',
  LIST_DEALS_SUCCESS: 'LIST_DEALS_SUCCESS',
  LIST_DEALS_FAILED: 'LIST_DEALS_FAILED',

  LIST_META_DEAL: 'LIST_META_DEAL',
  LIST_META_DEAL_SUCCESS: 'LIST_META_DEAL_SUCCESS',
  LIST_META_DEAL_FAILED: 'LIST_META_DEAL_FAILED',

  DETAIL_DEAL: 'DETAIL_DEAL',
  DETAIL_DEAL_SUCCESS: 'DETAIL_DEAL_SUCCESS',
  DETAIL_DEAL_FAILED: 'DETAIL_DEAL_FAILED',

  CLEAR_DEAL_DETAIL: 'CLEAR_DEAL_DETAIL',

  CONFIRM_DEAL: 'CONFIRM_DEAL',
  CONFIRM_DEAL_SUCCESS: 'CONFIRM_DEAL_SUCCESS',
  CONFIRM_DEAL_FAILED: 'CONFIRM_DEAL_FAILED',

  REJECT_DEAL: 'REJECT_DEAL',
  REJECT_DEAL_SUCCESS: 'REJECT_DEAL_SUCCESS',
  REJECT_DEAL_FAILED: 'REJECT_DEAL_FAILED',

  REORDER_SALE_ORDER: 'REORDER_SALE_ORDER',
  REORDER_SALE_ORDER_SUCCESS: 'REORDER_SALE_ORDER_SUCCESS',
  REORDER_SALE_ORDER_FAILED: 'REORDER_SALE_ORDER_FAILED',

  LIST_DEAL_GIFT_CARD_TRANSACTION: 'LIST_DEAL_GIFT_CARD_TRANSACTION',
  LIST_DEAL_GIFT_CARD_TRANSACTION_SUCCESS: 'LIST_DEAL_GIFT_CARD_TRANSACTION_SUCCESS',
  LIST_DEAL_GIFT_CARD_TRANSACTION_FAILED: 'LIST_DEAL_GIFT_CARD_TRANSACTION_FAILED',

  LIST_SELLER_PROFILE: 'LIST_SELLER_PROFILE',
  LIST_SELLER_PROFILE_SUCCESS: 'LIST_SELLER_PROFILE_SUCCESS',
  LIST_SELLER_PROFILE_FAILED: 'LIST_SELLER_PROFILE_FAILED',

  CHANGE_MODE_SELLER_PROFILE: 'CHANGE_MODE_SELLER_PROFILE',

  CREATE_SELLER_PROFILE: 'CREATE_SELLER_PROFILE',
  CREATE_SELLER_PROFILE_SUCCESS: 'CREATE_SELLER_PROFILE_SUCCESS',
  CREATE_SELLER_PROFILE_FAILED: 'CREATE_SELLER_PROFILE_FAILED',

  CREATE_DEAL: 'CREATE_DEAL',
  CREATE_DEAL_SUCCESS: 'CREATE_DEAL_SUCCESS',
  CREATE_DEAL_FAILED: 'CREATE_DEAL_FAILED',

  OPEN_SELLER_PROFILE_DETAIL: 'OPEN_SELLER_PROFILE_DETAIL',

  EDIT_SELLER_PROFILE: 'EDIT_SELLER_PROFILE',
  EDIT_SELLER_PROFILE_SUCCESS: 'EDIT_SELLER_PROFILE_SUCCESS',
  EDIT_SELLER_PROFILE_FAILED: 'EDIT_SELLER_PROFILE_FAILED',

  LIST_CATEGORY: 'LIST_CATEGORY',
  LIST_CATEGORY_SUCCESS: 'LIST_CATEGORY_SUCCESS',
  LIST_CATEGORY_FAILED: 'LIST_CATEGORY_FAILED',

  LIST_TOPIC: 'LIST_TOPIC',
  LIST_TOPIC_SUCCESS: 'LIST_TOPIC_SUCCESS',
  LIST_TOPIC_FAILED: 'LIST_TOPIC_FAILED',

  UPDATE_STATE_DEAL: 'UPDATE_STATE_DEAL',
  UPDATE_STATE_DEAL_SUCCESS: 'UPDATE_STATE_DEAL_SUCCESS',
  UPDATE_STATE_DEAL_FAILED: 'UPDATE_STATE_DEAL_FAILED',

  CLEAR_UPDATE_STATE_DEAL: 'CLEAR_UPDATE_STATE_DEAL',
  SEARCH_DEAL: 'SEARCH_DEAL',

  UPDATE_WAITING_SALE_ORDER: 'UPDATE_WAITING_SALE_ORDER',
  UPDATE_WAITING_SALE_ORDER_SUCCESS: 'UPDATE_WAITING_SALE_ORDER_SUCCESS',
  UPDATE_WAITING_SALE_ORDER_FAILED: 'UPDATE_WAITING_SALE_ORDER_FAILED',

  LIST_CAMPAIGN: 'LIST_CAMPAIGN',
  LIST_CAMPAIGN_SUCCESS: 'LIST_CAMPAIGN_SUCCESS',
  LIST_CAMPAIGN_FAILED: 'LIST_CAMPAIGN_FAILED',

  CREATE_CAMPAIGN: 'CREATE_CAMPAIGN',
  CREATE_CAMPAIGN_SUCCESS: 'CREATE_CAMPAIGN_SUCCESS',
  CREATE_CAMPAIGN_FAILED: 'CREATE_CAMPAIGN_FAILED',

  CHANGE_MODE_CAMPAIGN: 'CHANGE_MODE_CAMPAIGN',

  OPEN_CAMPAIGN_DETAIL: 'OPEN_CAMPAIGN_DETAIL',

  UPDATE_CAMPAIGN: 'UPDATE_CAMPAIGN',
  UPDATE_CAMPAIGN_SUCCESS: 'UPDATE_CAMPAIGN_SUCCESS',
  UPDATE_CAMPAIGN_FAILED: 'UPDATE_CAMPAIGN_FAILED',

  GET_CAMPAIGN_HISTORY: 'GET_CAMPAIGN_HISTORY',
  GET_CAMPAIGN_HISTORY_SUCCESS: 'GET_CAMPAIGN_HISTORY_SUCCESS',
  GET_CAMPAIGN_HISTORY_FAILED: 'GET_CAMPAIGN_HISTORY_FAILED',

  GET_LIST_TOP_BRAND: 'GET_LIST_TOP_BRAND',
  GET_LIST_TOP_BRAND_SUCCESS: 'GET_LIST_TOP_BRAND_SUCCESS',
  GET_LIST_TOP_BRAND_FAILED: 'GET_LIST_TOP_BRAND_FAILED',

  GET_MOBILE_SETTING: 'GET_MOBILE_SETTING',
  GET_MOBILE_SETTING_SUCCESS: 'GET_MOBILE_SETTING_SUCCESS',
  GET_MOBILE_SETTING_FAILED: 'GET_MOBILE_SETTING_FAILED',

  UPDATE_MOBILE_SETTING: 'UPDATE_MOBILE_SETTING',
  UPDATE_MOBILE_SETTING_SUCCESS: 'UPDATE_MOBILE_SETTING_SUCCESS',
  UPDATE_MOBILE_SETTING_FAILED: 'UPDATE_MOBILE_SETTING_FAILED',

  CREATE_TOP_BRAND: 'CREATE_TOP_BRAND',
  CREATE_TOP_BRAND_SUCCESS: 'CREATE_TOP_BRAND_SUCCESS',
  CREATE_TOP_BRAND_FAILED: 'CREATE_TOP_BRAND_FAILED',

  UPDATE_TOP_BRAND: 'UPDATE_TOP_BRAND',
  UPDATE_TOP_BRAND_SUCCESS: 'UPDATE_TOP_BRAND_SUCCESS',
  UPDATE_TOP_BRAND_FAILED: 'UPDATE_TOP_BRAND_FAILED',

  UPDATE_TOP_BRAND_PRIORITY: 'UPDATE_TOP_BRAND_PRIORITY',
  UPDATE_TOP_BRAND_PRIORITY_SUCCESS: 'UPDATE_TOP_BRAND_PRIORITY_SUCCESS',
  UPDATE_TOP_BRAND_PRIORITY_FAILED: 'UPDATE_TOP_BRAND_PRIORITY_FAILED',

  DELETE_TOP_BRAND: 'DELETE_TOP_BRAND',
  DELETE_TOP_BRAND_SUCCESS: 'DELETE_TOP_BRAND_SUCCESS',
  DELETE_TOP_BRAND_FAILED: 'DELETE_TOP_BRAND_FAILED',

  DRAGGABLE_TOP_BRAND: 'DRAGGABLE_TOP_BRAND',

  DRAGGABLE_CAROUSEL: 'DRAGGABLE_CAROUSEL',

  GET_BANK_TRANSFER_POOL: 'GET_BANK_TRANSFER_POOL',
  GET_BANK_TRANSFER_POOL_SUCCESS: 'GET_BANK_TRANSFER_POOL_SUCCESS',
  GET_BANK_TRANSFER_POOL_FAILED: 'GET_BANK_TRANSFER_POOL_FAILED',

  GET_LIST_VA: 'GET_LIST_VA',
  GET_LIST_VA_SUCCESS: 'GET_LIST_VA_SUCCESS',
  GET_LIST_VA_FAILED: 'GET_LIST_VA_FAILED',

  GET_LIST_DEBT_RECOVERY: 'GET_LIST_DEBT_RECOVERY',
  GET_LIST_DEBT_RECOVERY_SUCCESS: 'GET_LIST_DEBT_RECOVERY_SUCCESS',
  GET_LIST_DEBT_RECOVERY_FAILED: 'GET_LIST_DEBT_RECOVERY_FAILED',

  CREATE_DEBT_RECOVERY: 'CREATE_DEBT_RECOVERY',
  CREATE_DEBT_RECOVERY_SUCCESS: 'CREATE_DEBT_RECOVERY_SUCCESS',
  CREATE_DEBT_RECOVERY_FAILED: 'CREATE_DEBT_RECOVERY_FAILED',

  CLOSE_DEBT_RECOVERY: 'CLOSE_DEBT_RECOVERY',
  CLOSE_DEBT_RECOVERY_SUCCESS: 'CLOSE_DEBT_RECOVERY_SUCCESS',
  CLOSE_DEBT_RECOVERY_FAILED: 'CLOSE_DEBT_RECOVERY_FAILED',

  CREATE_SHIPPING_ADDRESS: 'CREATE_SHIPPING_ADDRESS',
  CREATE_SHIPPING_ADDRESS_SUCCESS: 'CREATE_SHIPPING_ADDRESS_SUCCESS',
  CREATE_SHIPPING_ADDRESS_FAILED: 'CREATE_SHIPPING_ADDRESS_FAILED',

  GET_SHIPPING_ADDRESS: 'GET_SHIPPING_ADDRESS',
  GET_SHIPPING_ADDRESS_SUCCESS: 'GET_SHIPPING_ADDRESS_SUCCESS',
  GET_SHIPPING_ADDRESS_FAILED: 'GET_SHIPPING_ADDRESS_FAILED',

  UPDATE_SHIPPING_ADDRESS: 'UPDATE_SHIPPING_ADDRESS',
  UPDATE_SHIPPING_ADDRESS_SUCCESS: 'UPDATE_SHIPPING_ADDRESS_SUCCESS',
  UPDATE_SHIPPING_ADDRESS_FAILED: 'UPDATE_SHIPPING_ADDRESS_FAILED',

  DELETE_SHIPPING_ADDRESS: 'DELETE_SHIPPING_ADDRESS',
  DELETE_SHIPPING_ADDRESS_SUCCESS: 'DELETE_SHIPPING_ADDRESS_SUCCESS',
  DELETE_SHIPPING_ADDRESS_FAILED: 'DELETE_SHIPPING_ADDRESS_FAILED',

  MONITOR_LOG_API: 'MONITOR_LOG_API',
  MONITOR_LOG_API_SUCCESS: 'MONITOR_LOG_API_SUCCESS',
  MONITOR_LOG_API_FAILED: 'MONITOR_LOG_API_FAILED',
  LIST_SALE_ORDER_REJECT_REASON: 'LIST_SALE_ORDER_REJECT_REASON',
  LIST_SALE_ORDER_REJECT_REASON_SUCCESS: 'LIST_SALE_ORDER_REJECT_REASON_SUCCESS',
  LIST_SALE_ORDER_REJECT_REASON_FAILED: 'LIST_SALE_ORDER_REJECT_REASON_FAILED',

  OPEN_CANCEL_ORDER_MODEL: 'OPEN_CANCEL_ORDER_MODEL',

  SELECT_CART_ITEM_TO_CHECKOUT: 'SELECT_CART_ITEM_TO_CHECKOUT',
  SELECT_CART_ITEM_TO_CHECKOUT_SUCCESS: 'SELECT_CART_ITEM_TO_CHECKOUT_SUCCESS',
  SELECT_CART_ITEM_TO_CHECKOUT_FAILED: 'SELECT_CART_ITEM_TO_CHECKOUT_FAILED',

  GET_USER_PROFILE: 'GET_USER_PROFILE',
  GET_USER_PROFILE_SUCCESS: 'GET_USER_PROFILE_SUCCESS',
  GET_USER_PROFILE_FAILED: 'GET_USER_PROFILE_FAILED',

  GET_LIST_BUYNGON_CATEGORY: 'GET_LIST_BUYNGON_CATEGORY',
  GET_LIST_BUYNGON_CATEGORY_SUCCESS: 'GET_LIST_BUYNGON_CATEGORY_SUCCESS',
  GET_LIST_BUYNGON_CATEGORY_FAILED: 'GET_LIST_BUYNGON_CATEGORY_FAILED',

  ON_MODEL_ADD_PROMOTION: 'ON_MODEL_ADD_PROMOTION',
  ON_MODEL_ADD_PROMOTION_SUCCESS: 'ON_MODEL_ADD_PROMOTION_SUCCESS',
  ON_MODEL_ADD_PROMOTION_FAILED: 'ON_MODEL_ADD_PROMOTION_FAILED',

  ADD_WEBSITE_PROMOTION: 'ADD_WEBSITE_PROMOTION',
  ADD_WEBSITE_PROMOTION_SUCCESS: 'ADD_WEBSITE_PROMOTION_SUCCESS',
  ADD_WEBSITE_PROMOTION_FAILED: 'ADD_WEBSITE_PROMOTION_FAILED',

  GET_WEBSITE_PROMOTION: 'GET_WEBSITE_PROMOTION',
  GET_WEBSITE_PROMOTION_SUCCESS: 'GET_WEBSITE_PROMOTION_SUCCESS',
  GET_WEBSITE_PROMOTION_FAILED: 'GET_WEBSITE_PROMOTION_FAILED',

  DELETE_WEBSITE_PROMOTION: 'DELETE_WEBSITE_PROMOTION',
  DELETE_WEBSITE_PROMOTION_SUCCESS: 'DELETE_WEBSITE_PROMOTION_SUCCESS',
  DELETE_WEBSITE_PROMOTION_FAILED: 'DELETE_WEBSITE_PROMOTION_FAILED'
};
