import { Drawer } from 'antd';
import Button from 'antd/lib/button';
import Row from 'antd/lib/row';
import Spin from 'antd/lib/spin';
import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import actions from '../../redux/actions';
import { notificationSuccess } from '../../utils/Notification';
import dispatch from '../../utils/dispatch';
import DomainFilter from './DomainFilter';
import DomainForm from './DomainForm';
import DomainTable from './DomainTable';

const Website = () => {
  const listWebsite = useSelector(state => {
    return get(state, 'website.list.data', { count: 0, results: [], next: false });
  });
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const [detail, setDetail] = useState(null);

  useEffect(() => {
    dispatch(actions.getWebsiteListDrawler({ limit: 1000 }));
  }, []);

  useEffect(() => {
    setList(listWebsite.results);
  }, [listWebsite]);

  const handleClose = () => {
    setShowDrawer(false);
  };

  const syncWebsite = () => {
    setLoading(true);
    dispatch(
      actions.syncPurchaseWebsite({
        callback: ({ error, data }) => {
          setLoading(false);
          if (!error) {
            setList(data);
            notificationSuccess('Cập nhật thành công');
          }
        }
      })
    );
  };

  return (
    <Spin spinning={loading}>
      <Row justify="space-between">
        <Spin spinning={loading}>
          <Button onClick={syncWebsite} type="primary">
            Cập nhật danh sách
          </Button>
        </Spin>
        <Row>
          <DomainFilter setList={setList} />
        </Row>
      </Row>
      <DomainTable
        dataSource={list}
        setDetail={setDetail}
        setShowDrawer={setShowDrawer}
      />
      <Drawer
        width={500}
        onClose={handleClose}
        visible={showDrawer}
        title="Format Info"
      >
        <DomainForm domainDetail={detail} setShowDrawer={setShowDrawer} />
      </Drawer>
    </Spin>
  );
};

export default Website;
