import { all, takeLatest, call } from 'redux-saga/effects';
import TYPE from '../actions/TYPE';
import services from '../../services';

function* getLogApi({ payload }) {
  const { data, callback } = payload;
  try {
    const {
      data: result,
      success,
      message
    } = yield call(services.marketplace.monitor.getLog, data);
    if (!success) {
      callback({ error: message, data: null });
      return;
    }
    callback({ error: null, data: result });
  } catch (error) {
    callback({ error: true, data: null });
  }
}

export default function* root() {
  yield all([takeLatest(TYPE.MONITOR_LOG_API, getLogApi)]);
}
