import { Tabs, Typography } from 'antd';
import React, { createContext, useLayoutEffect, useState } from 'react';
import Carousel from '../../components/Mobile/Carousel';
import Setting from '../../components/Mobile/Setting';
import TopBrand from '../../components/Mobile/TopBrand';
import actions from '../../redux/actions';
import dispatch from '../../utils/dispatch';
import TopOrder from '../../components/Mobile/TopOrder';

const { Title } = Typography;

const TABS = [
  { key: 'setting', tab: 'Setting', Component: Setting },
  { key: 'top-brand', tab: 'Top Brand', Component: TopBrand },
  { key: 'carousel', tab: 'Carousel', Component: Carousel },
  { key: 'top-order', tab: 'Top Order', Component: TopOrder }
];

export const DealNgonContext = createContext();

export default function Mobile() {
  const [tab, setTab] = useState(TABS[0].key);

  useLayoutEffect(() => {
    dispatch(actions.getMobileSetting());
    dispatch(actions.getListTopBrand());
  }, []);

  return (
    <DealNgonContext.Provider value={{ tab }}>
      <div className="container dealngon">
        <Title className="title" level={5}>
          Mobile
        </Title>
        <Tabs onChange={setTab}>
          <>
            {TABS.map(tabItem => {
              return (
                <Tabs.TabPane
                  active={tabItem.key === tab}
                  tab={tabItem.tab}
                  key={tabItem.key}
                >
                  <tabItem.Component />
                </Tabs.TabPane>
              );
            })}
          </>
        </Tabs>
      </div>
    </DealNgonContext.Provider>
  );
}
