/* eslint-disable no-console */

import { Button, Space, Table, Typography } from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import moment from 'moment';
import { any, arrayOf, func } from 'prop-types';
import React from 'react';
import shortid from 'shortid';

import './style.less';

const { Column } = Table;

export default function DomainTable({
  dataSource,
  setDetail,
  setShowDrawer,
  ...restProps
}) {
  const handleDisableUS = ({ target }) => {
    console.log(target.checked);
    // call API disable website US
  };

  const handleShowDetail = item => {
    setShowDrawer(true);
    setDetail(item);
  };

  return (
    <Table
      className="domain-table"
      bordered
      rowKey={shortid}
      dataSource={dataSource}
      {...restProps}
    >
      <Column key="domain" dataIndex="domain" title="Domain" />
      <Column
        key="created_at"
        dataIndex="created_at"
        title="Ngày tạo"
        render={date => {
          return (
            <Typography.Text>{moment(date).format('DD/MM/YYYY')}</Typography.Text>
          );
        }}
      />
      <Column
        key="disable"
        dataIndex="disable"
        title="Không hỗ trợ địa chỉ Mỹ"
        filters={[{ text: 'Disable', value: true }]}
        onFilter={(value, record) => record.disable === value}
        render={(disable, record) => (
          <Checkbox
            // disabled
            key={record._id}
            checked={disable}
            onChange={handleDisableUS}
          />
        )}
      />
      <Column
        key="detail"
        dataIndex="detail"
        className="action"
        title="Action"
        render={(_, record) => {
          return (
            <Space size={16}>
              <Button
                key={record._id}
                onClick={() => {
                  return handleShowDetail(record);
                }}
              >
                Chi tiết
              </Button>
            </Space>
          );
        }}
      />
    </Table>
  );
}

DomainTable.propTypes = {
  dataSource: arrayOf(any),
  setDetail: func,
  setShowDrawer: func
};

DomainTable.defaultProps = {
  dataSource: [],
  setDetail: () => {},
  setShowDrawer: () => {}
};
