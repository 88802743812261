import Button from 'antd/lib/button';
import Col from 'antd/lib/col';
import Image from 'antd/lib/image';
import Row from 'antd/lib/row';
import Space from 'antd/lib/space';
import Text from 'antd/lib/typography/Text';
import Title from 'antd/lib/typography/Title';
import React from 'react';
import { useLocation } from 'react-router';
import { Redirect } from 'react-router-dom';
import { useGoogleLogin } from '@react-oauth/google';
import Google from '../../components/icons/Google';
import { IS_AUTH, Routes } from '../../contents/Constants';
import Images from '../../contents/Images';
import { MessageContent } from '../../contents/Labels';
import './style.less';
import { getStore } from '../../App';
import actions from '../../redux/actions';

const Login = () => {
  const location = useLocation();
  const { from } = location.state || { from: { pathname: Routes.Transaction } };
  const isAuth = localStorage.getItem(IS_AUTH);

  const onGoogle = useGoogleLogin({
    onSuccess: response => {
      getStore().dispatch(actions.login(response));
    }
  });

  if (isAuth) {
    return <Redirect to={from} />;
  }

  return (
    <Space direction="vertical" className="form-login">
      <Row gutter={[16]} align="middle" justify="center">
        <Col flex={1}>
          <Image preview={false} width={40} src={Images.logo} alt="logo" />
        </Col>
        <Col flex={4}>
          <Title level={4} className="form-login-title">
            {MessageContent.LoginTitle}
          </Title>
        </Col>
      </Row>
      <Row gutter={[0, 20]} align="middle" justify="center">
        <Col>
          <Text>{MessageContent.LoginWith}</Text>
        </Col>
      </Row>

      <Row align="middle" justify="center">
        <Col>
          <Button
            style={{
              display: 'flex',
              alignItems: 'center',
              padding: '6px 16px',
              boxSizing: 'content-box',
              borderRadius: '8px',
              cursor: 'pointer'
            }}
            icon={<Google />}
            onClick={onGoogle}
            className="google-btn"
          >
            Đăng nhập bằng Google
          </Button>
        </Col>
      </Row>
    </Space>
  );
};

export default Login;
