import { Button, Card, Spin } from 'antd';
import { get } from 'lodash';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import useUploadFile from '../../hooks/useUploadFile';
import actions from '../../redux/actions';
import dispatch from '../../utils/dispatch';
import UploadImage from '../UploadImage';

export default function TopOrder() {
  const [loading, setLoading] = useState(false);
  const topOrder = useSelector(state => {
    return get(state, 'mobile.settings.topOrder', {});
  });
  const backgroundTopOrder = topOrder?.background;

  const [file, handleUploadFile, handleReset] = useUploadFile(
    backgroundTopOrder?.url
  );
  const handleSave = () => {
    dispatch(
      actions.updateMobileSetting({
        data: {
          topOrder: {
            background: {
              type: file?.type || backgroundTopOrder?.type,
              url: file?.link
            }
          }
        },
        callback: () => {
          setTimeout(() => {
            setLoading(false);
          }, 500);
        }
      })
    );
  };

  return (
    <Spin spinning={loading}>
      <div className="fragment-theme">
        <div className="btn-action">
          <div />
          <Button type="primary" onClick={handleSave}>
            <b>Lưu </b>
          </Button>
        </div>

        <div className="dealngon-banner">
          <Card title="Banner Top Order" className="card">
            <div>
              <UploadImage
                isPreventCrop
                imgUrl={file.link}
                loading={file.isFetching}
                beforeUpload={handleUploadFile}
                handleReset={handleReset}
              />
              <div style={{ width: 300 }}> </div>
            </div>
          </Card>
        </div>
      </div>
    </Spin>
  );
}
