import { Editor } from '@tinymce/tinymce-react';
import { Button, Space, Spin } from 'antd';
import { string } from 'prop-types';
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MESSAGE_CONTENT } from '../../contents/Constants';
import actions from '../../redux/actions';
import { notificationSuccess } from '../../utils/Notification';

export default function CustomEditor({ type }) {
  const rawContent = useSelector(state => state?.configuration?.content);
  const defaultContent = rawContent?.[type] || '';
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const editorRef = useRef(null);

  const handleReset = () => editorRef.current.resetContent();

  const handleSave = () => {
    setLoading(true);
    dispatch(
      actions.updateConfiguration({
        data: { content: { ...rawContent, [type]: editorRef.current.getContent() } },
        callback: ({ error }) => {
          setLoading(false);
          if (!error) {
            notificationSuccess(MESSAGE_CONTENT.updateSuccess);
          }
        }
      })
    );
  };

  return (
    <div className="input-editor">
      <Spin spinning={loading}>
        <Space
          style={{ display: 'flex', justifyContent: 'flex-end' }}
          className="btn-action"
        >
          <Button className="save" onClick={handleSave}>
            Lưu lại
          </Button>
          <Button type="default" onClick={handleReset}>
            Reset
          </Button>
        </Space>

        <Editor
          apiKey="cfeiosctuxqogghe6rbepetv42nieg4cz0gs6efkr8dmrpjp"
          onInit={(_, editor) => {
            editorRef.current = editor;
          }}
          initialValue={defaultContent}
          init={{
            height: 600,
            link_assume_external_targets: true,
            plugins:
              'print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons',
            menubar: 'file edit view insert format tools table help',
            content_style:
              'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
            toolbar:
              'undo redo | bold italic underline | alignleft aligncenter alignright alignjustify | outdent indent | numlist bullist | forecolor backcolor'
          }}
        />
      </Spin>
    </div>
  );
}

CustomEditor.propTypes = {
  type: string
};

CustomEditor.defaultProps = {
  type: ''
};
