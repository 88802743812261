/* eslint-disable react/prop-types */
/* eslint-disable no-plusplus */

import { CheckOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Col, Row, Space, Table, Tag, Tooltip, Typography } from 'antd';
import { isEmpty } from 'lodash';
import find from 'lodash/find';
import get from 'lodash/get';
import size from 'lodash/size';
import { any, arrayOf, bool, string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useSaleCreateOrderForCustomer } from '../../../hooks/useSaleCreateOrderForCustomer';
import actions from '../../../redux/actions';
import { convertDollar, convertVND, roundNumber } from '../../../utils/convert';
import dispatch from '../../../utils/dispatch';
import {
  checkListWebsiteDisableUS,
  getListWebsiteDisableUS,
  getTypeAddress
} from '../../../utils/helpers';
import ModalConfirmSaleOrder from './ModalConfirmSaleOrder';
import ModalEditQuotation from './ModalEditQuotation';
import ModelAddPromotion from './ModelAddPromotion';
import './styles.less';
import Image from './Image';
// import { cartFetch } from './Constant';

function useModelConfirm() {
  const [showModelConfirm, setShowModelConfirm] = useState(false);
  const { carts, userSelected } = useSaleCreateOrderForCustomer();
  const handleShowModelConfirm = () => {
    dispatch(
      actions.selectCartItemToCheckout({
        cartItemIds: carts.map(i => `${i._id}`),
        userId: userSelected?._id,
        callback: ({ error }) => {
          setTimeout(() => {
            if (!error) {
              setShowModelConfirm(true);
            }
            dispatch(actions.offLoading());
          }, 1 * 1000);
        }
      }),
      true
    );
  };

  const handleHideModelConfirm = () => setShowModelConfirm(false);

  return {
    visible: showModelConfirm,
    onShow: handleShowModelConfirm,
    onHide: handleHideModelConfirm
  };
}

const CartSaleOrder = ({ title, isDeal }) => {
  const methods = useModelConfirm();
  const { onShow: onShowModelConfirm } = methods;

  const cartGroup = useSelector(
    state => state?.transaction?.cartGroup || [],
    shallowEqual
  );

  const [data, setData] = useState([]);
  const [isDisable, setIsDisable] = useState(false);
  const userId = useSelector(state => {
    return get(state, 'customer.userSelected._id');
  });
  // const codeContent = useSelector(state => {
  //   return get(state, 'transaction.draftSaleOrder.payment_code', '');
  // });
  const { carts, priceTotal, isWholeSale } = useSaleCreateOrderForCustomer();
  const listWebsiteRejected = useSelector(state => {
    return get(state, 'website.disable', []);
  });
  const listUserAddress = useSelector(state => {
    return get(state, 'customer.userSelected.address_management');
  });
  // const totalPrice = useSelector(state => {
  //   if (isDeal)
  //     return get(state, 'transactionDeal.currentDealOrder.total_last_price', 0);
  //   return get(state, 'transaction.draftSaleOrder.total_price_after_sale_VND', 0);
  // });

  // const totalPriceDeal = useSelector(state => {
  //   return get(state, 'transactionDeal.currentDealOrder.total_last_price', 0);
  // });

  const handleDelete = key => {
    const quotationId = find(data, item => {
      return item._id === key;
    })._id;
    dispatch(actions.deleteQuotation(quotationId, userId), true);
  };

  const handleConfirmSO = () => {
    // const paymentCode = `${codeContent}-VCB`;
    dispatch(actions.confirmSaleOrder(userId, ''), true);
  };

  const handleConfirmDO = deal_gift_card_id => {
    const requestDate = {
      user: userId,
      payment: { type: 'gift_card', content: { deal_gift_card_id } }
    };
    dispatch(actions.confirmDealOrder(requestDate), true);
  };

  const handleEditQuotation = quotationId =>
    dispatch(actions.handleEditQuotation(quotationId));

  const columnProps = {
    align: 'left',
    ellipsis: true,
    width: 200
  };

  const columns = [
    {
      ...columnProps,
      width: 80,
      fixed: 'left',
      title: '',
      dataIndex: 'image',
      render: text => {
        return (
          <div
            style={{
              maxWidth: 48,
              maxHeight: 48,
              overflow: 'hidden'
            }}
          >
            <Image
              src={text}
              style={{ width: '100%', maxHeight: '100%', objectFit: 'cover' }}
            />
          </div>
        );
      }
    },
    {
      ...columnProps,
      width: 160,
      fixed: 'left',
      title: 'Link sản phẩm',
      dataIndex: 'link'
    },
    !isDeal
      ? {
          ...columnProps,
          fixed: 'left',
          title: 'Tỉ giá',
          dataIndex: 'price_list',
          align: 'right',
          width: 80,
          render: (_, record) => {
            return convertVND(get(record, 'price_list.rate', 0));
          }
        }
      : {},
    {
      ...columnProps,
      title: 'Link hình ảnh',
      dataIndex: 'image'
    },
    {
      ...columnProps,
      title: 'Tên sản phẩm',
      dataIndex: 'name'
    },
    {
      ...columnProps,
      title: 'Màu',
      dataIndex: 'color'
    },
    {
      width: 120,
      title: 'Size',
      dataIndex: 'size'
    },
    {
      ...columnProps,
      title: 'Số lượng',
      dataIndex: 'quantity',
      align: 'right'
    },
    isWholeSale
      ? {}
      : {
          width: 200,
          title: 'Ngành hàng',
          dataIndex: 'category',
          render: text => text?.vn || ''
        },
    {
      ...columnProps,
      title: `Giá`,
      dataIndex: 'price',
      align: 'right',
      render: (_, record) => {
        const storePromotion = record.store_promotion;
        const isPromotion = !isEmpty(storePromotion);
        return (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {isPromotion && (
              <Tag color="#2db7f5">{`Khuyến mãi giảm ${storePromotion.discount_amount_original}${storePromotion.discount_amount_type}`}</Tag>
            )}
            {+record.price === 0 && <Tag color="green">Quà tặng</Tag>}
            <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
              <p
                style={{
                  marginLeft: 10,
                  marginBottom: 0,
                  textDecoration: 'line-through'
                }}
              >
                {isPromotion ? convertDollar(record.price, record.location) : ''}
              </p>
              <>
                {convertDollar(
                  roundNumber(record.price - record.sale_unit_price, 2),
                  record.location
                )}
              </>
            </div>
          </div>
        );
      }
    },
    !isDeal
      ? {
          ...columnProps,
          title: 'Đơn giá (đ)',
          dataIndex: 'price_VND',
          align: 'right',
          render: (_, record) => {
            const lastPriceUnit =
              (+record.price - (record.sale_unit_price || 0)) *
                +record.price_list?.rate || 0;
            return convertVND(lastPriceUnit, 0);
          }
        }
      : {},
    // {
    //   ...columnProps,
    //   title: 'Giá giảm',
    //   dataIndex: 'sale_price_original',
    //   align: 'right',
    //   render: (text, record) => {
    //     // if (isDeal) return record?.price - record?.last_price;
    //     const salePriceType = get(record, 'sale_price_type', 'none');
    //     if (salePriceType !== 'none') {
    //       return `${text}${get(record, 'sale_price_type')}`;
    //     }
    //     return '';
    //   }
    // },
    // {
    //   ...columnProps,
    //   title: 'Mã giảm giá',
    //   dataIndex: 'note'
    // },
    // {
    //   ...columnProps,
    //   title: 'Hình thức',
    //   dataIndex: 'sale_price_type',
    //   render: text => {
    //     const result = find(SALE_PRICE_TYPE, item => {
    //       return item.value === text;
    //     });
    //     return get(result, 'name', '');
    //   }
    // },

    {
      width: 200,
      title: 'Phí',
      align: 'right',
      dataIndex: 'fee',
      render: (_, record) => {
        const weightFee = record?.weight_fee;
        const serviceFee = record?.service_fee_VND;
        const surchargeFee = record?.surcharge_fee;
        return (
          <Tooltip
            title={
              <ul style={{ fontSize: 12 }}>
                <li>
                  Phí vận chuyển:
                  <strong color="#1D50FF">
                    {isWholeSale ? `Tính khi về VN` : convertVND(weightFee)}
                  </strong>
                </li>
                <li>
                  Phí dịch vụ:
                  <strong color="#1D50FF">{convertVND(serviceFee)}</strong>
                </li>
                <li>
                  Phí phụ thu:
                  <strong color="#1D50FF">
                    {isWholeSale ? `Tính khi về VN` : convertVND(surchargeFee)}
                  </strong>
                </li>
              </ul>
            }
          >
            {convertVND(weightFee + surchargeFee + serviceFee)}
          </Tooltip>
        );
      }
    },
    {
      width: 200,
      title: 'Thành tiền',
      align: 'right',
      dataIndex: 'price_after_sale_VND',
      render: text => convertVND(text)
    },
    {
      ...columnProps,
      title: 'Ghi chú',
      dataIndex: 'notice'
    },
    {
      width: 200,
      fixed: 'right',
      title: 'Thao tác',
      align: 'center',
      dataIndex: 'actions',
      render: (_, record) => {
        return (
          <Space>
            <Button
              type="ghost"
              onClick={() => handleEditQuotation(record._id)}
              icon={<EditOutlined />}
            >
              Sửa
            </Button>

            <Button
              type="danger"
              onClick={() => {
                if (isDeal) {
                  return dispatch(actions.deleteItemDealOrder(record._id), true);
                }
                return handleDelete(record._id);
              }}
              icon={<DeleteOutlined />}
            >
              Xoá
            </Button>
          </Space>
        );
      }
    }
  ];

  const listWebsiteDisableUS = getListWebsiteDisableUS(carts, listWebsiteRejected);

  const isMultiWebsiteInDealMode =
    isDeal && size([...new Set(carts.map(item => item.website))]) > 1;

  const checkDisableUS = () => checkListWebsiteDisableUS(carts, listWebsiteRejected);

  useEffect(() => {
    setData(carts);
    if (getTypeAddress(listUserAddress) === 'usa' && checkDisableUS()) {
      setIsDisable(true);
    } else setIsDisable(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carts]);

  const { quotationId } = useSelector(state => {
    const quotation = state.transaction.editQuotation;
    return {
      quotationId: quotation?._id
    };
  });

  const onModelAddPromotion = website => {
    dispatch(actions.onModelAddPromotion(website));
  };

  const columns1 = [
    {
      title: 'Thông tin sản phẩm',
      dataIndex: 'website',
      key: 'website',
      fixed: 'left',
      render: (_, record) => {
        const [web] = record.website.split('_');
        return (
          <>
            <b style={{ color: 'red' }}>
              {web} ({record?.list?.length || 0})
            </b>
            <Button
              style={{ color: '#00CC79', marginLeft: 20 }}
              onClick={() => onModelAddPromotion(record.website)}
            >
              + Thêm khuyến mãi website
            </Button>
          </>
        );
      }
    }
  ];

  const expandedRowRender = expand => {
    return (
      <Table
        bordered
        size="small"
        dataSource={expand.list}
        scroll={{ x: '100%' }}
        columns={columns}
        rowClassName="editable-row"
        pagination={{
          pageSize: 5,
          size: 'small'
        }}
      />
    );
  };

  return (
    <div style={{ marginTop: 16 }}>
      <Typography.Title level={5} className="title">
        {title}
      </Typography.Title>
      <Row justify="space-between">
        <Row>
          <Col span={24}>
            <Typography.Text>
              <b>{'Tổng giá trị đơn hàng: '}</b>
              <Typography.Text mark>{convertVND(priceTotal)}</Typography.Text>
            </Typography.Text>
          </Col>
          {isDisable && (
            <Col span={24}>
              <Typography.Text type="danger" italic>
                <b>{`${listWebsiteDisableUS[0]} chưa hỗ trợ nhận hàng tại địa chỉ Mỹ`}</b>
              </Typography.Text>
            </Col>
          )}
          {isMultiWebsiteInDealMode && (
            <Typography.Text type="danger" italic>
              Có nhiều sản phẩm khác website trong giỏ hàng
            </Typography.Text>
          )}
        </Row>
        <Button
          icon={<CheckOutlined />}
          onClick={onShowModelConfirm}
          disabled={isDisable || isMultiWebsiteInDealMode}
          type="primary"
          className="button"
        >
          Xác nhận đơn hàng
        </Button>
      </Row>
      <Table
        columns={columns1}
        expandable={{
          expandedRowRender,
          defaultExpandAllRows: true
        }}
        scroll={{ x: '80vw' }}
        dataSource={cartGroup.map((i, k) => ({ ...i, key: k.toString() }))}
        size="small"
      />
      {methods.visible && (
        <ModalConfirmSaleOrder
          {...methods}
          isDeal={isDeal}
          onConfirmSaleOrder={handleConfirmSO}
          onConfirmDealOrder={handleConfirmDO}
        />
      )}
      {quotationId && <ModalEditQuotation />}
      {<ModelAddPromotion />}
    </div>
  );
};

CartSaleOrder.propTypes = {
  isDeal: bool,
  title: string,
  carts: arrayOf(any)
};

CartSaleOrder.defaultProps = {
  isDeal: false,
  title: '',
  carts: []
};

export default CartSaleOrder;
