import { all, takeLatest, put, call } from 'redux-saga/effects';
import { get } from 'lodash';
import TYPE from '../actions/TYPE';
import services from '../../services';
import actions from '../actions';

function* getWebsiteListCrawler({ payload }) {
  try {
    const data = yield call(services.getWebsiteList, payload);
    yield put({
      type: TYPE.GET_WEBSITE_LIST_CRAWLER_SUCCESS,
      data
    });
  } catch (err) {
    yield put({
      type: TYPE.GET_WEBSITE_LIST_CRAWLER_FAILED
    });
  }
}

function* addFormatWebsiteCrawler({ payload }) {
  try {
    const data = yield call(services.addFormatWebsiteCrawler, payload);
    yield put({
      type: TYPE.ADD_FORMAT_WEBSITE_CRAWLER_SUCCESS,
      ...data
    });
  } catch (err) {
    yield put({
      type: TYPE.ADD_FORMAT_WEBSITE_CRAWLER_FAILED
    });
  }
}

function* editFormatWebsiteCrawler({ payload }) {
  try {
    const data = yield call(services.editFormatWebsiteCrawler, payload);
    yield put({
      type: TYPE.EDIT_FORMAT_WEBSITE_CRAWLER_SUCCESS,
      ...data
    });
  } catch (err) {
    yield put({
      type: TYPE.EDIT_FORMAT_WEBSITE_CRAWLER_FAILED
    });
  }
}

function* createFormatWebsiteCrawler({ payload }) {
  const { data, callback } = payload;
  try {
    const result = yield call(services.createFormatWebsiteCrawler, data);
    callback({ error: false, data: get(result, 'data.results', []) });
  } catch (error) {
    callback({ error: true, data: null });
  }
}

function* syncPurchaseWebsite({ payload }) {
  const { callback } = payload;
  try {
    const result = yield call(services.syncPurchaseWebsite);
    callback({ error: false, data: get(result, 'data.results', []) });
  } catch (error) {
    callback({ error: true, data: null });
  }
}

function* updateCrawlWebsite(action) {
  try {
    const {
      payload: { domain }
    } = action;
    const data = yield call(services.editFormatWebsiteCrawler, domain);
    yield put({
      type: TYPE.EDIT_FORMAT_WEBSITE_CRAWLER_SUCCESS,
      ...data
    });
  } catch (error) {
    yield put({ type: TYPE.UPDATE_CRAWL_WEBSITE_FAILED, msg: error });
  }
}

function* getListWebsiteDisableUs() {
  try {
    yield put(actions.loading());
    const { data } = yield call(services.getListWebsiteDisableUs);
    yield put({ type: TYPE.GET_LIST_WEBSITE_DISABLE_US_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: TYPE.GET_LIST_WEBSITE_DISABLE_US_FAILED, msg: error });
  } finally {
    yield put(actions.offLoading());
  }
}

function* deleteWebsite(action) {
  try {
    yield put(actions.loading());
    yield call(services.deleteWebsite, action.id);
    yield put({ type: TYPE.DELETE_WEBSITE_SUCCESS, id: action.id });
  } catch (err) {
    yield put({ type: TYPE.DELETE_WEBSITE_FAILED, msg: err });
  } finally {
    yield put(actions.offLoading());
  }
}

export default function* root() {
  yield all([
    takeLatest(TYPE.GET_WEBSITE_LIST_CRAWLER, getWebsiteListCrawler),
    takeLatest(TYPE.ADD_FORMAT_WEBSITE_CRAWLER, addFormatWebsiteCrawler),
    takeLatest(TYPE.EDIT_FORMAT_WEBSITE_CRAWLER, editFormatWebsiteCrawler),
    takeLatest(TYPE.CREATE_FORMAT_WEBSITE_CRAWLER, createFormatWebsiteCrawler),
    takeLatest(TYPE.SYNC_PURCHASE_WEBSTIE, syncPurchaseWebsite),
    takeLatest(TYPE.UPDATE_CRAWL_WEBSITE, updateCrawlWebsite),
    takeLatest(TYPE.GET_LIST_WEBSITE_DISABLE_US, getListWebsiteDisableUs),
    takeLatest(TYPE.DELETE_WEBSITE, deleteWebsite)
  ]);
}
