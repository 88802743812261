import { all, fork } from 'redux-saga/effects';
import account from './accountSaga';
import bank from './bankSaga';
import categoryDealSaga from './categoryDealSaga';
import category from './categorySaga';
import configuration from './configurationSaga';
import customer from './customerSaga';
import dealngon from './dealngonSaga';
import dealWithdraw from './dealWithdrawSaga';
import exchangePointRequest from './exchangePointRequestSaga';
import location from './locationSaga';
import logs from './logs';
import manageMetaDealSaga from './manageMetaDealSaga';
import paymentContent from './paymentContentSaga';
import profile from './profileSaga';
import publicSaga from './publicSaga';
import seller from './seller';
import shipping from './shippingSaga';
import topiDealProductSaga from './topicDealProductSaga';
import transactionDeal from './transactionDealSaga';
import transaction from './transactionSaga';
import website from './websiteSaga';
import withdrawal from './withdrawalSaga';
import mobile from './mobileSaga';
import campaignSaga from './campaignSaga';
import bankTransfer from './bankTransferSaga';
import debtRecovery from './debtRecovery';
import shippingAddress from './shippingAddressSaga';
import monitorSaga from './monitorSaga';

export default function* root() {
  yield all([
    fork(profile),
    fork(customer),
    fork(account),
    fork(transaction),
    fork(configuration),
    fork(publicSaga),
    fork(location),
    fork(logs),
    fork(website),
    fork(category),
    fork(dealWithdraw),
    fork(dealngon),
    fork(shipping),
    fork(paymentContent),
    fork(withdrawal),
    fork(exchangePointRequest),
    fork(bank),
    fork(transactionDeal),
    fork(manageMetaDealSaga),
    fork(seller),
    fork(categoryDealSaga),
    fork(topiDealProductSaga),
    fork(mobile),
    fork(campaignSaga),
    fork(bankTransfer),
    fork(debtRecovery),
    fork(shippingAddress),
    fork(monitorSaga)
  ]);
}
