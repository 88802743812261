import { Modal, Radio, Space, Spin, Typography } from 'antd';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { bool, func } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import actions from '../../../redux/actions';
import dispatch from '../../../utils/dispatch';

const TRANSFER_OPTION = 'transfer';

export default function ModalConfirmSaleOrder({
  isDeal,
  visible,
  onHide,
  onConfirmSaleOrder,
  onConfirmDealOrder
}) {
  const [value, setValue] = useState(TRANSFER_OPTION);
  const loading = useSelector(
    state =>
      !!state.common.loadingImprove?.confirmDealOrder ||
      !!state.common.loadingImprove?.getCurrentDealOrder
  );

  const listDealOfUser = useSelector(state => {
    if (!isDeal) return [];
    const mainWebsite =
      state.transactionDeal?.currentDealOrder?.quotation_list?.[0]?.website;
    const totalLastPrice = state.transactionDeal?.currentDealOrder?.total_last_price;
    return (state.transactionDeal.listDealOfUser || []).reduce((acc, cur) => {
      const dealId = cur?._id;
      const dealWebsite =
        cur?.deal_purchase_transaction?.deal_order_id?.history?.website;
      const dealName = cur?.deal_purchase_transaction?.deal_order_id?.history?.name;
      const dealAmount = cur?.deal_purchase_transaction?.deal_order_id?.amount || 0;
      const dealPriceInUsed = cur?.price_in_used || 0;
      const dealRemain = dealAmount - dealPriceInUsed;

      if (dealWebsite !== mainWebsite) return acc;
      if (dealRemain < totalLastPrice) return acc;
      const result = {
        value: dealId,
        label: `${dealName} (Giá trị: $${dealAmount},Đã sử dụng $${dealPriceInUsed}, Còn lại $${dealRemain} )`
      };
      return acc.concat(result);
    }, []);
  });

  const onChange = event => setValue(event.target.value);

  const handleOk = () => {
    if (value === TRANSFER_OPTION) {
      onConfirmSaleOrder();
      onHide();
      return;
    }

    onConfirmDealOrder(value);
  };

  const handleCancel = () => onHide();

  const haveNotDeal = isDeal && isEmpty(listDealOfUser);
  const customerId = useSelector(state => {
    return get(state, 'customer.userSelected._id');
  });
  useEffect(() => {
    if (customerId) {
      dispatch(actions.getListDealByUser(customerId));
      dispatch(actions.getCurrentDealOrder(customerId));
    }
  }, [customerId]);

  return (
    <Modal
      title="Chọn hình thức thanh toán"
      visible={visible}
      onOk={handleOk}
      okButtonProps={{ disabled: loading || haveNotDeal }}
      cancelButtonProps={{ disabled: haveNotDeal }}
      onCancel={handleCancel}
    >
      <Spin spinning={loading}>
        {isDeal && isEmpty(listDealOfUser) && (
          <Typography>Vui lòng mua deal để thanh toán</Typography>
        )}
        <Radio.Group onChange={onChange} value={value}>
          <Space direction="vertical">
            {!isDeal && <Radio value={TRANSFER_OPTION}>Chuyển khoản</Radio>}
            {isDeal &&
              listDealOfUser.map((item, index) => (
                <Radio value={item.value} key={index}>
                  {item.label}
                </Radio>
              ))}
          </Space>
        </Radio.Group>
      </Spin>
    </Modal>
  );
}

ModalConfirmSaleOrder.propTypes = {
  isDeal: bool,
  visible: bool,
  onHide: func,
  onConfirmSaleOrder: func,
  onConfirmDealOrder: func
};

ModalConfirmSaleOrder.defaultProps = {
  isDeal: false,
  visible: false,
  onHide: () => {},
  onConfirmSaleOrder: () => {},
  onConfirmDealOrder: () => {}
};
