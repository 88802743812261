import { Button, Typography } from 'antd';
import { bool, number, string } from 'prop-types';
import React from 'react';
import { convertDollar } from '../../../utils/convert';
import Image from './Image';
import './styles.less';
import { useStorePromotion } from '../../../hooks/useStorePromotion';

export const Line = ({
  image,
  name,
  price,
  quantity,
  color,
  size,
  showDelete,
  location,
  _id
}) => {
  const { onDelete } = useStorePromotion();

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: '1px solid #E9ECF4',
        padding: '20px 5px 20px 5px',
        width: '100%'
      }}
    >
      <div
        style={{ maxWidth: 56, maxHeight: 56, overflow: 'hidden', marginRight: 5 }}
      >
        <Image
          src={image}
          style={{ width: '100%', height: '100%', objectFit: 'cover' }}
        />
      </div>
      <div style={{ width: '100%' }}>
        <Typography.Text
          className="break-word"
          style={{ width: 245, color: 'green' }}
        >
          {name}
        </Typography.Text>
        <div>
          <Typography className="break-word">
            <b>Màu:</b> {color}
          </Typography>
          <Typography className="break-word">
            <b>Size:</b> {size}
          </Typography>
        </div>
      </div>
      <div>
        <Typography>{convertDollar(price, location)}</Typography>
        <Typography style={{ float: 'right' }}>x{quantity}</Typography>
      </div>
      {showDelete && (
        <Button
          style={{ marginLeft: 5 }}
          size="small"
          danger
          onClick={() => onDelete([_id])}
        >
          Xóa
        </Button>
      )}
    </div>
  );
};

Line.propTypes = {
  image: string,
  name: string,
  price: number,
  quantity: number,
  color: string,
  size: string,
  showDelete: bool,
  location: string,
  _id: string
};

Line.defaultProps = {
  image: '',
  name: '',
  price: 0,
  quantity: 0,
  color: '',
  size: '',
  showDelete: true,
  location: 'USA',
  _id: null
};
