import Spin from 'antd/lib/spin';
import get from 'lodash/get';
import map from 'lodash/map';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CustomizeTable from '../../../components/CustomizeTable/CustomizeTable';
import { LIMIT_PAGE_DEFAULT, TRANSACTION_STATUS } from '../../../contents/Constants';
import { MessageContent } from '../../../contents/Labels';
import actions from '../../../redux/actions';
import dispatch from '../../../utils/dispatch';
import TransactionRetailDetail from '../TransactionDetail';
import columnData from './columnData';
// import './style.less';

const TransactionRetailList = () => {
  const { transactions, count } = useSelector(state => {
    return get(state, 'transaction.list', {});
  });
  const loading = useSelector(state => {
    return get(state, 'transaction.isLoading', false);
  });
  const [page, setPage] = useState(1);
  // eslint-disable-next-line no-unused-vars
  const [activeTab, setActiveTab] = useState(
    TRANSACTION_STATUS.customerConfirmed.key
  );

  useEffect(() => {
    dispatch(
      actions.getTransactionList({
        status: activeTab,
        page,
        limit: LIMIT_PAGE_DEFAULT,
        source: 'retail_page'
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const data = map(transactions, item => {
    return { key: item._id, ...item };
  }).filter(item => item?.is_retail_customer && !item?.is_cs_verify);
  const handleChangePage = pageIndex => {
    return setPage(pageIndex);
  };

  return (
    <Spin spinning={loading}>
      <CustomizeTable
        title={MessageContent.TransactionInfo}
        columns={columnData[activeTab]}
        data={data}
        count={count}
        currentPage={page}
        onPageChange={handleChangePage}
        activeCategoryTab={activeTab}
      />
      <TransactionRetailDetail />
    </Spin>
  );
};

export default TransactionRetailList;
