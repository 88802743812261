import { Form, Modal, Select, Typography } from 'antd';
import get from 'lodash/get';
import React from 'react';
import { useSelector } from 'react-redux';
import actions from '../../../redux/actions';
import dispatch from '../../../utils/dispatch';

export default function CancelOrderModel() {
  const rejectReasons = useSelector(state =>
    get(state, 'transaction.rejectReasons', [])
  );

  const { status, orderId } = useSelector(state =>
    get(state, 'transaction.cancelOrderModel', false)
  );

  const [form] = Form.useForm();
  const onHideModal = () => {
    dispatch(actions.openCancelOrderModel(false));
  };

  const onRejectOrder = () => {
    dispatch(
      actions.updateWaitingSaleOrder({
        id: orderId,
        type: 'reject',
        reason: form.getFieldValue()?.reason
      }),
      false
    );
    dispatch(actions.openCancelOrderModel(false));
    form.resetFields();
  };
  const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  return (
    <Modal
      title="Vui lòng điền"
      open={status}
      onOk={onRejectOrder}
      onCancel={onHideModal}
      okText="Hủy đơn"
      cancelText="Trở lại"
    >
      <Form form={form} labelCol={{ span: 6 }}>
        <Form.Item
          label={<Typography.Text strong>Lý do hủy đơn</Typography.Text>}
          name="reason"
          rules={[{ required: true, message: 'Không được để trống' }]}
        >
          <Select
            showSearch
            style={{ width: '70%' }}
            placeholder="Select a reason"
            optionFilterProp="children"
            filterOption={filterOption}
            options={rejectReasons.map(i => ({ value: i?._id, label: i?.content }))}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}
