import { Button, Form, Input, InputNumber, Modal, Select, Space, Spin } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  LOCATION_TYPES,
  LOCATION_TYPE_DEFAULT,
  RULES_QUOTATION,
  SALE_PRICE_TYPE
} from '../../../contents/Constants';
import actions from '../../../redux/actions';
import dispatch from '../../../utils/dispatch';
import { useSaleCreateOrderForCustomer } from '../../../hooks/useSaleCreateOrderForCustomer';

export default function ModalEditQuotation() {
  const { categories, isWholeSale } = useSaleCreateOrderForCustomer();

  const { quotationId, editQuotation } = useSelector(state => {
    const quotation = state.transaction.editQuotation;
    return {
      quotationId: quotation?._id,
      editQuotation: {
        link: quotation?.link,
        image: quotation?.image,
        name: quotation?.name,
        location: quotation?.location || LOCATION_TYPE_DEFAULT.value,
        color: quotation?.color,
        size: quotation?.size,
        quantity: quotation?.quantity,
        price: quotation?.price,
        salePrice: quotation?.sale_price_original,
        salePriceType: quotation?.sale_price_type,
        note: quotation?.note,
        notice: quotation?.notice,
        category_obj: quotation?.category?.id
      }
    };
  });
  const handleHideModelEdit = () =>
    dispatch(actions.handleHideModelEdit(quotationId));

  const handleEdit = values => {
    const lineFormat = {
      link: values.link,
      location: editQuotation?.location,
      image: values.image,
      name: values.name,
      color: values.color,
      size: values.size,
      quantity: values.quantity,
      price: values.price,
      sale_price: values.salePrice,
      sale_price_type: values.salePriceType,
      note: values?.note || '',
      notice: values?.notice || '',
      created_from: 'wadmin'
    };

    if (!isWholeSale) {
      lineFormat.category =
        categories.find(i => i.id === values?.category_obj) || {};
    }
    dispatch(actions.handleEdit({ ...lineFormat }));
  };
  const loading = useSelector(state => !!state.common.loadingImprove?.handleEdit);

  const RULES = RULES_QUOTATION(
    editQuotation?.salePriceType,
    editQuotation?.price,
    editQuotation?.quantity
  );

  const locationOfItem = LOCATION_TYPES.find(
    i => i.value === editQuotation.location
  );
  const salePriceTypeOfItem = SALE_PRICE_TYPE.find(
    i => i.value === locationOfItem.unit
  );

  const filterOption = (input, option) => {
    return (option?.children ?? '').toLowerCase().includes(input.toLowerCase());
  };

  return (
    <Modal
      title="Chỉnh sửa sản phẩm"
      visible={quotationId}
      onOk={() => {}}
      footer={null}
      onCancel={handleHideModelEdit}
    >
      <Spin spinning={loading}>
        <Form
          name="edit-quotation"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          initialValues={editQuotation}
          onFinish={handleEdit}
          // onFinishFailed={errors => console.log(errors)}
          autoComplete="off"
        >
          <Form.Item
            label="Link sản phẩm"
            name="link"
            rules={[
              {
                required: true,
                message: 'Nhập link sản phẩm'
              },
              ...RULES.find(item => item.dataIndex === 'link').rules
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item label="Link hình ảnh" name="image">
            <Input />
          </Form.Item>

          <Form.Item
            label="Tên sản phẩm"
            name="name"
            rules={[
              {
                required: true,
                message: 'Nhập tên sản phẩm'
              }
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item label="Màu" name="color">
            <Input />
          </Form.Item>

          <Form.Item label="Size" name="size">
            <Input />
          </Form.Item>

          <Form.Item
            label="Số lượng"
            name="quantity"
            rules={[
              {
                required: true,
                message: 'Nhập số lượng'
              },
              ...RULES.find(item => item.dataIndex === 'quantity').rules
            ]}
          >
            <InputNumber />
          </Form.Item>

          {!isWholeSale && (
            <Form.Item label="Ngành hàng" name="category_obj">
              <Select showSearch filterOption={filterOption}>
                {categories.map((item, idx) => {
                  return (
                    <Select.Option value={item.id} key={idx}>
                      {item.vn}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          )}

          <Form.Item
            label="Giá"
            name="price"
            rules={[
              {
                required: true,
                message: 'Nhập giá'
              },
              ...RULES.find(item => item.dataIndex === 'price').rules
            ]}
          >
            <InputNumber />
          </Form.Item>

          <Form.Item
            label="Giá giảm"
            name="salePrice"
            rules={RULES.find(item => item.dataIndex === 'salePrice').rules}
          >
            <InputNumber disabled />
          </Form.Item>

          <Form.Item label="Mã giảm giá" name="note">
            <Input disabled />
          </Form.Item>

          <Form.Item label="Hình thức" name="salePriceType">
            <Select disabled>
              {SALE_PRICE_TYPE.slice(0, 2).map((item, idx) => {
                return (
                  <Select.Option value={item.value} key={idx}>
                    {item.name}
                  </Select.Option>
                );
              })}
              <Select.Option value={salePriceTypeOfItem.value}>
                {salePriceTypeOfItem.name}
              </Select.Option>
            </Select>
          </Form.Item>

          <Form.Item label="Ghi chú" name="notice">
            <Input />
          </Form.Item>

          <Form.Item
            wrapperCol={{
              offset: 8,
              span: 16
            }}
          >
            <Space>
              <Button type="default" onClick={handleHideModelEdit}>
                Cancel
              </Button>
              <Button type="primary" htmlType="submit">
                OK
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
}
