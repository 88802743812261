import { shallowEqual, useSelector } from 'react-redux';

export const useSaleCreateOrderForCustomer = () => {
  const carts = useSelector(state => state?.transaction?.cart || [], shallowEqual);
  const isWholeSale = useSelector(
    state => state?.customer?.resPartner?.is_wholesale || false,
    shallowEqual
  );
  const userSelected = useSelector(
    state => state?.customer?.userSelected || {},
    shallowEqual
  );
  const priceTotal = carts.reduce((r, q) => r + q?.price_after_sale_VND || 0, 0);
  const categories = useSelector(state => state?.transaction?.categories || []);
  return {
    carts,
    priceTotal,
    userSelected,
    isWholeSale,
    categories
  };
};
