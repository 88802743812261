import { get } from 'lodash';
import TYPE from '../actions/TYPE';

const initialState = {
  list: []
};

const shippingAddress = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case TYPE.CREATE_SHIPPING_ADDRESS_SUCCESS: {
      return {
        ...state,
        list: [...state.list, get(payload, 'data', {})]
      };
    }
    case TYPE.GET_SHIPPING_ADDRESS_SUCCESS:
      return {
        ...state,
        list: get(payload, 'data', [])
      };

    case TYPE.UPDATE_SHIPPING_ADDRESS_SUCCESS: {
      const address = get(payload, 'data', {});
      return {
        ...state,
        list: state.list.map(item => {
          if (item._id === address._id) return address;
          return item;
        })
      };
    }

    case TYPE.DELETE_SHIPPING_ADDRESS_SUCCESS: {
      const id = get(payload, 'data', {})._id;
      return {
        ...state,
        list: state.list.filter(item => item._id !== id)
      };
    }
    default:
      return state;
  }
};

export default shippingAddress;
