import Tabs from 'antd/lib/tabs';
import Title from 'antd/lib/typography/Title';
import React, { useEffect, useState } from 'react';
import Website from '../../components/Website';
import actions from '../../redux/actions';
import dispatch from '../../utils/dispatch';
import './styles.less';

const TABS = [
  { key: 'website', tab: 'Website mua hàng', Component: Website }
  // {
  //   key: 'priority-categories',
  //   tab: 'Categories',
  //   Component: Categories
  // },
  // {
  //   key: 'weight',
  //   tab: 'Weight',
  //   Component: Weight
  // },
  // { key: 'payment_content', tab: 'Payment Content', Component: PaymentContent }
];

const Utility = () => {
  const [tab, setTab] = useState(TABS[0].key);

  useEffect(() => {
    dispatch(actions.getConfiguration());
    return () => dispatch(actions.clearConfiguration());
  }, []);

  return (
    <div className="container">
      <Title className="title" level={5}>
        Tiện ích
      </Title>
      <Tabs onChange={setTab}>
        <>
          {TABS.map(tabItem => {
            return (
              <Tabs.TabPane
                active={tabItem.key === tab}
                tab={tabItem.tab}
                key={tabItem.key}
              >
                <tabItem.Component />
              </Tabs.TabPane>
            );
          })}
        </>
      </Tabs>
    </div>
  );
};

export default Utility;
