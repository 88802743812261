import React, { useEffect, useState } from 'react';
import Row from 'antd/lib/row';
import Card from 'antd/lib/card';
import Checkbox from 'antd/lib/checkbox';
import ButtonGroup from 'antd/lib/button/button-group';
import Button from 'antd/lib/button';
import '../theme/style.less';
import Spin from 'antd/lib/spin';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import actions from '../../redux/actions';
import { notificationSuccess } from '../../utils/Notification';
import { MESSAGE_CONTENT } from '../../contents/Constants';

const optionWholesale = [
  {
    label: 'Đúng thông tin chuyển khoản',
    value: 'valid_transfer_info'
  },
  {
    label: 'Tổng tiền chuyển khoản >= tổng tiền đơn hàng',
    value: 'valid_transfer_amount_more_than_or_equal_to_order_amount'
  },
  {
    label: 'Tổng số dư ví >= tổng tiền đơn hàng',
    value: 'valid_balance_amount_more_than_or_equal_to_order_amount'
  },
  {
    label: 'Đơn hàng tạo bởi sale tự động xác nhận sau khi khách xác nhận mua',
    value: 'valid_sale_order_created_by_sale_has_not_been_verify'
  }
];

const optionNotWholesale = [
  {
    label: 'Đúng thông tin chuyển khoản',
    value: 'valid_transfer_info'
  },
  {
    label: 'Tổng tiền chuyển khoản >= tổng tiền đơn hàng',
    value: 'valid_transfer_amount_more_than_or_equal_to_order_amount'
  },
  {
    label: 'Tổng số dư ví >= tổng tiền đơn hàng',
    value: 'valid_balance_amount_more_than_or_equal_to_order_amount'
  },
  {
    label: 'Đơn hàng cần được kiểm tra bởi Customer Service',
    value: 'valid_sale_order_has_not_been_checked_by_sale'
  }
];
function RuleAutoConfirm() {
  const dispatch = useDispatch();

  const actionsConfig = useSelector(state => {
    return get(state, 'configuration.actions', {});
  }, shallowEqual);
  const actionRule = actionsConfig?.rule_auto_confirm_sale_order;

  const [wholesaleValue, setWholesaleValue] = useState([]);
  const [notWholesaleValue, setNotWholesaleValue] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setWholesaleValue(
      optionWholesale.reduce((r, q) => {
        if (actionRule?.wholesale[q.value]) {
          r.push(q.value);
        }
        return r;
      }, [])
    );
    setNotWholesaleValue(
      optionNotWholesale.reduce((r, q) => {
        if (actionRule?.not_wholesale[q.value]) {
          r.push(q.value);
        }
        return r;
      }, [])
    );
  }, [actionRule]);

  const onSave = () => {
    const wholesale = optionWholesale.reduce((r, q) => {
      r[q.value] = wholesaleValue.filter(i => i === q.value)?.length > 0;
      return r;
    }, {});
    const not_wholesale = optionNotWholesale.reduce((r, q) => {
      r[q.value] = notWholesaleValue.filter(i => i === q.value)?.length > 0;
      return r;
    }, {});
    setLoading(true);
    dispatch(
      actions.updateConfiguration({
        data: {
          actions: {
            ...actionsConfig,
            rule_auto_confirm_sale_order: { wholesale, not_wholesale }
          }
        },
        callback: ({ error }) => {
          setTimeout(() => {
            setLoading(false);
            if (!error) {
              notificationSuccess(MESSAGE_CONTENT.updateSuccess);
            }
          }, 1 * 1000);
        }
      })
    );
  };

  const onReset = () => {
    setWholesaleValue(
      optionWholesale.reduce((r, q) => {
        if (actionRule?.wholesale[q.value]) {
          r.push(q.value);
        }
        return r;
      }, [])
    );
    setNotWholesaleValue(
      optionNotWholesale.reduce((r, q) => {
        if (actionRule?.not_wholesale[q.value]) {
          r.push(q.value);
        }
        return r;
      }, [])
    );
  };

  return (
    <Spin spinning={loading}>
      <div className="fragment-theme">
        <ButtonGroup className="btn-action">
          <Button className="save" onClick={onSave}>
            Lưu lại
          </Button>
          <Button type="default" onClick={onReset}>
            Reset
          </Button>
        </ButtonGroup>
        <Row>
          <Card title="Khách sĩ" className="card">
            <Checkbox.Group
              options={optionWholesale}
              value={wholesaleValue}
              onChange={value => setWholesaleValue(value)}
            />
          </Card>
          <Card title="Khách lẻ" className="card">
            <Checkbox.Group
              options={optionNotWholesale}
              value={notWholesaleValue}
              onChange={value => setNotWholesaleValue(value)}
            />
          </Card>
        </Row>
      </div>
    </Spin>
  );
}

export default RuleAutoConfirm;
