import { DeleteFilled } from '@ant-design/icons';
import { Button, Col, Collapse, Form, Input, Row, Space, Typography } from 'antd';
import isEmpty from 'lodash/isEmpty';
import { bool, func, number, string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import useUploadFile from '../../hooks/useUploadFile';
import actions from '../../redux/actions';
import dispatch from '../../utils/dispatch';
import { notificationError } from '../../utils/Notification';
import CustomizeCollapse from '../CustomizeCollapse';
import ArrowDownIcon from '../icons/ArrowDownIcon';
import ArrowUpIcon from '../icons/ArrowUpIcon';
import UploadImage from '../UploadImage';
import './styles.less';

export default function TopBrandItem({
  logo,
  domain,
  description,
  url,
  index,
  setLoading,
  isNew,
  setShowAddNew,
  ...props
}) {
  const [file, handleUploadFile, handleReset] = useUploadFile(logo);
  const [expand, setExpand] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({ domain, description, url });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [domain, description, url]);

  const handleFinish = () => {
    setLoading(false);
    setShowAddNew(false);
  };

  const handleSaveBanner = values => {
    if (isEmpty(file.link)) {
      notificationError('Vui lòng nhập đầy đủ thông tin');
      return;
    }

    setLoading(true);

    if (isNew) {
      const newItem = { ...values, logo: file.link };
      dispatch(actions.createTopBrand({ data: newItem, callback: handleFinish }));
      return;
    }

    const updateItem = { ...values, logo: file.link };
    dispatch(
      actions.updateTopBrand({
        topBrandId: props?._id,
        data: updateItem,
        callback: handleFinish
      })
    );
  };

  const handleDelete = e => {
    setLoading(true);
    dispatch(
      actions.deleteTopBrand({
        topBrandId: props._id,
        callback: handleFinish
      })
    );
    e.stopPropagation();
  };

  const header = (
    <Row justify="space-between" align="middle">
      <Space align="center" size="middle">
        {expand ? <ArrowUpIcon /> : <ArrowDownIcon />}
        <Typography.Text strong>{isNew ? 'New Brand' : `${domain}`}</Typography.Text>
      </Space>
      {!isNew && (
        <Col>
          <DeleteFilled onClick={handleDelete} />
        </Col>
      )}
    </Row>
  );

  const handleExpand = () => setExpand(prevState => !prevState);

  return (
    <CustomizeCollapse onChange={handleExpand}>
      <Collapse.Panel showArrow={false} className="banner-header" header={header}>
        <Row gutter={16}>
          <Col className="gutter-row" span={12}>
            <UploadImage
              isPreventCrop
              imgUrl={file.link}
              loading={file.isFetching}
              beforeUpload={handleUploadFile}
              handleReset={handleReset}
            />
          </Col>

          <Col className="gutter-row" span={12}>
            <Form form={form} onFinish={handleSaveBanner}>
              <Form.Item
                name="domain"
                initialValue={domain}
                rules={[{ required: true, message: 'Vui lòng nhập brand' }]}
              >
                <Input placeholder="Brand name" />
              </Form.Item>

              <Form.Item
                name="url"
                initialValue={url}
                rules={[{ required: true, message: 'Vui lòng nhập url' }]}
              >
                <Input placeholder="url" />
              </Form.Item>

              <Form.Item
                name="description"
                initialValue={description}
                rules={[{ required: true, message: 'Vui lòng nhập tag' }]}
              >
                <Input placeholder="Tag" />
              </Form.Item>

              <Row justify="end">
                <Button htmlType="submit" className="banner-button" type="primary">
                  Lưu
                </Button>
              </Row>
            </Form>
          </Col>
        </Row>
      </Collapse.Panel>
    </CustomizeCollapse>
  );
}

TopBrandItem.propTypes = {
  _id: string,
  logo: string,
  domain: string,
  description: string,
  url: string,
  index: number,
  setLoading: func,
  isNew: bool,
  setShowAddNew: func
};

TopBrandItem.defaultProps = {
  _id: '',
  logo: '',
  domain: '',
  description: '',
  url: '',
  index: 0,
  setLoading: () => {},
  isNew: false,
  setShowAddNew: () => {}
};
