import { all, takeLatest, put, call } from 'redux-saga/effects';
import TYPE from '../actions/TYPE';
import services from '../../services';

function* getListTopBrand({ payload }) {
  try {
    const { data } = yield call(services.getListTopBrand, payload);
    yield put({ type: TYPE.GET_LIST_TOP_BRAND_SUCCESS, data });
  } catch (error) {
    yield put({ type: TYPE.GET_LIST_TOP_BRAND_FAILED, error });
  }
}

function* createTopBrand({ payload }) {
  const { data: requestBody } = payload;
  try {
    const { data } = yield call(services.createTopBrand, requestBody);
    yield put({ type: TYPE.CREATE_TOP_BRAND_SUCCESS, data });
  } catch (error) {
    yield put({ type: TYPE.CREATE_TOP_BRAND_FAILED, error });
  } finally {
    payload?.callback?.();
  }
}

function* updateTopBrand({ payload }) {
  const { data: requestBody, topBrandId } = payload;
  try {
    const { data } = yield call(services.updateTopBrand, topBrandId, requestBody);
    yield put({ type: TYPE.UPDATE_TOP_BRAND_SUCCESS, data });
  } catch (error) {
    yield put({ type: TYPE.UPDATE_TOP_BRAND_FAILED, error });
  } finally {
    payload?.callback?.();
  }
}

function* updateTopBrandPriority({ payload }) {
  const { data: requestBody } = payload;
  try {
    yield call(services.updateTopBrandPriority, requestBody);
    yield put({ type: TYPE.UPDATE_TOP_BRAND_PRIORITY_SUCCESS });
  } catch (error) {
    yield put({ type: TYPE.UPDATE_TOP_BRAND_PRIORITY_FAILED, error });
  } finally {
    payload?.callback?.();
  }
}

function* deleteTopBrand({ payload }) {
  const { topBrandId } = payload;
  try {
    yield call(services.deleteTopBrand, topBrandId);
    yield put({ type: TYPE.DELETE_TOP_BRAND_SUCCESS, data: topBrandId });
  } catch (error) {
    yield put({ type: TYPE.DELETE_TOP_BRAND_FAILED, error });
  } finally {
    payload?.callback?.();
  }
}

function* getMobileSetting() {
  try {
    const { data } = yield call(services.getMobileSetting);
    yield put({ type: TYPE.GET_MOBILE_SETTING_SUCCESS, data });
  } catch (error) {
    yield put({ type: TYPE.GET_MOBILE_SETTING_FAILED, error });
  }
}

function* updateMobileSetting({ payload }) {
  const { data: requestData } = payload;
  try {
    const { data } = yield call(services.updateMobileSetting, requestData);
    yield put({ type: TYPE.UPDATE_MOBILE_SETTING_SUCCESS, data });
  } catch (error) {
    yield put({ type: TYPE.UPDATE_MOBILE_SETTING_FAILED, error });
  } finally {
    payload?.callback?.();
  }
}

export default function* root() {
  yield all([
    takeLatest(TYPE.GET_LIST_TOP_BRAND, getListTopBrand),
    takeLatest(TYPE.CREATE_TOP_BRAND, createTopBrand),
    takeLatest(TYPE.UPDATE_TOP_BRAND, updateTopBrand),
    takeLatest(TYPE.UPDATE_TOP_BRAND_PRIORITY, updateTopBrandPriority),
    takeLatest(TYPE.DELETE_TOP_BRAND, deleteTopBrand),
    takeLatest(TYPE.GET_MOBILE_SETTING, getMobileSetting),
    takeLatest(TYPE.UPDATE_MOBILE_SETTING, updateMobileSetting)
  ]);
}
