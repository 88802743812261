import TYPE from '../actions/TYPE';

const initialState = {
  topBrand: [],
  settings: {}
};

const mobile = (state = initialState, action = {}) => {
  switch (action.type) {
    case TYPE.DRAGGABLE_TOP_BRAND:
      return {
        ...state,
        topBrand: [...action.payload]
      };

    case TYPE.CREATE_TOP_BRAND_SUCCESS:
      return {
        ...state,
        topBrand: [action.data, ...state.topBrand]
      };

    case TYPE.UPDATE_TOP_BRAND_SUCCESS:
      return {
        ...state,
        topBrand: state.topBrand.map(item => {
          if (item._id !== action.data._id) return item;
          return action.data;
        })
      };

    case TYPE.DELETE_TOP_BRAND_SUCCESS:
      return {
        ...state,
        topBrand: state.topBrand.filter(item => item._id !== action.data)
      };

    case TYPE.GET_LIST_TOP_BRAND_SUCCESS:
      return {
        ...state,
        topBrand: action.data
      };

    case TYPE.GET_MOBILE_SETTING_SUCCESS:
    case TYPE.UPDATE_MOBILE_SETTING_SUCCESS:
      return {
        ...state,
        settings: action.data
      };

    case TYPE.DRAGGABLE_CAROUSEL:
      return {
        ...state,
        settings: {
          ...state.settings,
          carousel: [...action.payload]
        }
      };

    default:
      return state;
  }
};

export default mobile;
