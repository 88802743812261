import { combineReducers } from 'redux';
import account from './accountReducer';
import bank from './bankReducer';
import bankTransfer from './bankTransferReducer';
import campaign from './campaignReducer';
import dealCategory from './categoryDealReducer';
import category from './categoryReducer';
import common from './commonReducer';
import configuration from './configurationReducer';
import customer from './customerReducer';
import dealWithdraw from './dealWithdrawReducer';
import dealngon from './dealngonReducer';
import debtRecovery from './debtRecovery';
import exchangePointRequest from './exchangePointRequest';
import location from './locationReducer';
import metaDeal from './metaDealReducer';
import mobile from './mobileReducer';
import paymentContent from './paymentContentReducer';
import profile from './profileReducer';
import seller from './sellerReducer';
import shippingAddress from './shippingAddressReducer';
import shipping from './shippingReducer';
import topic from './topicReducer';
import transactionDeal from './transactionDealReducer';
import transaction from './transactionReducer';
import website from './websiteReducer';
import withdrawal from './withdrawalReducer';

export default combineReducers({
  account,
  bank,
  common,
  profile,
  customer,
  transaction,
  configuration,
  location,
  website,
  category,
  dealngon,
  dealWithdraw,
  shipping,
  paymentContent,
  withdrawal,
  exchangePointRequest,
  transactionDeal,
  metaDeal,
  seller,
  dealCategory,
  topic,
  mobile,
  campaign,
  bankTransfer,
  debtRecovery,
  shippingAddress
});
