import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Card, Space, Spin, Table, Typography } from 'antd';
import { get, isEmpty } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FlagsIcon from '../../components/common/FlagsIcon';
import { COUNTRY_NAME, SHIPPING_ADDRESS_FORMS } from '../../contents/Constants';
import actions from '../../redux/actions';
import ShippingAddressDeleteModal from './DeleteModal';
import ShippingAddressEditModal from './EditModal';
import './styles.less';

const columnsProp = {
  width: 200
};

function ShippingAddressList() {
  const dispatch = useDispatch();
  const [onEdit, setOnEdit] = useState(false);
  const [onDelete, setOnDelete] = useState(false);
  const loading = useSelector(state =>
    get(state, 'common.loadingImprove.getShippingAddress', false)
  );
  const list = useSelector(state => get(state, 'shippingAddress.list', []));
  const listEmpty = isEmpty(list);

  const addressSelected = useRef(null);

  useEffect(() => {
    dispatch(actions.getShippingAddress());
  }, [dispatch]);

  const handleUpdateAddressSelected = data => {
    addressSelected.current = data;
  };

  const handleShowEditModal = () => setOnEdit(true);

  const handleHideEditModal = () => setOnEdit(false);

  const handleShowDeleteModal = () => setOnDelete(true);
  const handleHideDeleteModal = () => setOnDelete(false);

  const columnBase = {
    type: {
      ...columnsProp,
      title: 'Country',
      dataIndex: 'type',
      key: 'type',
      render: type => (
        <Typography>
          <FlagsIcon type={type} /> {COUNTRY_NAME[type]}
        </Typography>
      )
    },
    actions: {
      ...columnsProp,
      title: 'Thao tác',
      dataIndex: '',
      key: 'actions',
      align: 'center',
      fixed: 'right',
      render: data => (
        <Space>
          <Button
            type="ghost"
            onClick={() => {
              handleUpdateAddressSelected(data);
              handleShowEditModal();
            }}
            icon={<EditOutlined />}
          >
            Sửa
          </Button>

          <Button
            type="danger"
            onClick={() => {
              handleUpdateAddressSelected(data);
              handleShowDeleteModal();
            }}
            icon={<DeleteOutlined />}
          >
            Xoá
          </Button>
        </Space>
      )
    }
  };

  return (
    <Spin spinning={loading}>
      <Card className="card" title="Danh sách">
        {listEmpty && <Table />}
        <div className="card-content">
          {SHIPPING_ADDRESS_FORMS.map(({ types, options }, index) => {
            const dataSource = list.reduce((result, item) => {
              if (types.includes(item.type)) {
                const newItem = { ...item, key: item._id };
                result = [...result, newItem];
              }
              return result;
            }, []);

            const columns = options.reduce((result, { label, key: _key }) => {
              const column = {
                ...columnsProp,
                title: label,
                dataIndex: 'info',
                key: _key,
                render: data => <Typography>{data?.[_key]?.value}</Typography>
              };
              return [...result, column];
            }, []);

            if (isEmpty(dataSource)) return null;

            return (
              <Table
                key={index}
                bordered
                size="small"
                dataSource={dataSource}
                scroll={{ x: '100%' }}
                columns={[columnBase.type, ...columns, columnBase.actions]}
                pagination={{
                  size: 'small'
                }}
              />
            );
          })}
        </div>
      </Card>
      <ShippingAddressEditModal
        data={addressSelected.current}
        open={onEdit}
        onCancel={handleHideEditModal}
      />
      <ShippingAddressDeleteModal
        data={addressSelected.current}
        open={onDelete}
        onCancel={handleHideDeleteModal}
      />
    </Spin>
  );
}

export default ShippingAddressList;
