import { all, takeLatest, put, call } from 'redux-saga/effects';
import TYPE from '../actions/TYPE';
import services from '../../services';
import { ACTION_STATUS } from '../../contents/Constants';
import { MessageContent } from '../../contents/Labels';
import { notificationSuccess, notificationError } from '../../utils/Notification';
import { getListDepositForCustomer } from './commonSaga';

function* changeStatusActivityCustomer(action) {
  try {
    const {
      payload: { id, status }
    } = action;
    const data = yield call(services.changeStatusActivityCustomer, id, status);
    yield put({
      type: TYPE.CHANGE_STATUS_ACTIVITY_CUSTOMER_SUCCESS,
      message: ACTION_STATUS.SUCCESS,
      data
    });
    notificationSuccess(MessageContent.ChangeStatusSuccess);
  } catch (err) {
    yield put({
      type: TYPE.CHANGE_STATUS_ACTIVITY_CUSTOMER_FAILED,
      message: ACTION_STATUS.FAILED
    });
    notificationError(MessageContent.ChangeStatusFailed);
  }
}

function* searchCustomer(action) {
  try {
    const {
      payload: { isEnableDeposit, ...payload }
    } = action;
    const data = yield call(services.searchCustomer, payload);
    yield put({
      type: TYPE.SEARCH_CUSTOMER_SUCCESS,
      message: ACTION_STATUS.SUCCESS,
      data
    });

    if (isEnableDeposit) {
      yield getListDepositForCustomer();
    }
  } catch (err) {
    yield put({
      type: TYPE.SEARCH_CUSTOMER_FAILED,
      message: ACTION_STATUS.FAILED
    });
  }
}

function* updateCustomer({ payload }) {
  const { data, callback } = payload;
  try {
    const { success, data: result } = yield call(services.updateCustomer, data);
    callback({ error: !success, data: result });
  } catch (err) {
    yield put({
      type: TYPE.UPDATE_CUSTOMER_FAILED,
      message: ACTION_STATUS.FAILED
    });
  }
}

function* getAllCustomers() {
  try {
    const { data } = yield call(services.getAllCustomers);
    yield put({ type: TYPE.GET_ALL_CUSTOMERS_SUCCESS, data });
  } catch (error) {
    yield put({ type: TYPE.GET_ALL_CUSTOMERS_FAILED, message: error });
  }
}

function* getDeliveryAddress(action) {
  try {
    yield put({ type: TYPE.ENABLE_LOADING_IMPROVE, target: 'getDeliveryAddress' });
    const { userId } = action;
    const data = yield call(services.getDeliveryAddress, userId);
    yield put({
      type: TYPE.GET_DELIVERY_ADDRESS_SUCCESS,
      data
    });
  } catch (error) {
    yield put({
      type: TYPE.GET_DELIVERY_ADDRESS_FAILED,
      message: error
    });
  } finally {
    yield put({ type: TYPE.DISABLE_LOADING_IMPROVE, target: 'getDeliveryAddress' });
  }
}

function* getUserProfile({ payload }) {
  try {
    const { data, success, message } = yield call(
      services.marketplace.customer.getUserProfile,
      payload
    );
    if (!success) {
      notificationError(message);
    } else
      yield put({
        type: TYPE.GET_USER_PROFILE_SUCCESS,
        data: data?.resPartner
      });
  } catch (error) {
    yield put({
      type: TYPE.GET_USER_PROFILE_FAILED,
      message: error
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(TYPE.CHANGE_STATUS_ACTIVITY_CUSTOMER, changeStatusActivityCustomer),
    takeLatest(TYPE.SEARCH_CUSTOMER, searchCustomer),
    takeLatest(TYPE.UPDATE_CUSTOMER, updateCustomer),
    takeLatest(TYPE.GET_ALL_CUSTOMERS, getAllCustomers),
    takeLatest(TYPE.GET_DELIVERY_ADDRESS, getDeliveryAddress),
    takeLatest(TYPE.GET_USER_PROFILE, getUserProfile)
  ]);
}
