import { Button, Skeleton, Space, Tooltip, Typography } from 'antd';
import { find, get, isNumber, size } from 'lodash';
import moment from 'moment';
import { any } from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import FlagsIcon from '../../../components/common/FlagsIcon';
import {
  LOCATION_TYPES,
  LOCATION_TYPE_DEFAULT,
  TRANSACTION_STATUS
} from '../../../contents/Constants';
import { MessageContent } from '../../../contents/Labels';
import actions from '../../../redux/actions';
import { convertDollar } from '../../../utils/convert';
import currency from '../../../utils/currency';
import dispatch from '../../../utils/dispatch';
import { exceptNumber } from '../../../utils/helpers';

const { Text } = Typography;

function ReorderButton({ order_code, user_id }) {
  const loading = useSelector(state => !!state.common.loadingImprove?.[order_code]);

  const handleReorderSaleOrder = () => {
    const payload = {
      user: user_id[0]._id,
      order_code
    };
    dispatch(actions.reorderSaleOrder(payload), false);
  };

  return (
    <Button loading={loading} type="primary" onClick={handleReorderSaleOrder}>
      Đặt lại
    </Button>
  );
}

ReorderButton.propTypes = {
  user_id: any.isRequired,
  _id: any.isRequired,
  order_code: any.isRequired
};

const locationColumn = {
  title: 'Thuộc tuyến',
  dataIndex: 'location',
  key: 'location',
  render: dataColumns => {
    const location =
      find(LOCATION_TYPES, item => item.value === dataColumns) ||
      LOCATION_TYPE_DEFAULT;
    return (
      <Text className="location">
        <FlagsIcon type={location.value} />
        &nbsp;{location.name}
      </Text>
    );
  }
};

export default {
  customer_confirmed: [
    {
      title: 'Ngày giao dịch',
      key: 'createdAt',
      dataIndex: 'created_at',
      align: 'center',
      width: '20%',
      fixed: true,
      render: (text, row) => {
        return (
          <Text>
            {moment(row?.confirmed_time || text).format('HH:mm:ss, DD/MM/YYYY')}
          </Text>
        );
      }
    },
    {
      title: 'confirmed_time',
      dataIndex: 'confirmed_time',
      className: 'hide'
    },
    {
      title: 'Nội dung',
      dataIndex: 'payment_code',
      key: 'paymentCode'
    },
    {
      title: 'Tên khách hàng',
      dataIndex: 'user_id',
      render: text => <Tooltip title={text?.[0]?.email}>{text?.[0]?.name}</Tooltip>
    },
    { title: 'Mã đơn hàng', dataIndex: 'order_code', key: 'order_code' },
    {
      title: 'Sale tạo thay',
      dataIndex: 'created_by_sale',
      key: 'created_by_sale',
      render: data => {
        return get(data, '[0].name', '-');
      }
    },
    {
      title: 'Khoản dư',
      dataIndex: 'deposit',
      key: 'balance',
      align: 'right',
      render: value => {
        if (!isNumber(value)) {
          return <Skeleton title={false} paragraph={{ rows: 1, width: '100%' }} />;
        }
        return <p>{`${currency.format(value)} đ`}</p>;
      }
    },
    {
      title: 'Số tiền đề xuất',
      dataIndex: 'total_price_after_sale_VND',
      key: 'totalPriceAfterSale',
      align: 'right',
      render: text => {
        return <Text className="total">{`${currency.format(text)} đ`}</Text>;
      }
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      render: status => {
        return (
          <Text>
            {status === TRANSACTION_STATUS.customerConfirmed.key
              ? 'Khách đã xác nhận'
              : 'Khách chưa xác nhận'}
          </Text>
        );
      }
    },
    locationColumn,
    {
      title: 'Thao tác',
      dataIndex: 'action',
      key: 'action',
      align: 'left',
      render: (_, record) => {
        return (
          <div>
            {record.status === TRANSACTION_STATUS.customerConfirmed.key && (
              <>
                <Button
                  type="primary"
                  onClick={() => {
                    return dispatch(actions.getTransactionInfo(record), false);
                  }}
                >
                  {MessageContent.Confirm}
                </Button>
                <Button
                  type="dashed"
                  style={{ marginLeft: 10 }}
                  onClick={() => {
                    return dispatch(actions.showTransactionDetail(record), false);
                  }}
                >
                  {MessageContent.Detail}
                </Button>
              </>
            )}

            {record.status === 'waiting' && (
              <>
                <Button
                  type="primary"
                  onClick={() => {
                    return dispatch(
                      actions.updateWaitingSaleOrder({
                        id: record._id,
                        type: 'approve'
                      }),
                      false
                    );
                  }}
                >
                  {MessageContent.ConfirmForCustomer}
                </Button>
                <Button
                  type="primary"
                  danger
                  onClick={() => {
                    return dispatch(
                      actions.openCancelOrderModel({
                        status: true,
                        orderId: record._id
                      }),
                      false
                    );
                    // return dispatch(
                    //   actions.updateWaitingSaleOrder({
                    //     id: record._id,
                    //     type: 'reject'
                    //   }),
                    //   false
                    // );
                  }}
                >
                  {MessageContent.RejectForCustomer}
                </Button>
              </>
            )}
          </div>
        );
      }
    }
  ],
  buyngon_confirmed: [
    {
      title: 'Ngày giao dịch',
      key: 'createdAt',
      dataIndex: 'created_at',
      align: 'center',
      width: '20%',
      fixed: true,
      render: (text, row) => {
        return (
          <Text>
            {moment(row?.confirmed_time || text).format('HH:mm:ss, DD/MM/YYYY')}
          </Text>
        );
      }
    },
    {
      title: 'Ngày xác nhận',
      key: 'approved_time',
      dataIndex: 'approved_time',
      align: 'center',
      width: '20%',
      fixed: true,
      render: text => {
        return (
          <Text>{text ? moment(text).format('HH:mm:ss, DD/MM/YYYY') : '-'}</Text>
        );
      }
    },
    {
      title: 'confirmed_time',
      dataIndex: 'confirmed_time',
      className: 'hide'
    },
    {
      title: 'Nội dung',
      dataIndex: 'payment_code',
      key: 'paymentCode'
    },
    {
      title: 'Tên khách hàng',
      dataIndex: 'user_id',
      render: text => <Tooltip title={text?.[0]?.email}>{text?.[0]?.name}</Tooltip>
    },

    { title: 'Mã đơn hàng', dataIndex: 'order_code', key: 'order_code' },
    {
      title: 'Loại đơn hàng',
      dataIndex: 'type',
      key: 'type',
      render: (_, record) => (
        <Text type={record?.type === 'deal' ? 'success' : 'default'}>
          {`${record?.type === 'deal' ? 'Deal' : 'Sale'}`} Order
        </Text>
      )
    },
    {
      title: 'Số tiền đề xuất',
      dataIndex: 'total_price_after_sale_VND',
      key: 'totalPriceAfterSale',
      align: 'right',
      render: (text, record) => {
        if (record?.type === 'deal')
          return (
            <Text className="total">{convertDollar(record?.total_last_price)}</Text>
          );

        return <Text className="total">{`${currency.format(text)} đ`}</Text>;
      }
    },
    {
      title: 'Số tiền thực nhận',
      dataIndex: 'payment_info',
      key: 'totalPriceTranfer',
      align: 'right',
      render: data => {
        return (
          <Text className="total">
            {data?.price_tranfer
              ? `${currency.format(exceptNumber(data.price_tranfer))} đ`
              : '-'}
          </Text>
        );
      }
    },
    // {
    //   title: 'Khuyến Mãi',
    //   dataIndex: 'coupons',
    //   key: 'coupons',
    //   align: 'right',
    //   render: value => {
    //     return (
    //       <Tooltip
    //         color="green"
    //         title={!isEmpty(value) ? `${currency.format(value[0].price)} đ` : ''}
    //       >
    //         {!isEmpty(value) ? value[0].name : '-'}
    //       </Tooltip>
    //     );
    //   }
    // },
    {
      title: 'Người xác nhận',
      dataIndex: 'approved_by',
      key: 'approved_by',
      render: data => {
        return get(data, '[0].name', '');
      }
    },
    locationColumn,
    {
      title: 'Thao tác',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      render: (_, record) => {
        return (
          <Space style={{ width: '100%' }}>
            <Button
              type="dashed"
              style={{ marginLeft: 10 }}
              onClick={() => {
                return dispatch(actions.showTransactionDetail(record), false);
              }}
            >
              {MessageContent.Detail}
            </Button>
            {record?.type !== 'deal' && <ReorderButton {...record} />}
          </Space>
        );
      }
    }
  ],
  all_rejected: [
    {
      title: 'Ngày giao dịch',
      key: 'createdAt',
      dataIndex: 'created_at',
      align: 'center',
      width: '20%',
      fixed: true,
      render: (text, row) => {
        return (
          <Text>
            {moment(row?.confirmed_time || text).format('HH:mm:ss, DD/MM/YYYY')}
          </Text>
        );
      }
    },
    {
      title: 'Ngày xác nhận',
      key: 'rejected_time',
      dataIndex: 'rejected_time',
      align: 'center',
      width: '20%',
      fixed: true,
      render: text => {
        return (
          <Text>{text ? moment(text).format('HH:mm:ss, DD/MM/YYYY') : '-'}</Text>
        );
      }
    },
    {
      title: 'confirmed_time',
      dataIndex: 'confirmed_time',
      className: 'hide'
    },
    {
      title: 'Nội dung',
      dataIndex: 'payment_code',
      key: 'paymentCode'
    },
    {
      title: 'Tên khách hàng',
      dataIndex: 'user_id',
      render: text => <Tooltip title={text?.[0]?.email}>{text?.[0]?.name}</Tooltip>
    },
    { title: 'Mã đơn hàng', dataIndex: 'order_code', key: 'order_code' },
    {
      title: 'Số tiền đề xuất',
      dataIndex: 'total_price_after_sale_VND',
      key: 'totalPriceAfterSale',
      align: 'right',
      render: text => {
        return <Text className="total">{`${currency.format(text)} đ`}</Text>;
      }
    },
    {
      title: 'Người xác nhận',
      dataIndex: 'rejected_by',
      key: 'rejected_by',
      render: data => {
        return get(data, '[0].name', '');
      }
    },
    {
      title: 'Khách huỷ đơn',
      dataIndex: 'user_id',
      key: 'user_id',
      render: (data, row) => {
        return size(row?.rejected_by) > 0 ? '-' : get(data, '[0].name', '');
      }
    },
    locationColumn,
    {
      title: 'Thao tác',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      render: (_, record) => {
        return (
          <Space>
            <Button
              type="dashed"
              onClick={() => {
                return dispatch(actions.showTransactionDetail(record), false);
              }}
            >
              {MessageContent.Detail}
            </Button>
            <ReorderButton {...record} />
          </Space>
        );
      }
    }
  ]
};
