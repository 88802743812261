import { Button, Card, Spin } from 'antd';
import { get } from 'lodash';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import useUploadFile from '../../hooks/useUploadFile';
import actions from '../../redux/actions';
import dispatch from '../../utils/dispatch';
import UploadImage from '../UploadImage';

export default function FlashSale() {
  const [loading, setLoading] = useState(false);
  const images = useSelector(state => {
    return get(state, 'configuration.images');
  });
  const welcomeBanner = useSelector(state => {
    return get(state, 'configuration.images.backgroundWelcome');
  });

  const [file, handleUploadFile, handleReset] = useUploadFile(welcomeBanner?.url);
  const handleSave = () => {
    setLoading(true);
    dispatch(
      actions.updateConfiguration({
        data: {
          images: {
            ...images,
            backgroundWelcome: {
              type: file?.type || welcomeBanner?.type,
              url: file?.link
            }
          }
        },
        callback: () => {
          setTimeout(() => {
            setLoading(false);
          }, 500);
        }
      })
    );
  };

  // useEffect(() => {
  //   if (file.isFetching === false && file.link && file.type) {
  //     handleSave(file.type, file.link);
  //   }
  // }, [file.isFetching, file.link, file.type]);

  return (
    <Spin spinning={loading}>
      <div className="fragment-theme">
        <div className="btn-action">
          <div />
          <Button type="primary" onClick={handleSave}>
            <b>Lưu</b>
          </Button>
        </div>

        <div className="dealngon-banner">
          <Card
            title="Banner Welcome"
            className="card"
            style={{
              width: '100%'
            }}
          >
            <div>
              <UploadImage
                isPreventCrop
                imgUrl={file.link}
                loading={file.isFetching}
                beforeUpload={handleUploadFile}
                handleReset={handleReset}
              />
            </div>
          </Card>
        </div>
      </div>
    </Spin>
  );
}
