import { DeleteFilled } from '@ant-design/icons';
import {
  Button,
  Col,
  Collapse,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Space,
  Typography
} from 'antd';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { bool, func, string } from 'prop-types';
import React, { useState } from 'react';
import useUploadFile from '../../hooks/useUploadFile';
import actions from '../../redux/actions';
import dispatch from '../../utils/dispatch';
import { notificationError } from '../../utils/Notification';
import CustomizeCollapse from '../CustomizeCollapse';
import ArrowDownIcon from '../icons/ArrowDownIcon';
import ArrowUpIcon from '../icons/ArrowUpIcon';
import UploadImage from '../UploadImage';

const { Panel } = Collapse;
const { useForm } = Form;
const { RangePicker } = DatePicker;
const timeFormat = 'DD-MM-YYYY HH:mm:ss';

export default function PromotionItem({
  program_name,
  website,
  discount_content,
  start_date,
  end_date,
  description,
  currency,
  logo,
  isNew,
  id,
  setShowAddPromotionUs
}) {
  const [expand, setExpand] = useState(false);
  const [file, handleUploadFile, handleReset] = useUploadFile(logo);
  const [rangeDate, setRangeDate] = useState({
    start_date,
    end_date
  });

  const [form] = useForm();

  const handleDelete = e => {
    // call API delete
    dispatch(actions.deletePromotionUs(id));
    e.stopPropagation();
  };

  const handleSave = values => {
    if (isEmpty(file.link)) {
      notificationError('Vui lòng nhập đầy đủ thông tin');
    } else if (isNew) {
      // call Create
      dispatch(
        actions.createPromotionUs({
          program_name: get(values, 'program_name', ''),
          website: get(values, 'website', ''),
          discount_content: get(values, 'discount_content', ''),
          description: get(values, 'description', ''),
          start_date: rangeDate.start_date,
          end_date: rangeDate.end_date,
          currency: values.currency,
          logo: file.link ? file.link : logo
        })
      );
      setShowAddPromotionUs(false);
    } else {
      dispatch(
        actions.updatePromotionUs({
          program_name: get(values, 'program_name', ''),
          website: get(values, 'website', ''),
          discount_content: get(values, 'discount_content', ''),
          description: get(values, 'description', ''),
          start_date: rangeDate.start_date,
          end_date: rangeDate.end_date,
          currency: values.currency,
          logo: file.link ? file.link : logo,
          _id: id
        })
      );
    }
  };

  const handleChoseDate = (_, dateString) => {
    setRangeDate({
      start_date: moment(dateString[0], timeFormat),
      end_date: moment(dateString[1], timeFormat)
    });
  };

  const header = (
    <Row justify="space-between" align="middle">
      <Space align="center" size="middle">
        {expand ? <ArrowUpIcon /> : <ArrowDownIcon />}
        {isNew ? (
          <Typography.Text strong>New Promotion on US website</Typography.Text>
        ) : (
          <Typography.Text>
            Tại <Typography.Text underline>{website}</Typography.Text>
          </Typography.Text>
        )}
      </Space>
      {!isNew && (
        <Col>
          <DeleteFilled onClick={handleDelete} />
        </Col>
      )}
    </Row>
  );

  return (
    <CustomizeCollapse onChange={() => setExpand(prevState => !prevState)}>
      <Panel header={header} showArrow={false}>
        <Form form={form} onFinish={handleSave}>
          <Row gutter={16}>
            <Col span={12}>
              <Typography.Text
                strong
                type="secondary"
                rules={[{ required: true, message: 'Vui lòng nhập domain' }]}
              >
                Link khuyến mãi
              </Typography.Text>
              <Form.Item name="website" initialValue={website}>
                <Input placeholder="Nhập domain" />
              </Form.Item>
              <Typography.Text
                rules={[{ required: true, message: 'Vui lòng nhập Title' }]}
                strong
                type="secondary"
              >
                Title
              </Typography.Text>
              <Form.Item name="program_name" initialValue={program_name}>
                <Input placeholder="Tiêu đề mã khuyến mãi" />
              </Form.Item>

              <Typography.Text
                rules={[
                  { required: true, message: 'Vui lòng nhập nội dung khuyến mãi' }
                ]}
                strong
                type="secondary"
              >
                Nội dung khuyến mãi
              </Typography.Text>
              <Form.Item name="discount_content" initialValue={discount_content}>
                <Input placeholder="Vd: Giảm 50%, Mua 3 tặng 1" />
              </Form.Item>

              <Typography.Text
                rules={[{ required: true, message: 'Vui lòng nhập Description' }]}
                strong
                type="secondary"
              >
                Description
              </Typography.Text>
              <Form.Item name="description" initialValue={description}>
                <Input placeholder="Description" />
              </Form.Item>
              <Form.Item label="Ngày bắt đầu" name="date">
                <RangePicker
                  {...(rangeDate.start_date &&
                    rangeDate.end_date && {
                      defaultValue: [
                        moment(rangeDate.start_date),
                        moment(rangeDate.end_date)
                      ]
                    })}
                  onChange={handleChoseDate}
                  showTime={{
                    format: 'HH:mm:ss',
                    defaultValue: [
                      moment('00:00:00', 'HH:mm:ss'),
                      moment('23:59:59', 'HH:mm:ss')
                    ]
                  }}
                  format={timeFormat}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Typography.Text
                strong
                type="secondary"
                rules={[{ required: true, message: 'Vui lòng upload image' }]}
              >
                Logo
              </Typography.Text>
              <UploadImage
                isPreventCrop
                imgUrl={file.link}
                beforeUpload={handleUploadFile}
                loading={file.isFetching}
                handleReset={handleReset}
                isNew={isNew}
              />

              <Typography.Text
                rules={[{ required: true, message: 'Vui lòng nhập Description' }]}
                strong
                type="secondary"
              >
                Tuyến
              </Typography.Text>
              <Form.Item
                name="currency"
                initialValue={currency}
                rules={[{ required: true, message: 'Vui lòng chọn tuyến' }]}
              >
                <Select placeholder="Chọn tuyến" allowClear>
                  <Select.Option value="USD">Mỹ </Select.Option>
                  <Select.Option value="EUR">Tây Ban Nha</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row justify="center" style={{ marginTop: 8 }}>
            <Button htmlType="submit" type="primary">
              Save
            </Button>
          </Row>
        </Form>
      </Panel>
    </CustomizeCollapse>
  );
}

PromotionItem.propTypes = {
  id: string,
  logo: string,
  currency: string,
  program_name: string,
  website: string,
  discount_content: string,
  isNew: bool,
  start_date: string,
  end_date: string,
  setShowAddPromotionUs: func,
  description: string
};

PromotionItem.defaultProps = {
  id: '',
  logo: '',
  program_name: '',
  currency: 'USD',
  website: '',
  discount_content: '',
  isNew: false,
  start_date: undefined,
  end_date: undefined,
  description: '',
  setShowAddPromotionUs: () => {}
};
