import { Button, List, Spin } from 'antd';
import get from 'lodash/get';
import React, { useState } from 'react';
import ReactDragListView from 'react-drag-listview';
import { useSelector } from 'react-redux';
import useDraggable from '../../hooks/useDraggable';
import actions from '../../redux/actions';
import dispatch from '../../utils/dispatch';
import CarouselItem from './CarouselItem';

export default function Carousel() {
  const [loading, setLoading] = useState(false);
  const [showAddNew, setShowAddNew] = useState(false);
  const listCarousel = useSelector(state => {
    return get(state, 'mobile.settings.carousel', []);
  });

  const { onDragEnd } = useDraggable(listCarousel);

  const handleDragEnd = (fromIndex, toIndex) => {
    const result = onDragEnd(fromIndex, toIndex);
    dispatch(actions.draggableCarousel(result));
  };

  const handleSavePriority = () => {
    setLoading(true);
    dispatch(
      actions.updateMobileSetting({
        data: { carousel: listCarousel },
        callback: () => {
          setLoading(false);
          setShowAddNew(false);
        }
      })
    );
  };

  return (
    <Spin spinning={loading}>
      <div className="fragment-theme">
        <div className="btn-action">
          <Button type="primary" onClick={handleSavePriority}>
            <b>Lưu thứ tự</b>
          </Button>
          <Button
            onClick={() => {
              return setShowAddNew(true);
            }}
            className="save"
          >
            <b>+ Thêm slide</b>
          </Button>
        </div>
        <div className="dealngon-banner">
          <ReactDragListView
            onDragEnd={handleDragEnd}
            handleSelector=".ant-collapse-header"
            nodeSelector=".banner-item"
          >
            {showAddNew && (
              <CarouselItem
                isNew
                setShowAddNew={setShowAddNew}
                setLoading={setLoading}
              />
            )}
            {(listCarousel.length > 0 || !showAddNew) && (
              <List
                dataSource={listCarousel}
                split={false}
                renderItem={(item, index) => {
                  return (
                    <List.Item className="banner-item">
                      <CarouselItem
                        {...item}
                        key={index}
                        setLoading={setLoading}
                        setShowAddNew={setShowAddNew}
                        index={index}
                      />
                    </List.Item>
                  );
                }}
              />
            )}
          </ReactDragListView>
        </div>
      </div>
    </Spin>
  );
}
