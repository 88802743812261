import { Modal, Spin } from 'antd';
import { get } from 'lodash';
import { bool, func, object } from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import actions from '../../redux/actions';
import ShippingAddressForm from './component/Form';
import './styles.less';

function ShippingAddressEditModal({ data, open, onCancel }) {
  const dispatch = useDispatch();
  const loading = useSelector(state =>
    get(state, 'common.loadingImprove.updateShippingAddress', false)
  );

  const handleUpdate = ({ value }) => {
    const payload = {
      value,
      callback: onCancel
    };
    dispatch(actions.updateShippingAddress(payload));
  };

  return (
    <Modal title="Chỉnh sửa địa chỉ" open={open} onCancel={onCancel} footer={null}>
      <Spin spinning={loading}>
        <ShippingAddressForm loading={loading} data={data} onSubmit={handleUpdate} />
      </Spin>
    </Modal>
  );
}

export default ShippingAddressEditModal;

ShippingAddressEditModal.propTypes = {
  data: object,
  open: bool,
  onCancel: func
};

ShippingAddressEditModal.defaultProps = {
  data: {},
  open: false,
  onCancel: () => {}
};
