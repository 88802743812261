import { Button, Col, Form, Row, Select, Spin, Typography } from 'antd';
import get from 'lodash/get';
import { objectOf } from 'prop-types';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CS_VERIFY_SO } from '../../../../contents/Constants';
import actions from '../../../../redux/actions';

const { Text } = Typography;
// const { TextArea } = Input;

export default function Action({ saleOrder }) {
  const [note, setNote] = useState('');
  const [showReason, setShowReason] = useState(false);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const loading = useSelector(state => state.transaction.csVerifyRetail.isLoading);

  const rejectReasons = useSelector(state =>
    get(state, 'transaction.rejectReasons', [])
  );
  const onVerify = type => {
    dispatch(
      actions.csVerifyRetailSaleOrder({ type, note, sale_order_id: saleOrder._id })
    );
    setNote('');
  };

  const onReject = () => {
    const reason = form.getFieldValue()?.reason;
    if (!reason) {
      form.validateFields();
      setShowReason(true);
    } else
      dispatch(
        actions.rejectTransaction({ sale_order_id: saleOrder._id, reason }),
        false
      );
  };

  const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  return (
    <Spin spinning={loading}>
      {/* <TextArea
        value={note}
        rows={4}
        placeholder="Ghi chú"
        onChange={e => setNote(e.target.value)}
      /> */}
      <Form form={form} labelCol={{ span: 4 }}>
        <Form.Item
          style={{ display: showReason ? 'block' : 'none' }}
          label={<Text strong>Lý do hủy đơn</Text>}
          name="reason"
          rules={[{ required: true, message: 'Không được để trống' }]}
        >
          <Select
            showSearch
            style={{ width: '70%' }}
            placeholder="Select a reason"
            optionFilterProp="children"
            filterOption={filterOption}
            options={rejectReasons.map(i => ({ value: i?._id, label: i?.content }))}
          />
        </Form.Item>
      </Form>
      <Row gutter={16} style={{ marginTop: 10 }}>
        <Col>
          <Button type="primary" onClick={() => onVerify(CS_VERIFY_SO.confirm)}>
            Xác nhận
          </Button>
        </Col>
        <Col>
          <Button type="primary" onClick={() => onReject()} danger>
            Huỷ đơn
          </Button>
        </Col>
      </Row>
    </Spin>
  );
}

Action.propTypes = {
  saleOrder: objectOf
};

Action.defaultProps = {
  saleOrder: {}
};
