import { any, string } from 'prop-types';
import React from 'react';
import Images from '../../../contents/Images';

export default function Image({ defaultSrc, src, ...props }) {
  const imageSrc = src || defaultSrc || Images.defaultProduct;
  const handleError = event => {
    event.target.onerror = null;
    event.target.src = defaultSrc || Images.defaultProduct;
  };

  return <img {...props} src={imageSrc} onError={handleError} />;
}

Image.propTypes = {
  defaultSrc: string,
  src: any
};

Image.defaultProps = {
  defaultSrc: '',
  src: ''
};
