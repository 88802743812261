import React from 'react';
import ReactDOM from 'react-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import App from './App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <GoogleOAuthProvider clientId="259156509098-bb8jae1nn0t69go0u7v8lcujmmempgfn.apps.googleusercontent.com">
    <App />
  </GoogleOAuthProvider>,
  document.getElementById('root')
);

serviceWorker.unregister();
