import { Button, Form, Input, Space } from 'antd';
import { bool, func } from 'prop-types';
import React, { useState } from 'react';
import * as yup from 'yup';
import { notificationError } from '../../utils/Notification';

export default function CrawlMultipleLink({ onSubmit, isExistItem, onCancel }) {
  const [loading, setLoading] = useState(false);

  const onFinish = values => {
    const { links } = values;

    const linksArray = links
      .trim()
      .split('\n')
      .reduce((acc, cur) => {
        const currentUrl = cur.trim();
        const isValidItem = yup.string().required().url().isValidSync(currentUrl);
        if (isValidItem) acc.push(currentUrl);
        return acc;
      }, []);

    setLoading(true);
    Promise.allSettled(
      linksArray.map(url =>
        fetch(`${process.env.REACT_APP_PRODUCT_URL}/scraper?url=${url}`, {
          method: 'GET'
        }).then(response => response.json())
      )
    )
      .then(data => {
        const result = data.reduce(
          (acc, cur, index) => [
            ...acc,
            {
              quantity: 1,
              link: linksArray[index],
              key: crypto.randomUUID(),
              ...cur?.value
            }
          ],
          []
        );
        if (result.length > 0) {
          onSubmit(result);
          return;
        }
        notificationError('Link sản phẩm không hợp lệ');
      })
      .catch(() => notificationError('Có lỗi xảy ra. Vui lòng thử lại'))
      .finally(() => setLoading(false));
  };

  return (
    <Form
      name="basic"
      wrapperCol={{
        offset: 4,
        span: 16
      }}
      onFinish={onFinish}
      autoComplete="off"
    >
      <Form.Item
        name="links"
        rules={[
          {
            required: true,
            message: 'Nhập link sản phẩm!'
          }
        ]}
      >
        <Input.TextArea
          rows={5}
          placeholder={`Bạn có thể dán nhiều link. Mỗi link xuống dòng

www.link1.com
www.link2.com
www.link3.com`}
        />
      </Form.Item>

      <Form.Item
        wrapperCol={{
          offset: 4,
          span: 16
        }}
      >
        <Space>
          <Button loading={loading} type="primary" htmlType="submit">
            Tiếp tục
          </Button>
          {isExistItem && <Button onClick={onCancel}>Huỷ</Button>}
        </Space>
      </Form.Item>
    </Form>
  );
}

CrawlMultipleLink.propTypes = {
  onSubmit: func.isRequired,
  onCancel: func.isRequired,
  isExistItem: bool.isRequired
};
