import {
  AppstoreOutlined,
  BankFilled,
  BankOutlined,
  BgColorsOutlined,
  BranchesOutlined,
  CreditCardOutlined,
  DashboardOutlined,
  DollarOutlined,
  FireOutlined,
  FlagOutlined,
  ProfileOutlined,
  RetweetOutlined,
  TransactionOutlined,
  UserOutlined,
  VerifiedOutlined
} from '@ant-design/icons';
import CreateSaleOrder from '../components/CreateSaleOrder';
import MainLayout from '../components/MainLayout';
import OutLayout from '../components/OutLayout';
import AccountList from '../pages/Account';
// import DebtList from "../pages/Transaction/DebtList";
import Customer from '../pages/Customer';
import Dashboard from '../pages/Dashboard';
import DealNgon from '../pages/DealNgon';
import LogAction from '../pages/LogAction';
import Login from '../pages/Login';
import Withdrawal from '../pages/Withdrawal';
// import Notification from "../pages/Notification";
import CreateSaleOrderByDeal from '../components/CreateSaleOrderByDeal';
import Campaign from '../pages/Campaign';
import CreateDeal from '../pages/DealManage/component/CreateDeal';
import ListDeal from '../pages/DealManage/list/ListDeal';
import DealTransactionList from '../pages/DealTransaction/list';
import DealWithdraw from '../pages/DealWithdraw/list';
import DebtRecovery from '../pages/DebtRecovery';
import ExchangePointRequest from '../pages/ExchangePointRequest';
import Mobile from '../pages/Mobile';
import Payment from '../pages/Payment';
import TransactionRetailList from '../pages/Retail/TransactionList';
import Seller from '../pages/SellerProfile';
import Theme from '../pages/Theme';
import TransactionList from '../pages/Transaction/TransactionList';
import ShippingAddress from '../pages/ShippingAddress';
import Utility from '../pages/Utility';
import LogAPI from '../pages/Monitor/LogAPI';

export const ACCESS_KEY = 'ack';
export const REFRESH_TOKEN = 're-ack';
export const FCM_TOKEN = 'fcm';

export const Routes = {
  Home: '/',
  Dashboard: '/dashboard',
  Account: '/account',
  Transaction: '/transaction',
  DealTransaction: '/deal-transaction',
  DealManage: '/deal-manage',
  DealWithdraw: '/deal-withdraw',
  Debt: '/debt',
  Customer: '/customer',
  Login: '/login',
  Notification: '/notification',
  Theme: '/theme',
  LogAction: '/log-action',
  Mobile: '/mobile',
  CreateSaleOrder: '/create-sale-order',
  CreateSaleOrderByDeal: '/create-sale-order-by-deal',
  CreateDeal: '/create-deal',
  DealNgon: '/deal-ngon',
  Utility: '/utility',
  Wallet: '/wallet',
  Retail: '/retail',
  Withdrawal: '/withdrawal',
  ExchangePointRequest: '/exchange-point-request',
  SellerProfile: '/seller-profile',
  Marketing: '/campaign',
  Payment: '/payment',
  DebtRecovery: '/debt-recovery',
  ShippingAddress: '/shipping-address',
  LogAPI: '/monitor/logapi'
};

export const RULES_QUOTATION_CART = [
  {
    dataIndex: 'link',
    rules: [
      {
        type: 'url',
        message: 'Nhập liên kết hợp lệ'
      }
    ]
  },
  {
    dataIndex: 'quantity',
    rules: [
      () => ({
        validator(_, value) {
          if (value === null || value === undefined || value === '') {
            return Promise.resolve();
          }
          if (!Number.isInteger(value)) {
            return Promise.reject(new Error('Nhập số nguyên!'));
          }
          if (value <= 0) {
            return Promise.reject(new Error('Nhập số lớn hơn 0!'));
          }
          return Promise.resolve();
        }
      })
    ]
  },
  {
    dataIndex: 'price',
    rules: [
      () => ({
        validator(_, value) {
          if (value < 0) {
            return Promise.reject(new Error('Không nhập số âm!'));
          }
          return Promise.resolve();
        }
      })
    ]
  }
];

export const RULES_QUOTATION = (salePriceType, price, quantity) => [
  {
    dataIndex: 'link',
    rules: [
      {
        type: 'url',
        message: 'Nhập liên kết hợp lệ'
      }
    ]
  },
  {
    dataIndex: 'quantity',
    rules: [
      {
        type: 'number',
        message: 'Nhập số lượng lớn hơn 1',
        min: 1
      }
    ]
  },
  {
    dataIndex: 'price',
    rules: [
      {
        type: 'number',
        message: 'Nhập giá lớn hơn 0',
        min: 0
      }
    ]
  },
  {
    dataIndex: 'salePrice',
    rules: [
      {
        type: 'number',
        message: 'Nhập giá lớn hơn 0',
        min: 0
      },
      {
        type: 'number',
        message: 'Nhập giá nhỏ hơn 100',
        max: salePriceType === '%' && 100
      },
      {
        type: 'number',
        message: 'Nhập giá giảm không hợp lệ',
        max: salePriceType === '$' && price * quantity
      }
    ]
  },
  {
    dataIndex: 'category_obj',
    rules: [
      {
        validator: async (_, value) => {
          if (!value && value !== null) {
            return Promise.reject(new Error('Chọn ngành hàng'));
          }
          return true;
        }
      }
    ]
  }
];

export const RoutePages = {
  private: [
    {
      Icon: DashboardOutlined,
      name: 'Dashboard',
      path: Routes.Dashboard,
      showMenu: true,
      Layout: MainLayout,
      Page: Dashboard
    },
    {
      Icon: UserOutlined,
      name: 'Tài khoản',
      path: Routes.Account,
      showMenu: true,
      Layout: MainLayout,
      Page: AccountList
    },
    // {
    //   Icon: FireOutlined,
    //   name: 'Deal',
    //   showMenu: true,
    //   includes: [Routes.DealTransaction, Routes.DealManage, Routes.DealWithdraw]
    // },
    {
      Icon: BankOutlined,
      name: 'Giao dịch Deal',
      path: Routes.DealTransaction,
      showMenu: false,
      Layout: MainLayout,
      Page: DealTransactionList
    },
    {
      Icon: CreditCardOutlined,
      name: 'Quản lý Deal',
      path: Routes.DealManage,
      showMenu: false,
      Layout: MainLayout,
      Page: ListDeal
    },
    {
      Icon: RetweetOutlined,
      name: 'Hoàn trả Deal',
      path: Routes.DealWithdraw,
      showMenu: false,
      Layout: MainLayout,
      Page: DealWithdraw
    },
    {
      Icon: null,
      name: 'Taọ Deal',
      path: Routes.CreateDeal,
      showMenu: false,
      Layout: MainLayout,
      Page: CreateDeal
    },
    {
      Icon: null,
      name: 'Lên đơn hàng bằng deal',
      path: Routes.CreateSaleOrderByDeal,
      showMenu: false,
      Layout: MainLayout,
      Page: CreateSaleOrderByDeal
    },
    {
      Icon: TransactionOutlined,
      name: 'Giao dịch',
      path: Routes.Transaction,
      showMenu: true,
      Layout: MainLayout,
      Page: TransactionList
    },
    {
      Icon: VerifiedOutlined,
      name: 'Đơn khách lẻ',
      path: Routes.Retail,
      showMenu: true,
      Layout: MainLayout,
      Page: TransactionRetailList
    },
    {
      Icon: ProfileOutlined,
      name: 'Khách hàng',
      path: Routes.Customer,
      showMenu: true,
      Layout: MainLayout,
      Page: Customer
    },
    // {
    //   Icon: BellOutlined,
    //   name: "Thông báo",
    //   path: Routes.Notification,
    //   showMenu: true,
    //   Layout: MainLayout,
    //   Page: Notification
    // },
    {
      Icon: BgColorsOutlined,
      name: 'Giao diện',
      path: Routes.Theme,
      showMenu: true,
      Layout: MainLayout,
      Page: Theme
    },
    {
      Icon: BranchesOutlined,
      name: 'Hoạt động',
      path: Routes.LogAction,
      showMenu: true,
      Layout: MainLayout,
      Page: LogAction
    },
    {
      Icon: BranchesOutlined,
      name: 'Mobile',
      path: Routes.Mobile,
      showMenu: true,
      Layout: MainLayout,
      Page: Mobile
    },
    {
      Icon: FireOutlined,
      name: 'Deal Ngon',
      path: Routes.DealNgon,
      showMenu: true,
      Layout: MainLayout,
      Page: DealNgon
    },
    {
      Icon: null,
      name: 'Tạo đơn hàng mới',
      path: Routes.CreateSaleOrder,
      showMenu: false,
      Layout: MainLayout,
      Page: CreateSaleOrder
    },
    {
      Icon: AppstoreOutlined,
      name: 'Tiện ích',
      path: Routes.Utility,
      showMenu: true,
      Layout: MainLayout,
      Page: Utility
    },
    {
      Icon: AppstoreOutlined,
      name: 'Rút tiền',
      path: Routes.Withdrawal,
      showMenu: true,
      Layout: MainLayout,
      Page: Withdrawal
    },
    {
      Icon: DollarOutlined,
      name: 'Đổi điểm thưởng',
      path: Routes.ExchangePointRequest,
      showMenu: true,
      Layout: MainLayout,
      Page: ExchangePointRequest
    },
    {
      Icon: UserOutlined,
      name: 'Đối tác bán hàng',
      path: Routes.SellerProfile,
      showMenu: true,
      Layout: MainLayout,
      Page: Seller
    },
    {
      Icon: AppstoreOutlined,
      name: 'Marketing',
      path: Routes.Marketing,
      showMenu: true,
      Layout: MainLayout,
      Page: Campaign
    },
    {
      Icon: BankFilled,
      name: 'Phương thức thanh toán',
      path: Routes.Payment,
      showMenu: true,
      Layout: MainLayout,
      Page: Payment
    },
    {
      Icon: DollarOutlined,
      name: 'Thu hồi công nợ',
      path: Routes.DebtRecovery,
      showMenu: true,
      Layout: MainLayout,
      Page: DebtRecovery
    },
    {
      Icon: FlagOutlined,
      name: 'Địa chỉ ship hộ',
      path: Routes.ShippingAddress,
      showMenu: true,
      Layout: MainLayout,
      Page: ShippingAddress
    },
    {
      Icon: FlagOutlined,
      name: 'Monitor',
      path: Routes.LogAPI,
      showMenu: true,
      Layout: MainLayout,
      Page: LogAPI
    }
  ],
  public: [
    {
      Icon: null,
      name: '',
      path: Routes.Login,
      showMenu: false,
      Layout: OutLayout,
      Page: Login
    }
  ]
};

export const MESSAGE_NOTIFY = {
  ERROR: content => {
    return { error: -1, content, color: '#f05269' };
  },
  SUCCESS: content => {
    return { error: 0, content, color: '#1890ff' };
  }
};

export const ACTION_STATUS = {
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED'
};

export const PROVIDERS = {
  FACEBOOK: 'facebook.com',
  GOOGLE: 'google.com',
  ACCOUNT: 'up',
  PHONE: ''
};

export const ACTIVITY_STATUS = {
  ACTIVE: 'Còn hoạt động',
  INACTIVE: 'Không hoạt động'
};

export const DURATION = {
  DEFAULT: 5,
  NEVER_CLOSE: 0
};

export const SHOW_BUTTON = {
  SHOW: true,
  HIDE: false
};

export const MESSAGE_CONTENT = {
  updateTransaction: 'Có một đơn hàng vừa được tạo',
  updateSuccess: 'Chỉnh sửa thông tin thành công',
  createSuccess: 'Tạo mới thành công',
  duplicatePaymentContent: 'Mã này đã tồn tại, vui lòng chọn mã khác'
};

export const IS_AUTH = 'isAuth';

export const BankList = {
  VCB: 'Ngân hàng ngoại thương Việt Nam - Vietcombank',
  TCB: 'Ngân hàng TMCP Kỹ Thương - Techcombank',
  ACB: 'Ngân hàng TMCP Á Châu - ACBank'
};

export const TRANSACTION_STATUS = {
  customerConfirmed: { tab: 'Chờ xác nhận', key: 'customer_confirmed' },
  buyngonConfirmed: { tab: 'Đã xác nhận', key: 'buyngon_confirmed' },
  buyngonRejected: { tab: 'Đã từ chối', key: 'all_rejected' },
  list: [
    { tab: 'Chờ xác nhận', key: 'customer_confirmed' },
    { tab: 'Đã xác nhận', key: 'buyngon_confirmed' },
    { tab: 'Đã từ chối', key: 'all_rejected' }
  ]
};

export const DEAL_TRANSACTION_STATUS = {
  customerConfirmed: { tab: 'Chờ xác nhận', key: 'draft' },
  buyngonConfirmed: { tab: 'Đã xác nhận', key: 'buyngon_confirmed' },
  buyngonRejected: { tab: 'Đã từ chối', key: 'buyngon_rejected' },
  list: [
    { tab: 'Chờ xác nhận', key: 'draft' },
    { tab: 'Đã xác nhận', key: 'buyngon_confirmed' },
    { tab: 'Đã từ chối', key: 'buyngon_rejected' }
  ]
};

export const WITHDRAWAL_STATUS = {
  draft: { tab: 'Chờ xác nhận', key: 'draft' },
  approved: { tab: 'Đã xác nhận', key: 'approved' },
  rejected: { tab: 'Đã từ chối', key: 'rejected' },
  list: [
    { tab: 'Chờ xác nhận', key: 'draft' },
    { tab: 'Đã xác nhận', key: 'approved' },
    { tab: 'Đã từ chối', key: 'rejected' }
  ]
};

export const EXCHANGE_POINT_REQUEST_STATUS = {
  draft: { tab: 'Chờ xác nhận', key: 'draft' },
  confirmed: { tab: 'Đã xác nhận', key: 'confirmed' },
  rejected: { tab: 'Đã từ chối', key: 'rejected' },
  list: [
    { tab: 'Chờ xác nhận', key: 'draft' },
    { tab: 'Đã xác nhận', key: 'confirmed' },
    { tab: 'Đã từ chối', key: 'rejected' }
  ]
};

export const COUNTRY_NAME = {
  USA: 'America',
  ESP: 'Spain'
};

export const CURRENCY_TYPES = {
  USD: {
    currency: 'USD',
    unit: '$'
  },
  EUR: {
    currency: 'EUR',
    unit: '€'
  }
};

export const LOCATION_TYPES = [
  {
    value: 'USA',
    name: 'Mỹ',
    ...CURRENCY_TYPES.USD
  },
  {
    value: 'ESP',
    name: 'Tây Ban Nha',
    ...CURRENCY_TYPES.EUR
  }
];

export const SHIPPING_ADDRESS_FORMS = [
  {
    types: ['USA'],
    options: [
      { label: 'Address', key: 'address' },
      { label: 'City', key: 'city' },
      { label: 'State', key: 'state' },
      { label: 'ZIP/Postal Code', key: 'zip_code' },
      { label: 'Phone', key: 'phone' }
    ]
  },
  {
    types: ['ESP'],
    options: [
      { label: 'Address', key: 'address' },
      { label: 'Town', key: 'town' },
      { label: 'Province', key: 'province' },
      { label: 'ZIP/Postal Code', key: 'zip_code' },
      { label: 'Tel', key: 'tel' }
    ]
  }
];

export const SHIPPING_ADDRESS_VALIDATE_RULES = {
  address: [{ required: true, message: "'Address' is required" }],
  city: [{ required: true, message: "'City' is required" }],
  town: [{ required: true, message: "'Town' is required" }],
  state: [{ required: true, message: "'State' is required" }],
  province: [{ required: true, message: "'Province' is required" }],
  zip_code: [
    { type: 'number', message: "'ZIP/Postal Code' must be a number" },
    { required: true, message: "'ZIP/Postal Code' is required" }
  ],
  tel: [{ required: true, message: "'Tel' is required" }],
  phone: [{ required: true, message: "'Phone' is required" }]
};

export const LOCATION_TYPE_DEFAULT = LOCATION_TYPES[0];

export const SALE_PRICE_TYPE = [
  { value: 'none', name: 'Không có' },
  { value: '%', name: 'Chiếc khấu (%)' },
  { value: '$', name: 'Số lượng ($)' },
  { value: '€', name: 'Số lượng (€)' }
];

export const SAMPLE_QUOTATION = {
  link: '',
  image: '',
  name: '',
  color: '',
  size: '',
  quantity: 1,
  price: undefined,
  salePrice: undefined,
  location: LOCATION_TYPE_DEFAULT.value,
  salePriceType: 'none',
  note: ''
};

export const LIMIT_PAGE_DEFAULT = 10;

export const REQUIRED_LIST = ['link', 'name', 'quantity', 'price'];

export const LOGS = {
  AUTO_CANCEL: 'AUTO_CANCEL',
  PHONE_OTP: 'PHONE_OTP',
  AUTO_CONFIRM_SALE_ORDER_TRAN: 'AUTO_CONFIRM_SALE_ORDER_TRAN'
};

export const CATEGORY_TYPE = {
  group: 'group',
  category: 'categories'
};

export const CS_VERIFY_SO = {
  confirm: 'confirm',
  reject: 'reject'
};

export const SELLER_PROFILE_MODE = {
  create: 'create',
  edit: 'edit',
  off: null
};

export const CAMPAIGN_MODE = {
  create: 'create',
  detail: 'chi tiết',
  off: null
};

export const SALE_ORDER_V2 = {
  TYPE: {
    SALE_ORDER: 'sale_order',
    DEAL: 'deal'
  }
};

export const CAMPAIGN_PROMOTION = {
  STATE: {
    WAIT: 'wait',
    ACTIVATE: 'activate',
    CLOSE: 'close'
  },
  TYPE: {
    DECEMBER_BEST_DEAL: { value: 'december_best_deal', label: 'Deal cực ngon' }
  },
  CONDITION: {
    december_best_deal: [
      { value: 'quantity', label: 'Số lượng', component: 'number' },
      { value: 'emails', label: 'Danh sách email', component: 'textarea' },
      { value: 'websites', label: 'Link sản phẩm', component: 'textarea' },
      { value: 'discount', label: 'Giá giảm (đ)', component: 'number' }
    ]
  }
};
