import TYPE from './TYPE';

export default {
  loading: () => {
    return { type: TYPE.LOADING };
  },
  offLoading: () => {
    return { type: TYPE.OFF_LOADING };
  },

  getProfile: () => {
    return { type: TYPE.PROFILE_OWNER };
  },

  login: payload => {
    return { type: TYPE.LOGIN, payload };
  },
  logout: () => {
    return { type: TYPE.LOGOUT };
  },

  getAccountList: payload => {
    return { type: TYPE.LIST_ACCOUNTS, payload };
  },
  getAccountInfo: payload => {
    return { type: TYPE.ACCOUNT_DETAIL, payload };
  },
  updateAccount: payload => {
    return { type: TYPE.UPDATE_ACCOUNT, payload };
  },
  createNewAccount: payload => {
    return { type: TYPE.CREATE_ACCOUNT, payload };
  },
  removeAccount: payload => {
    return { type: TYPE.REMOVE_ACCOUNT, payload };
  },
  searchAccount: payload => {
    return { type: TYPE.SEARCH_ACCOUNT, payload };
  },
  changeStatusActivityAccount: payload => {
    return {
      type: TYPE.CHANGE_STATUS_ACTIVITY_ACCOUNT,
      payload
    };
  },

  getListDealWithdraw: payload => ({
    type: TYPE.GET_LIST_DEAL_WITHDRAW,
    payload
  }),

  createDealWithdrawRequest: payload => ({
    type: TYPE.CREATE_DEAL_WITHDRAW_REQUEST,
    payload
  }),

  getDealWithdrawDetail: payload => ({
    type: TYPE.GET_DEAL_WITHDRAW_DETAIL,
    payload
  }),

  confirmDealWithdraw: payload => ({
    type: TYPE.CONFIRM_DEAL_WITHDRAW,
    payload
  }),

  rejectDealWithdraw: payload => ({
    type: TYPE.REJECT_DEAL_WITHDRAW,
    payload
  }),

  getTransactionList: params => {
    return {
      type: TYPE.LIST_TRANSACTIONS,
      params
    };
  },
  showTransactionDetail: params => {
    return {
      type: TYPE.DETAIL_TRANSACTION,
      params
    };
  },

  getTransactionInfo: payload => {
    return { type: TYPE.GET_TRANSACTION_INFO, payload };
  },
  clearTransactionInfo: () => {
    return { type: TYPE.CLEAR_TRANSACTION_INFO };
  },

  updateTransaction: payload => {
    return {
      type: TYPE.UPDATE_TRANSACTION_INFO,
      payload
    };
  },
  acceptTransaction: (
    customerId,
    saleOrderId,
    paymentImg,
    paymentRef,
    priceTranfer,
    paidInfo
  ) => {
    return {
      type: TYPE.ACCEPT_TRANSACTION,
      customerId,
      saleOrderId,
      paymentImg,
      paymentRef,
      priceTranfer,
      paidInfo
    };
  },
  rejectTransaction: payload => {
    return { type: TYPE.REJECT_TRANSACTION, payload };
  },
  removeTransaction: payload => {
    return { type: TYPE.REMOVE_TRANSACTION, payload };
  },

  getCustomerInfo: payload => {
    return { type: TYPE.CUSTOMER_DETAIL, payload };
  },
  updateCustomer: payload => {
    return { type: TYPE.UPDATE_CUSTOMER, payload };
  },
  createNewCustomer: payload => {
    return { type: TYPE.CREATE_CUSTOMER, payload };
  },
  removeCustomer: payload => {
    return { type: TYPE.REMOVE_CUSTOMER, payload };
  },
  changeStatusActivityCustomer: payload => {
    return {
      type: TYPE.CHANGE_STATUS_ACTIVITY_CUSTOMER,
      payload
    };
  },
  searchCustomer: payload => {
    return { type: TYPE.SEARCH_CUSTOMER, payload };
  },
  notificationSaleOrder: saleOrderId => {
    return {
      type: TYPE.NOTIFICATION_TRANSACTION,
      saleOrderId
    };
  },
  updateFcmToken: fcmToken => {
    return {
      type: TYPE.UPDATE_FCM_TOKEN,
      fcmToken
    };
  },

  handleCheckAuth: () => {
    return {
      type: TYPE.CHECK_AUTH
    };
  },

  refreshToken: token => {
    return {
      type: TYPE.REFRESH_TOKEN,
      token
    };
  },

  getConfiguration: () => {
    return { type: TYPE.GET_CONFIGURATION };
  },
  updateConfiguration: payload => {
    return { type: TYPE.UPDATE_CONFIGURATION, payload };
  },
  getUrlImage: image => {
    return { type: TYPE.GET_URL_IMAGE, image };
  },

  getAllCustomers: () => {
    return { type: TYPE.GET_ALL_CUSTOMERS };
  },

  clearConfiguration: () => {
    return { type: TYPE.CLEAR_CONFIGURATION };
  },

  getDraftSaleOrder: userId => {
    return {
      type: TYPE.GET_DRAFT_SALE_ORDER,
      userId
    };
  },

  addQuotationToSaleOrder: (userId, quotationList) => {
    return {
      type: TYPE.ADD_QUOTATION_TO_SALE_ORDER,
      userId,
      quotationList
    };
  },

  editQuotation: (quotationId, userId, quotation) => {
    return {
      type: TYPE.EDIT_QUOTATION,
      quotationId,
      userId,
      quotation
    };
  },

  deleteQuotation: (quotationId, userId) => {
    return {
      type: TYPE.DELETE_QUOTATION,
      quotationId,
      userId
    };
  },

  getDeliveryAddress: userId => {
    return {
      type: TYPE.GET_DELIVERY_ADDRESS,
      userId
    };
  },

  createDeliveryAddress: (userId, address) => {
    return {
      type: TYPE.CREATE_DELIVERY_ADDRESS,
      userId,
      address
    };
  },

  selectedDeliveryAddress: (addressId, userId) => {
    return {
      type: TYPE.SELECTED_DELIVERY_ADDRESS,
      addressId,
      userId
    };
  },

  confirmSaleOrder: (userId, paymentCode) => {
    return {
      type: TYPE.CONFIRM_SALE_ORDER,
      userId,
      paymentCode
    };
  },

  selectCustomer: user => {
    return {
      type: TYPE.SELECT_CUSTOMER,
      user
    };
  },

  getAllProvince: () => {
    return {
      type: TYPE.GET_ALL_PROVINCE
    };
  },

  getAllDistrict: provinceId => {
    return {
      type: TYPE.GET_ALL_DISTRICT,
      provinceId
    };
  },

  getAllWard: (provinceId, districtId) => {
    return {
      type: TYPE.GET_ALL_WARD,
      provinceId,
      districtId
    };
  },

  clearCustomer: () => {
    return {
      type: TYPE.CLEAR_CUSTOMER
    };
  },

  importQuotation: file => {
    return {
      type: TYPE.IMPORT_QUOTATION,
      file
    };
  },

  clearImportQuotation: () => {
    return {
      type: TYPE.CLEAR_IMPORT_QUOTATION
    };
  },

  getLogCancel: payload => {
    return {
      type: TYPE.AUTO_CANCEL,
      payload
    };
  },

  handleEditQuotation: payload => ({
    type: TYPE.HANDLE_EDIT_QUOTATION,
    payload
  }),

  handleHideModelEdit: payload => ({
    type: TYPE.HANDLE_HIDE_QUOTATION,
    payload
  }),

  handleEdit: payload => ({
    type: TYPE.HANDLE_EDIT,
    payload
  }),

  getWebsiteListDrawler: payload => {
    return {
      type: TYPE.GET_WEBSITE_LIST_CRAWLER,
      payload
    };
  },

  addFormatWebsiteCrawler: payload => {
    return {
      type: TYPE.ADD_FORMAT_WEBSITE_CRAWLER,
      payload
    };
  },

  editFormatWebsiteCrawler: payload => {
    return {
      type: TYPE.EDIT_FORMAT_WEBSITE_CRAWLER,
      payload
    };
  },

  createFormatWebsiteCrawler: payload => {
    return {
      type: TYPE.CREATE_FORMAT_WEBSITE_CRAWLER,
      payload
    };
  },

  syncPurchaseWebsite: payload => {
    return {
      type: TYPE.SYNC_PURCHASE_WEBSTIE,
      payload
    };
  },

  getAllCategories: () => {
    return { type: TYPE.GET_ALL_CATEGORIES };
  },

  draggableCategories: payload => {
    return { type: TYPE.DRAGGABLE_CATEGORIES, payload };
  },

  draggableTopBrand: payload => {
    return { type: TYPE.DRAGGABLE_TOP_BRAND, payload };
  },

  draggableCarousel: payload => {
    return { type: TYPE.DRAGGABLE_CAROUSEL, payload };
  },

  updateCategories: payload => {
    return { type: TYPE.UPDATE_CATEGORIES, payload };
  },

  updateCategoriesName: (body, groupIndex, categoryIndex) => {
    return {
      type: TYPE.UPDATE_CATEGORIES_NAME,
      body,
      groupIndex,
      categoryIndex
    };
  },

  createGroup: body => {
    return { type: TYPE.CREATE_GROUP, body };
  },

  createCategory: body => {
    return {
      type: TYPE.CREATE_CATEGORY,
      body
    };
  },
  deleteCategory: (body, groupIndex, categoryIndex) => {
    return {
      type: TYPE.DELETE_CATEGORY,
      body,
      groupIndex,
      categoryIndex
    };
  },
  updateCrawlWebsite: payload => {
    return {
      type: TYPE.UPDATE_CRAWL_WEBSITE,
      payload
    };
  },

  getListWebsiteDisableUS: () => {
    return {
      type: TYPE.GET_LIST_WEBSITE_DISABLE_US
    };
  },
  // Deal Ngon
  createTag: payload => {
    return {
      type: TYPE.CREATE_TAG,
      payload
    };
  },

  getListTags: () => {
    return { type: TYPE.GET_LIST_TAGS };
  },

  updateTag: payload => {
    return {
      type: TYPE.UPDATE_TAG,
      payload
    };
  },

  getListWebsites: () => {
    return { type: TYPE.GET_LIST_WEBSITES };
  },

  createWebsite: payload => {
    return { type: TYPE.CREATE_WEBSITE, payload };
  },

  updateWebsite: payload => {
    return { type: TYPE.UPDATE_WEBSITE, payload };
  },

  enableLoading: () => {
    return {
      type: TYPE.ENABLE_LOADING
    };
  },

  disableLoading: () => {
    return { type: TYPE.DISABLE_LOADING };
  },

  createDNCategory: payload => {
    return { type: TYPE.CREATE_DN_CATEGORY, payload };
  },

  updateDNCategory: payload => {
    return { type: TYPE.UPDATE_DN_CATEGORY, payload };
  },
  updateIconCategories: payload => {
    return { type: TYPE.UPDATE_ICON_CATEGORIES, payload };
  },

  getListDNCategory: () => {
    return { type: TYPE.GET_LIST_DN_CATEGORIES };
  },

  createIcon: payload => {
    return { type: TYPE.CREATE_ICON, payload };
  },

  getListIcons: () => {
    return { type: TYPE.GET_LIST_ICONS };
  },

  getListIconsOther: () => {
    return { type: TYPE.GET_LIST_ICONS_OTHER };
  },

  draggableBanners: payload => {
    return { type: TYPE.DRAGGABLE_BANNER, payload };
  },

  draggableListCategory: payload => {
    return { type: TYPE.DRAGGABLE_LIST_CATEGORY, payload };
  },

  deleteIcon: payload => {
    return { type: TYPE.DELETE_ICON, payload };
  },

  deleteTag: payload => {
    return { type: TYPE.DELETE_TAG, payload };
  },

  deleteWebsite: id => {
    return { type: TYPE.DELETE_WEBSITE, id };
  },

  getShippingCategories: () => ({
    type: TYPE.GET_SHIPPING_CATEGORIES
  }),

  getInfoWallet: payload => ({ type: TYPE.GET_INFO_WALLET, payload }),
  clearInfoWallet: () => ({ type: TYPE.CLEAR_INFO_WALLET }),

  csVerifyRetailSaleOrder: payload => ({
    type: TYPE.CS_VERIFY_RETAIL_SALE_ORDER,
    payload
  }),

  getListPaymentContent: payload => {
    return {
      type: TYPE.GET_LIST_PAYMENT_CONTENT,
      payload
    };
  },

  createPaymentContent: payload => {
    return {
      type: TYPE.CREATE_PAYMENT_CONTENT,
      payload
    };
  },

  getWithdrawal: params => {
    return {
      type: TYPE.GET_LIST_WITHDRAWAL,
      params
    };
  },

  getWithdrawalInfo: payload => {
    return { type: TYPE.GET_WITHDRAWAL_DETAIL, payload };
  },

  clearWithdrawalInfo: () => {
    return { type: TYPE.CLEAR_WITHDRAWAL_DETAIL };
  },

  toggleRequestChangePoint: () => {
    return { type: TYPE.TOGGLE_REQUEST_CHANGE_POINT };
  },

  toggleExportPointStatistical: () => {
    return { type: TYPE.TOGGLE_EXPORT_POINT_STATISTICAL };
  },

  exportPointStatistical: payload => {
    return { type: TYPE.EXPORT_POINT_STATISTICAL, payload };
  },

  getExchangePointRequestInfo: payload => {
    return { type: TYPE.GET_EXCHANGE_POINT_REQUEST_DETAIL, payload };
  },

  clearExchangePointRequestInfo: () => {
    return { type: TYPE.CLEAR_EXCHANGE_POINT_REQUEST_DETAIL };
  },

  getListBank: () => {
    return { type: TYPE.GET_LIST_BANK };
  },

  rejectWithdrawal: payload => {
    return { type: TYPE.REJECT_WITHDRAWAL, payload };
  },

  approveWithdrawal: payload => {
    return { type: TYPE.APPROVE_WITHDRAWAL, payload };
  },

  getListExchangePointRequest: payload => {
    return { type: TYPE.GET_LIST_EXCHANGE_POINT_REQUEST, payload };
  },

  confirmExchangePointRequest: payload => {
    return { type: TYPE.CONFIRM_EXCHANGE_POINT_REQUEST, payload };
  },

  rejectExchangePointRequest: payload => {
    return { type: TYPE.REJECT_EXCHANGE_POINT_REQUEST, payload };
  },

  updatePointByAdmin: payload => {
    return { type: TYPE.UPDATE_POINT_BY_ADMIN, payload };
  },

  getPromotionUs: () => {
    return { type: TYPE.GET_PROMOTION_US };
  },
  deletePromotionUs: payload => {
    return { type: TYPE.DELETE_PROMOTION_US, payload };
  },
  createPromotionUs: payload => {
    return { type: TYPE.CREATE_PROMOTION_US, payload };
  },
  updatePromotionUs: payload => {
    return { type: TYPE.UPDATE_PROMOTION_US, payload };
  },

  getCurrentDealOrder: payload => {
    return { type: TYPE.GET_CURRENT_DEAL_ORDER, payload };
  },

  confirmDealOrder: payload => {
    return { type: TYPE.CONFIRM_DEAL_ORDER, payload };
  },

  getListDealByUser: payload => {
    return { type: TYPE.GET_LIST_DEAL_BY_USER, payload };
  },

  clearListDealByUser: () => {
    return { type: TYPE.CLEAR_LIST_DEAL_BY_USER };
  },

  deleteItemDealOrder: payload => {
    return { type: TYPE.DELETE_ITEM_DEAL_ORDER, payload };
  },

  addItemDealOrder: payload => {
    return { type: TYPE.ADD_ITEM_DEAL_ORDER, payload };
  },

  getMetaDealList: () => {
    return {
      type: TYPE.LIST_META_DEAL
    };
  },

  getListDeal: params => {
    return { type: TYPE.LIST_DEALS, params };
  },
  getDealDetail: id => {
    return {
      type: TYPE.DETAIL_DEAL,
      id
    };
  },
  clearDealDetail: () => {
    return { type: TYPE.CLEAR_DEAL_DETAIL };
  },

  confirmDeal: payload => {
    return { type: TYPE.CONFIRM_DEAL, payload };
  },

  rejectDeal: payload => {
    return { type: TYPE.REJECT_DEAL, payload };
  },
  reorderSaleOrder: payload => {
    return { type: TYPE.REORDER_SALE_ORDER, payload };
  },

  listSellerProfile: params => {
    return { type: TYPE.LIST_SELLER_PROFILE, params };
  },

  changeModeSeller: payload => {
    return { type: TYPE.CHANGE_MODE_SELLER_PROFILE, payload };
  },

  createSellerProfile: payload => {
    return { type: TYPE.CREATE_SELLER_PROFILE, payload };
  },

  createDeal: payload => {
    return { type: TYPE.CREATE_DEAL, payload };
  },
  editSellerProfile: payload => {
    return { type: TYPE.EDIT_SELLER_PROFILE, payload };
  },

  openSellerProfileDetail: payload => {
    return { type: TYPE.OPEN_SELLER_PROFILE_DETAIL, payload };
  },

  listCategory: () => {
    return { type: TYPE.LIST_CATEGORY };
  },
  listTopic: () => {
    return { type: TYPE.LIST_TOPIC };
  },
  updateStateDeal: payload => {
    return { type: TYPE.UPDATE_STATE_DEAL, payload };
  },
  clearUpdateStateDeal: () => {
    return { type: TYPE.CLEAR_UPDATE_STATE_DEAL };
  },
  searchDeal: payload => {
    return { type: TYPE.SEARCH_DEAL, payload };
  },
  updateWaitingSaleOrder: payload => {
    return { type: TYPE.UPDATE_WAITING_SALE_ORDER, payload };
  },

  listCampaign: params => {
    return { type: TYPE.LIST_CAMPAIGN, params };
  },

  createCampaign: payload => {
    return { type: TYPE.CREATE_CAMPAIGN, payload };
  },

  changeModeCampaign: payload => {
    return { type: TYPE.CHANGE_MODE_CAMPAIGN, payload };
  },

  openCampaignDetail: payload => {
    return { type: TYPE.OPEN_CAMPAIGN_DETAIL, payload };
  },

  updateCampaign: payload => {
    return { type: TYPE.UPDATE_CAMPAIGN, payload };
  },

  getCampaignHistory: payload => {
    return {
      type: TYPE.GET_CAMPAIGN_HISTORY,
      payload
    };
  },

  getListTopBrand: payload => {
    return {
      type: TYPE.GET_LIST_TOP_BRAND,
      payload
    };
  },
  getBankTransferPool: () => {
    return {
      type: TYPE.GET_BANK_TRANSFER_POOL
    };
  },

  getListVAs: () => {
    return {
      type: TYPE.GET_LIST_VA
    };
  },

  getListDebtRecovery: () => {
    return {
      type: TYPE.GET_LIST_DEBT_RECOVERY
    };
  },

  createDebtRecovery: payload => {
    return {
      type: TYPE.CREATE_DEBT_RECOVERY,
      payload
    };
  },

  getMobileSetting: payload => {
    return {
      type: TYPE.GET_MOBILE_SETTING,
      payload
    };
  },

  updateMobileSetting: payload => {
    return {
      type: TYPE.UPDATE_MOBILE_SETTING,
      payload
    };
  },

  createTopBrand: payload => {
    return {
      type: TYPE.CREATE_TOP_BRAND,
      payload
    };
  },

  updateTopBrand: payload => {
    return {
      type: TYPE.UPDATE_TOP_BRAND,
      payload
    };
  },

  updateTopBrandPriority: payload => {
    return {
      type: TYPE.UPDATE_TOP_BRAND_PRIORITY,
      payload
    };
  },

  deleteTopBrand: payload => {
    return {
      type: TYPE.DELETE_TOP_BRAND,
      payload
    };
  },

  closeDebtRecovery: payload => {
    return {
      type: TYPE.CLOSE_DEBT_RECOVERY,
      payload
    };
  },

  createShippingAddress: payload => {
    return { type: TYPE.CREATE_SHIPPING_ADDRESS, payload };
  },

  getShippingAddress: () => {
    return { type: TYPE.GET_SHIPPING_ADDRESS };
  },

  updateShippingAddress: payload => {
    return { type: TYPE.UPDATE_SHIPPING_ADDRESS, payload };
  },

  deleteShippingAddress: payload => {
    return { type: TYPE.DELETE_SHIPPING_ADDRESS, payload };
  },

  monitorLogApi: payload => {
    return { type: TYPE.MONITOR_LOG_API, payload };
  },

  listSaleOrderRejectReason: () => {
    return { type: TYPE.LIST_SALE_ORDER_REJECT_REASON };
  },

  openCancelOrderModel: payload => {
    return { type: TYPE.OPEN_CANCEL_ORDER_MODEL, payload };
  },

  selectCartItemToCheckout: payload => {
    return { type: TYPE.SELECT_CART_ITEM_TO_CHECKOUT, payload };
  },

  getUserProfile: payload => {
    return { type: TYPE.GET_USER_PROFILE, payload };
  },

  getListBuyNgonCategory: () => {
    return { type: TYPE.GET_LIST_BUYNGON_CATEGORY };
  },

  onModelAddPromotion: website => {
    return { type: TYPE.ON_MODEL_ADD_PROMOTION, payload: website };
  },

  addWebsitePromotion: payload => {
    return { type: TYPE.ADD_WEBSITE_PROMOTION, payload };
  },

  getWebsitePromotion: () => {
    return { type: TYPE.GET_WEBSITE_PROMOTION };
  },

  deleteWebsitePromotion: payload => {
    return { type: TYPE.DELETE_WEBSITE_PROMOTION, payload };
  }
};
