import { Modal, Typography } from 'antd';
import { get } from 'lodash';
import { bool, func, object } from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import actions from '../../redux/actions';

export default function ShippingAddressDeleteModal({ data, open, onCancel }) {
  const dispatch = useDispatch();
  const loading = useSelector(state =>
    get(state, 'common.loadingImprove.deleteShippingAddress', false)
  );

  const handleDelete = () => {
    const payload = {
      value: {
        id: data?._id,
        body: { state: 'deactivate' }
      },
      callback: onCancel
    };
    dispatch(actions.deleteShippingAddress(payload));
  };

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      okText="Xác nhận"
      cancelText="Huỷ bỏ"
      onOk={handleDelete}
      okButtonProps={{ loading }}
    >
      <Typography style={{ fontSize: 16 }}>
        Bạn chắc chắn muốn xoá địa chỉ này?
      </Typography>
    </Modal>
  );
}

ShippingAddressDeleteModal.propTypes = {
  data: object,
  open: bool,
  onCancel: func
};

ShippingAddressDeleteModal.defaultProps = {
  data: {},
  open: false,
  onCancel: () => {}
};
