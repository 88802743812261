import { shallowEqual, useSelector } from 'react-redux';
import dispatch from '../utils/dispatch';
import actions from '../redux/actions';

export const useStorePromotion = () => {
  const userSelected = useSelector(
    state => state?.customer?.userSelected,
    shallowEqual
  );
  const onDelete = ids => {
    dispatch(
      actions.deleteWebsitePromotion({
        quotation_ids: ids,
        user_id: userSelected?._id
      })
    );
  };
  return {
    onDelete
  };
};
