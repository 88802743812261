import Spin from 'antd/lib/spin';
import get from 'lodash/get';
import map from 'lodash/map';
import React, { createContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import CustomizeTable from '../../../components/CustomizeTable/CustomizeTable';
import FilterAndSearch from '../../../components/FilterAndSearch';
import ConfirmTransaction from '../../../components/Modal/ConfirmTransaction';
import {
  LIMIT_PAGE_DEFAULT,
  SALE_ORDER_V2,
  TRANSACTION_STATUS
} from '../../../contents/Constants';
import { MessageContent } from '../../../contents/Labels';
import actions from '../../../redux/actions';
import dispatch from '../../../utils/dispatch';
import TransactionDetail from '../TransactionDetail';
import columnData from './columnData';
import './style.less';
import CancelOrderModel from '../CancelOrderModel';

export const TransactionListContext = createContext(null);

const SEARCH_TRANSACTION = {
  text: '',
  date: null
};

const TransactionList = () => {
  const { count, transactions } = useSelector(state => {
    return get(state, 'transaction.list', {});
  });
  const loading = useSelector(state => {
    return get(state, 'transaction.isLoading', false);
  });
  const [page, setPage] = useState(1);
  const [activeTab, setActiveTab] = useState(
    TRANSACTION_STATUS.customerConfirmed.key
  );
  const [searchForm, setSearchForm] = useState(SEARCH_TRANSACTION);
  const searchFormRef = useRef(SEARCH_TRANSACTION);
  const activeTabRef = useRef(TRANSACTION_STATUS.customerConfirmed.key);

  useEffect(() => {
    dispatch(actions.listSaleOrderRejectReason());
  }, []);

  useEffect(() => {
    const { text: search, date } = searchForm;
    const pageIndex =
      searchFormRef.current === searchForm && activeTabRef.current === activeTab
        ? page
        : 1;

    searchFormRef.current = searchForm;
    activeTabRef.current = activeTab;
    setPage(pageIndex);

    dispatch(
      actions.getTransactionList({
        search,
        date,
        status: activeTab,
        page: pageIndex,
        limit: LIMIT_PAGE_DEFAULT,
        source: 'transaction_page'
      })
    );
  }, [page, searchForm, activeTab]);

  const data = map(transactions, item => {
    return { key: item._id, ...item };
  }).filter(
    item =>
      !item?.is_retail_customer ||
      item?.is_cs_verify ||
      item?.payment_option === 1 ||
      item?.type === SALE_ORDER_V2.TYPE.DEAL ||
      item?.status === 'buyngon_rejected' ||
      item?.status === 'customer_rejected' ||
      item?.auto_confirm_so_tran?.success
  );

  // const sortData = list => {
  //   const listFormat = list.reduce((r, q) => {
  //     if (q.type === 'deal') {
  //       r.push({
  //         ...q,
  //         bold_approved_time: q?.approved_time,
  //         bold_rejected_time: q?.approved_time,
  //         bold_customer_created_time: q?.created_at
  //       });
  //     } else {
  //       r.push({
  //         ...q,
  //         bold_approved_time: q?.approved_time,
  //         bold_rejected_time: q?.rejected_time,
  //         bold_customer_created_time: q?.confirmed_time
  //       });
  //     }
  //     return r;
  //   }, []);

  //   switch (activeTab) {
  //     case TRANSACTION_STATUS.buyngonConfirmed.key:
  //       return orderBy(listFormat, ['approved_time'], ['desc']);
  //     case TRANSACTION_STATUS.customerConfirmed.key:
  //       return orderBy(listFormat, ['confirmed_time'], ['desc']);
  //     case TRANSACTION_STATUS.buyngonRejected.key:
  //       return orderBy(listFormat, ['rejected_time'], ['desc']);
  //     default:
  //       return listFormat;
  //   }
  // };

  const handleChangePage = pageIndex => {
    return setPage(pageIndex);
  };

  return (
    <TransactionListContext.Provider value={{ searchForm, setSearchForm }}>
      <Spin spinning={loading}>
        <CustomizeTable
          title={MessageContent.TransactionInfo}
          columns={columnData[activeTab]}
          // data={sortData(data)}
          data={data}
          count={count}
          currentPage={page}
          onPageChange={handleChangePage}
          filterAndSearch={<FilterAndSearch.Transaction />}
          categoryTabs={TRANSACTION_STATUS.list}
          activeCategoryTab={activeTab}
          onChangeCategoryTab={setActiveTab}
        />
      </Spin>
      <TransactionDetail />
      <ConfirmTransaction />
      <CancelOrderModel />
    </TransactionListContext.Provider>
  );
};

export default TransactionList;
