/* eslint-disable no-param-reassign */

import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import './styles.less';
import Button from 'antd/lib/button';
import Drawer from 'antd/lib/drawer';
import Row from 'antd/lib/row';
import Space from 'antd/lib/space';
import { find, get, isEmpty, size } from 'lodash';
import Avatar from 'antd/lib/avatar/avatar';
import Empty from 'antd/lib/empty';
import Spin from 'antd/lib/spin';
import Typography from 'antd/lib/typography';
import DoubleRightOutlined from '@ant-design/icons/DoubleRightOutlined';
import CloseOutlined from '@ant-design/icons/CloseOutlined';
import shortid from 'shortid';
import Title from 'antd/lib/typography/Title';
import CustomerProfile from '../CustomerProfile';
import SearchCustomer from '../SearchCustomer';
import dispatch from '../../utils/dispatch';
import actions from '../../redux/actions';
import { convertAddress } from '../../utils/convert';
import { notificationError } from '../../utils/Notification';
import CartSaleOrder from './TableSaleOrder/CartSaleOrder';
import RequestSaleOrder from './TableSaleOrder/RequestSaleOrder';
import useGetQuotationTemplate from '../../hooks/useGetQuotationTemplate';
import { useSaleCreateOrderForCustomer } from '../../hooks/useSaleCreateOrderForCustomer';

const CreateSaleOrder = () => {
  const [isSelectedUser, setIsSelectedUser] = useState(false);
  const loading = useSelector(state => {
    return get(state, 'common.loading', false);
  });

  useEffect(() => {
    dispatch(actions.getListWebsiteDisableUS());
    dispatch(actions.getListBuyNgonCategory());
  }, []);

  useEffect(() => {
    return () => dispatch(actions.clearCustomer());
  }, []);

  const isWholeSale = useSelector(
    state => state?.customer?.resPartner?.is_wholesale || false,
    shallowEqual
  );
  const { carts } = useSaleCreateOrderForCustomer();

  const selectedCustomer = useSelector(state => {
    return get(state, 'customer.userSelected', {});
  });
  const deliveryAddress = find(get(selectedCustomer, 'address_management'), item => {
    return item.selected;
  });
  const listImport = useSelector(state => {
    return get(state, 'transaction.listQuotationImport', []);
  }).map(item => {
    return {
      ...item,
      key: shortid.generate()
    };
  });

  const address = convertAddress(deliveryAddress);

  const [visible, setVisible] = useState(false);
  const userSelected = useSelector(state => {
    return get(state, 'customer.userSelected');
  });
  const showDrawer = () => {
    dispatch(actions.selectCustomer(null));
    setVisible(true);
  };

  const handleShowDrawer = () => {
    return setVisible(true);
  };

  const handleClose = () => {
    setVisible(false);
    dispatch(actions.clearCustomer());
  };

  const UserProfile = (
    <Space className="user-profile">
      <Avatar src={get(selectedCustomer, 'avatar_url')} />
      <div className="user-info">
        <Typography.Title level={4}>
          {get(deliveryAddress, 'name', 'name')}
        </Typography.Title>
        <Typography.Text>
          <Typography.Text strong>{' Địa chỉ nhận hàng: '}</Typography.Text>
          {address}
        </Typography.Text>
        <Typography.Text strong style={{ color: 'red' }}>
          {isWholeSale ? 'Khách sỉ' : 'Khách lẻ'}
        </Typography.Text>
        <Button type="text" className="btn" onClick={handleShowDrawer}>
          {'Xem khách hàng '} <DoubleRightOutlined />
        </Button>
      </div>
    </Space>
  );

  const Header = !isEmpty(userSelected) ? (
    UserProfile
  ) : (
    <Button type="primary" onClick={showDrawer}>
      Chọn khách hàng
    </Button>
  );

  const DraftSaleOrder = !isEmpty(userSelected) ? (
    <RequestSaleOrder listImport={listImport} />
  ) : (
    <Empty description={<span>Vui lòng chọn khách hàng</span>} />
  );

  const handleUploadFile = event => {
    if (!userSelected) {
      notificationError('Vui lòng chọn khách hàng trước');
      event.target.value = null;
      return;
    }

    const file = event.target.files[0];
    dispatch(actions.importQuotation(file));
    event.target.value = null;
  };

  const QUOTATION_FILE = useGetQuotationTemplate();

  return (
    <div className="create-sale-order">
      <Title className="title" level={5}>
        Tạo đơn hàng
      </Title>
      <Spin spinning={loading}>
        <Row justify="space-between" align="top">
          <Space>
            <label
              className={`btn-import ${!userSelected && 'btn-disable'}`}
              htmlFor="import"
            >
              Import đơn hàng cho khách
              <input
                id="import"
                hidden
                type="file"
                disabled={!userSelected}
                accept=".csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                onChange={handleUploadFile}
              />
            </label>
            <Button disabled={!userSelected} href={QUOTATION_FILE}>
              Tải xuống mẫu excel
            </Button>
          </Space>
          {Header}
        </Row>
        {DraftSaleOrder}
        {size(carts) > 0 && (
          <CartSaleOrder keyValue="_id" carts={carts} title="Giỏ hàng" showConfirm />
        )}
        <Drawer
          className="drawer"
          width={500}
          title="Chọn khách hàng"
          placement="right"
          closeIcon={<CloseOutlined onClick={handleClose} />}
          visible={visible}
        >
          {userSelected && (
            <CustomerProfile
              isSelected={isSelectedUser}
              setIsSelected={setIsSelectedUser}
              setVisible={setVisible}
            />
          )}
          {!userSelected && <SearchCustomer />}
        </Drawer>
      </Spin>
    </div>
  );
};

export default CreateSaleOrder;
