import { Tooltip } from 'antd';
import Button from 'antd/lib/button';
import Text from 'antd/lib/typography/Text';
import { find } from 'lodash';
import moment from 'moment';
import React from 'react';
import FlagsIcon from '../../../components/common/FlagsIcon';
import { LOCATION_TYPES, LOCATION_TYPE_DEFAULT } from '../../../contents/Constants';
import { MessageContent } from '../../../contents/Labels';
import actions from '../../../redux/actions';
import { convertVND } from '../../../utils/convert';
import dispatch from '../../../utils/dispatch';

export default {
  customer_confirmed: [
    {
      title: 'Ngày giao dịch',
      key: 'createdAt',
      dataIndex: 'created_at',
      align: 'center',
      width: '20%',
      fixed: true,
      render: (text, row) => {
        return (
          <Text>
            {moment(row?.confirmed_time || text).format('HH:mm:ss, DD/MM/YYYY')}
          </Text>
        );
      }
    },
    {
      title: 'confirmed_time',
      dataIndex: 'confirmed_time',
      className: 'hide'
    },
    {
      title: 'Nội dung',
      dataIndex: 'payment_code',
      key: 'paymentCode'
    },
    {
      title: 'Tên khách hàng',
      dataIndex: 'user_id',
      render: text => <Tooltip title={text?.[0]?.email}>{text?.[0]?.name}</Tooltip>
    },
    {
      title: 'Số tiền đề xuất',
      dataIndex: 'total_price_after_sale_VND',
      key: 'totalPriceAfterSale',
      align: 'right',
      render: text => {
        return <Text className="total">{convertVND(text)}</Text>;
      }
    },
    {
      title: 'Thuộc tuyến',
      dataIndex: 'location',
      key: 'location',
      render: dataColumns => {
        const location =
          find(LOCATION_TYPES, item => item.value === dataColumns) ||
          LOCATION_TYPE_DEFAULT;
        return (
          <Text className="location">
            <FlagsIcon type={location.value} />
            &nbsp;{location.name}
          </Text>
        );
      }
    },
    {
      title: 'Thao tác',
      dataIndex: 'action',
      key: 'action',
      align: 'left',
      render: (_, record) => {
        return (
          <div>
            {record?.is_retail_customer && (
              <Button
                type="primary"
                style={{ marginLeft: 10 }}
                onClick={() => {
                  return dispatch(actions.showTransactionDetail(record), false);
                }}
              >
                {MessageContent.CsVerifySO}
              </Button>
            )}
          </div>
        );
      }
    }
  ]
};
