import { Button, Form, Input, InputNumber, Radio, Spin } from 'antd';
import { keys } from 'lodash';
import { bool, func, object } from 'prop-types';
import React, { useEffect, useState } from 'react';
import FlagsIcon from '../../../components/common/FlagsIcon';
import {
  COUNTRY_NAME,
  LOCATION_TYPES,
  SHIPPING_ADDRESS_FORMS,
  SHIPPING_ADDRESS_VALIDATE_RULES
} from '../../../contents/Constants';
import '../styles.less';

function ShippingAddressForm({ loading, data, onSubmit }) {
  const [country, setCountry] = useState('USA');
  const [form] = Form.useForm();

  useEffect(() => {
    const _country = data?.type;

    if (_country) {
      setCountry(_country);
    }

    return () => {
      form.resetFields();
    };
  }, [data, form]);

  const formOptions =
    SHIPPING_ADDRESS_FORMS.find(({ types }) => types.includes(country))?.options ||
    [];
  const initialValues = keys(data.info).reduce((result, item) => {
    const field = { [item]: data?.info?.[item]?.value };
    result = { ...result, ...field };

    return result;
  }, {});

  const handleSubmit = value => {
    const formatData = formOptions.reduce((result, item) => {
      const field = {
        [item.key]: {
          label: item.label,
          value: value[item.key]
        }
      };
      result = { ...result, ...field };
      return result;
    }, {});

    const dataReturn = {
      value: { id: data._id, body: { info: formatData, type: country } },
      resetInput: form.resetFields
    };

    onSubmit(dataReturn);
  };

  const onChangeCountry = ({ target }) => setCountry(target.value);

  return (
    <Spin spinning={loading}>
      <Radio.Group onChange={event => onChangeCountry(event)} value={country}>
        {LOCATION_TYPES.map((item, index) => (
          <Radio value={item.value} key={index}>
            {<FlagsIcon type={item.value} />}&nbsp;
            {COUNTRY_NAME[item.value]}
          </Radio>
        ))}
      </Radio.Group>
      <Form
        form={form}
        initialValues={initialValues}
        className="form-container"
        onFinish={handleSubmit}
      >
        {formOptions.map(({ key, label }) => {
          const isInputTypeNumber = SHIPPING_ADDRESS_VALIDATE_RULES[key].some(
            item => item.type === 'number'
          );

          return (
            <Form.Item
              key={key}
              label={label}
              name={key}
              rules={SHIPPING_ADDRESS_VALIDATE_RULES[key] || []}
            >
              {isInputTypeNumber ? <InputNumber /> : <Input />}
            </Form.Item>
          );
        })}

        <Button type="primary" className="form-confirm-btn" htmlType="submit">
          Xác nhận
        </Button>
      </Form>
    </Spin>
  );
}

export default ShippingAddressForm;

ShippingAddressForm.propTypes = {
  data: object,
  onSubmit: func,
  loading: bool
};

ShippingAddressForm.defaultProps = {
  data: {},
  onSubmit: () => {},
  loading: false
};
