import { Row, Tabs } from 'antd';
import Title from 'antd/lib/typography/Title';
import React, { useState } from 'react';

import AddressManagement from './AddressManagement';
import CustomEditor from '../../components/CustomEditor';
import './styles.less';

const TABS = [
  {
    key: 'shipping_address',
    tab: 'Quản lý địa chỉ ship hộ',
    Component: AddressManagement
  },
  {
    key: 'us_vn',
    tab: 'Bảng giá ship hộ Mỹ - VN',
    Component: () => <CustomEditor type="price_list_us_vn" />
  },
  {
    key: 'es_vn',
    tab: 'Bảng giá ship hộ TBN - VN',
    Component: () => <CustomEditor type="price_list_es_vn" />
  }
];

export default function ShippingAddress() {
  const [tab, setTab] = useState(TABS[0].key);

  return (
    <div className="transport-location-container">
      <Row justify="space-between" align="top">
        <Title className="title" level={5}>
          Địa chỉ ship hộ
        </Title>
      </Row>

      <Tabs onChange={setTab}>
        <>
          {TABS.map(tabItem => {
            return (
              <Tabs.TabPane
                active={tabItem.key === tab}
                tab={tabItem.tab}
                key={tabItem.key}
              >
                <tabItem.Component />
              </Tabs.TabPane>
            );
          })}
        </>
      </Tabs>
    </div>
  );
}
