import React, { useState } from 'react';
import Modal from 'antd/lib/modal';
import Input from 'antd/lib/input';
import Col from 'antd/lib/col';
import Row from 'antd/lib/row';
import './style.less';
import { bool, func } from 'prop-types';
import get from 'lodash/get';
import find from 'lodash/find';
import { useDispatch, useSelector } from 'react-redux';
import { Spin } from 'antd';
import { MessageContent } from '../../../contents/Labels';
import actions from '../../../redux/actions';
import TYPE from '../../../redux/actions/TYPE';
import { notificationSuccess } from '../../../utils/Notification';
import { MESSAGE_CONTENT } from '../../../contents/Constants';
import AddressList from './AddressList/AddressList';
import Profile from './Profile';

const CustomerDetail = ({ visible, onOk, onCancel }) => {
  const customerList = useSelector(state => {
    return get(state, 'customer.list.customers', []);
  });
  const customerID = useSelector(state => {
    return get(state, 'customer.detail._id', '');
  });
  const customer = find(customerList, item => {
    return item._id === customerID;
  });
  // const isBuyngonAccount = get(customer, 'is_buyngon_account', false);
  const [info, setInfo] = useState({ _id: get(customer, '_id', '') });
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const showConfirm = () => {
    setLoading(true);
    setTimeout(() => {
      dispatch(
        actions.updateCustomer({
          data: info,
          callback: ({ error }) => {
            if (error) {
              dispatch({ type: TYPE.UPDATE_CUSTOMER_FAILED });
            } else {
              notificationSuccess(MESSAGE_CONTENT.updateSuccess);
              onOk();
            }
            setLoading(false);
          }
        })
      );
    }, 1 * 1000);
  };

  const onChangeValue = data => {
    const { name, value } = data.target;
    setInfo({ ...info, [name]: value });
  };
  return (
    <Modal
      width={1000}
      bordered
      title={MessageContent.DetailInfo}
      visible={visible}
      onCancel={onCancel}
      onOk={showConfirm}
      okText={MessageContent.Accept}
      cancelText={MessageContent.Cancel}
    >
      <Spin spinning={loading}>
        <Profile customer={customer} />
        <Row className="popup-info" justify="center">
          <Row gutter={[40, 15]} className="popup-item">
            <Col span={10}>
              <span className="label">{MessageContent.Name}</span>
              <Input
                name="name"
                size="middle"
                defaultValue={get(customer, 'name', '')}
                onChange={onChangeValue}
              />
            </Col>
            <Col span={10}>
              <span className="label">{MessageContent.Phone}</span>
              <Input
                name="phone"
                size="middle"
                onChange={onChangeValue}
                defaultValue={get(customer, 'phone', '')}
                disabled
              />
            </Col>
            {/* {isBuyngonAccount && ( */}
            <Col span={10}>
              <span className="label">{MessageContent.NewPassword}</span>
              <Input name="new_password" size="middle" onChange={onChangeValue} />
            </Col>
            {/* )} */}
          </Row>
        </Row>
        <AddressList customer={customer} />
      </Spin>
    </Modal>
  );
};

CustomerDetail.propTypes = {
  visible: bool.isRequired,
  onOk: func.isRequired,
  onCancel: func.isRequired
};

export default CustomerDetail;
