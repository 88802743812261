import { all, takeLatest, call, put } from 'redux-saga/effects';
import services from '../../services';
import { notificationError, notificationSuccess } from '../../utils/Notification';
import TYPE from '../actions/TYPE';

function* createShippingAddress({ payload }) {
  try {
    const { value, callback } = payload;

    yield put({
      type: TYPE.ENABLE_LOADING_IMPROVE,
      target: 'createShippingAddress'
    });

    const data = yield call(services.marketplace.shippingAddress.create, value);

    notificationSuccess('Thêm địa chỉ thành công');
    callback?.();
    yield put({
      type: TYPE.CREATE_SHIPPING_ADDRESS_SUCCESS,
      payload: data
    });
  } catch (error) {
    notificationError('Thêm địa chỉ thất bại');

    yield put({
      type: TYPE.CREATE_SHIPPING_ADDRESS_FAILED,
      error
    });
  } finally {
    yield put({
      type: TYPE.DISABLE_LOADING_IMPROVE,
      target: 'createShippingAddress'
    });
  }
}

function* getShippingAddress({ payload }) {
  try {
    yield put({
      type: TYPE.ENABLE_LOADING_IMPROVE,
      target: 'getShippingAddress'
    });

    const data = yield call(services.marketplace.shippingAddress.get, payload);

    yield put({
      type: TYPE.GET_SHIPPING_ADDRESS_SUCCESS,
      payload: data
    });
  } catch (error) {
    yield put({
      type: TYPE.GET_SHIPPING_ADDRESS_FAILED,
      error
    });
  } finally {
    yield put({
      type: TYPE.DISABLE_LOADING_IMPROVE,
      target: 'getShippingAddress'
    });
  }
}

function* updateShippingAddress({ payload }) {
  try {
    const { value, callback } = payload;

    yield put({
      type: TYPE.ENABLE_LOADING_IMPROVE,
      target: 'updateShippingAddress'
    });
    const data = yield call(services.marketplace.shippingAddress.update, value);

    notificationSuccess('Thông tin đã được cập nhật');
    callback?.();

    yield put({
      type: TYPE.UPDATE_SHIPPING_ADDRESS_SUCCESS,
      payload: data
    });
  } catch (error) {
    notificationError('Cập nhật thông tin thất bại');
    yield put({
      type: TYPE.UPDATE_SHIPPING_ADDRESS_FAILED,
      error
    });
  } finally {
    yield put({
      type: TYPE.DISABLE_LOADING_IMPROVE,
      target: 'updateShippingAddress'
    });
  }
}

function* deleteShippingAddress({ payload }) {
  try {
    const { value, callback } = payload;

    yield put({
      type: TYPE.ENABLE_LOADING_IMPROVE,
      target: 'deleteShippingAddress'
    });
    const data = yield call(services.marketplace.shippingAddress.update, value);

    notificationSuccess('Xoá địa chỉ thành công');
    callback?.();

    yield put({
      type: TYPE.DELETE_SHIPPING_ADDRESS_SUCCESS,
      payload: data
    });
  } catch (error) {
    notificationError('Xoá địa chỉ thất bại');
    yield put({
      type: TYPE.DELETE_SHIPPING_ADDRESS_FAILED,
      error
    });
  } finally {
    yield put({
      type: TYPE.DISABLE_LOADING_IMPROVE,
      target: 'deleteShippingAddress'
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(TYPE.CREATE_SHIPPING_ADDRESS, createShippingAddress),
    takeLatest(TYPE.GET_SHIPPING_ADDRESS, getShippingAddress),
    takeLatest(TYPE.UPDATE_SHIPPING_ADDRESS, updateShippingAddress),
    takeLatest(TYPE.DELETE_SHIPPING_ADDRESS, deleteShippingAddress)
  ]);
}
