import { Tabs, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { MessageContent } from '../../contents/Labels';
import actions from '../../redux/actions';
import './style.less';
import CustomEditor from '../../components/CustomEditor';
import ContentTheme from '../../components/theme/ContentTheme';

const { Title } = Typography;

const TABS = [
  {
    key: 0,
    tab: 'Nội dung',
    Component: ContentTheme
  },
  {
    key: 1,
    tab: 'Chính sách bảo mật',
    Component: () => <CustomEditor type="privacy_policy" />
  },
  {
    key: 2,
    tab: 'Chính sách mua hàng hộ quốc tế',
    Component: () => <CustomEditor type="order_service_policy" />
  },
  {
    key: 3,
    tab: 'Chính sách bảo hiểm',
    Component: () => <CustomEditor type="insurance_policy" />
  },
  {
    key: 4,
    tab: 'Chính sách ship hộ',
    Component: () => <CustomEditor type="shipping_service_policy" />
  },
  {
    key: 5,
    tab: 'Bảng giá',
    Component: () => <CustomEditor type="price_list" />
  },
  {
    key: 6,
    tab: 'Mua hàng',
    Component: () => <CustomEditor type="order" />
  },
  {
    key: 7,
    tab: 'Quản lý đơn hàng',
    Component: () => <CustomEditor type="order_management" />
  }
];

const Theme = () => {
  const dispatch = useDispatch();
  const [tab, setTab] = useState(TABS[0].key);

  useEffect(() => {
    dispatch(actions.getConfiguration());
    return () => dispatch(actions.clearConfiguration());
  }, [dispatch]);
  return (
    <div className="container">
      <Title className="title" level={5}>
        {MessageContent.UserInterface}
      </Title>
      <Tabs onChange={setTab}>
        <>
          {TABS.map(tabItem => {
            return (
              <Tabs.TabPane
                active={tabItem.key === tab}
                tab={tabItem.tab}
                key={tabItem.key}
              >
                <tabItem.Component />
              </Tabs.TabPane>
            );
          })}
        </>
      </Tabs>
    </div>
  );
};
export default Theme;
