import get from 'lodash/get';
import TYPE from '../actions/TYPE';

const initialState = {
  list: {
    count: 0,
    customers: []
  },
  detail: null,
  isLoading: false,
  listCustomers: [],
  userSelected: null,
  userId: '',
  userDepositList: [],
  userSearch: null,
  isShowDetail: false,
  resPartner: {}
};

const customer = (state = initialState, action = {}) => {
  switch (action.type) {
    case TYPE.CLEAR_CUSTOMER:
    case TYPE.CONFIRM_SALE_ORDER_SUCCESS:
      return {
        ...state,
        userSelected: null
      };

    case TYPE.CREATE_DELIVERY_ADDRESS_SUCCESS:
    case TYPE.SELECTED_DELIVERY_ADDRESS_SUCCESS:
      return {
        ...state,
        userSelected: action.data
      };

    case TYPE.GET_ALL_CUSTOMERS_SUCCESS:
      return {
        ...state,
        listCustomers: action.data
      };

    case TYPE.CUSTOMER_DETAIL:
      return { ...state, detail: get(action, 'payload') };

    case TYPE.UPDATE_CUSTOMER_SUCCESS:
      const updatedCustomer = get(action, 'data');
      return {
        ...state,
        detail: updatedCustomer,
        list: {
          ...state.list,
          customers: state.list.customers.map(cus => {
            return cus._id === updatedCustomer._id ? updatedCustomer : cus;
          })
        }
      };

    case TYPE.CREATE_CUSTOMER_SUCCESS:
      return { ...state, list: [action.data, ...state.list] };

    case TYPE.REMOVE_CUSTOMER: {
      return {
        ...state,
        list: state.list.customers.filter(item => {
          return item._id !== get(action, 'data');
        })
      };
    }

    case TYPE.CHANGE_STATUS_ACTIVITY_CUSTOMER_SUCCESS: {
      return {
        ...state,
        list: {
          ...state.list,
          customers: state.list.customers.map(item => {
            return item._id !== get(action, 'data._id') ? item : get(action, 'data');
          })
        }
        // detail: get(action, "data")
      };
    }

    case TYPE.SEARCH_CUSTOMER: {
      return {
        ...state,
        isLoading: true
      };
    }

    case TYPE.SEARCH_CUSTOMER_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        list: {
          count: get(action, 'data.count', initialState.list.count),
          customers: get(action, 'data.results', initialState.list.customers)
        }
      };
    }

    case TYPE.LIST_DEPOSITS_FOR_CUSTOMER_SUCCESS: {
      return {
        ...state,
        list: {
          ...state.list,
          customers: action.data
        }
      };
    }

    case TYPE.SELECT_CUSTOMER: {
      return {
        ...state,
        userSelected: get(action, 'user')
      };
    }

    case TYPE.GET_INFO_WALLET: {
      const userId = get(action.payload, '_id', '');
      return {
        ...state,
        userId
      };
    }

    case TYPE.GET_DELIVERY_ADDRESS_SUCCESS: {
      return {
        ...state,
        userDepositList: action.data?.deposit?.transactions || []
      };
    }

    case TYPE.CLEAR_INFO_WALLET: {
      return {
        ...state,
        userId: '',
        userDepositList: []
      };
    }

    case TYPE.GET_USER_PROFILE_SUCCESS: {
      return {
        ...state,
        resPartner: action.data
      };
    }

    default:
      return state;
  }
};

export default customer;
