import { Card } from 'antd';
import { get } from 'lodash';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import actions from '../../redux/actions';
import ShippingAddressForm from './component/Form';

function ShippingAddressCreate() {
  const dispatch = useDispatch();
  const loading = useSelector(state =>
    get(state, 'common.loadingImprove.createShippingAddress', false)
  );

  const handleCreate = ({ value, resetInput }) => {
    const payload = {
      value: value.body,
      callback: resetInput
    };
    dispatch(actions.createShippingAddress(payload));
  };

  return (
    <Card className="card" style={{ marginTop: 20 }} title="Tạo mới">
      <ShippingAddressForm loading={loading} onSubmit={handleCreate} />
    </Card>
  );
}

export default ShippingAddressCreate;
