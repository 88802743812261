import {
  Button,
  DatePicker,
  Drawer,
  Select,
  Space,
  Spin,
  Table,
  Typography
} from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ReactJson from 'react-json-view';
import actions from '../../../redux/actions';

export default function LogAPI() {
  const dispatch = useDispatch();
  const [filter, setFilter] = useState({ type: 'info' });
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [detail, setDetail] = useState({});

  useEffect(() => {
    setLoading(true);
    dispatch(
      actions.monitorLogApi({
        data: { ...filter },
        callback: ({ error, data: result }) => {
          setTimeout(() => {
            if (!error) {
              setData(result);
            }
            setLoading(false);
          }, 1 * 1000);
        }
      })
    );
  }, [filter, dispatch]);

  const columnProps = {
    align: 'left',
    ellipsis: true,
    width: 200
  };

  const columns = [
    {
      ...columnProps,
      fixed: 'left',
      title: 'Time',
      width: 50,
      dataIndex: 'meta',
      render: (value, r) => {
        const time = filter?.type === 'info' ? r?.timestamp : value?.date;
        return <Typography>{time}</Typography>;
      }
    },
    {
      ...columnProps,
      fixed: 'left',
      title: 'Customer',
      width: 50,
      dataIndex: 'level'
    },
    {
      ...columnProps,
      fixed: 'left',
      title: 'Level',
      width: 20,
      dataIndex: 'level'
    },
    {
      ...columnProps,
      fixed: 'left',
      title: 'Method',
      width: 30,
      dataIndex: 'meta',
      render: ({ req: { method } }) => method
    },
    {
      ...columnProps,
      fixed: 'left',
      title: 'Method',
      width: 90,
      dataIndex: 'meta',
      render: ({ req: { originalUrl } }) => originalUrl
    },
    {
      ...columnProps,
      title: 'Thao tác',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      width: 40,
      render: (_, record) => {
        return (
          <Button
            type="primary"
            onClick={() => {
              setDetail(record);
            }}
          >
            Chi tiết
          </Button>
        );
      }
    }
  ];

  const handleClose = () => {
    setDetail({});
  };

  const onSelect = q => {
    setFilter({ ...filter, type: q });
  };

  const onSearch = q => {
    const time = moment(q).format('YYYY-MM-DD');

    setFilter({ ...filter, time });
  };

  return (
    <Spin spinning={loading}>
      <Space size={20}>
        <Button
          onClick={() => {
            setFilter({ ...filter });
          }}
        >
          Reload
        </Button>
        <DatePicker onChange={onSearch} />
        <Select defaultValue="info" style={{ width: 180 }} onChange={onSelect}>
          <Select.Option value="info">Info</Select.Option>
          <Select.Option value="error">Error</Select.Option>
        </Select>
      </Space>
      <Table columns={columns} dataSource={data} />
      <Drawer
        className="drawer"
        width="90vw"
        title="MONITOR LOG API"
        placement="right"
        visible={!isEmpty(detail)}
        closeIcon={<CloseOutlined onClick={handleClose} />}
      >
        <ReactJson src={detail} />
      </Drawer>
    </Spin>
  );
}
