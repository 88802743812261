import { Button, Input, Row, Typography } from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import Form from 'antd/lib/form';
import { get } from 'lodash';
import { any } from 'prop-types';
import React, { useEffect } from 'react';
import actions from '../../../redux/actions';
import { notificationSuccess } from '../../../utils/Notification';
import dispatch from '../../../utils/dispatch';

export default function DomainForm({ domainDetail, setShowDrawer }) {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      domain: get(domainDetail, 'domain', ''),
      disable: get(domainDetail, 'disable', false)
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [domainDetail]);

  const onSave = () => {
    form.validateFields().then(values => {
      const { domain, disable } = values;
      dispatch(
        actions.updateCrawlWebsite({
          domain: {
            _id: domainDetail._id,
            domain,
            disable
          }
        })
      );
    });
    notificationSuccess('Cập nhật thành công');
    setShowDrawer(false);
  };

  return (
    <Form form={form}>
      <Typography.Title level={5}>Domain:</Typography.Title>
      <Form.Item
        name="domain"
        rules={[{ required: true, message: 'Missing domain name' }]}
      >
        <Input placeholder="Domain" />
      </Form.Item>

      <Form.Item name="disable" valuePropName="checked">
        <Checkbox>Không hỗ trợ địa chỉ Mỹ</Checkbox>
      </Form.Item>

      <Form.Item>
        <Row justify="center">
          <Button type="primary" htmlType="submit" onClick={onSave}>
            Save
          </Button>
        </Row>
      </Form.Item>
    </Form>
  );
}

DomainForm.propTypes = {
  domainDetail: any,
  setShowDrawer: any
};

DomainForm.defaultProps = {
  domainDetail: null,
  setShowDrawer: () => {}
};
