import { string, object } from 'prop-types';
import React from 'react';
import AmericaIcon from '../icons/flags/AmericaIcon';
import SpainIcon from '../icons/flags/SpainIcon';

export default function FlagsIcon({ type, ...props }) {
  const defaultStyle = { marginBottom: -3 };

  switch (type) {
    case 'USA':
      return <AmericaIcon {...props} style={{ ...defaultStyle, ...props.style }} />;
    case 'ESP':
      return <SpainIcon {...props} style={{ ...defaultStyle, ...props.style }} />;
    default:
      return null;
  }
}

FlagsIcon.propTypes = {
  type: string,
  style: object
};

FlagsIcon.defaultProps = {
  type: '',
  style: {}
};
